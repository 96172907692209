/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { format } from "date-fns";
import axios from "axios";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import logo0 from "../../../common/images/no-img-80.png";
import {
  appId,
  apiUrl,
  mediaUrl,
  restaurantLink,
  headerconfig,
} from "../../Helpers/Config";
import { stripslashes, showPriceValue } from "../../Helpers/SettingHelper";

var newly_opened = {
  items: 4,
  autoplay: false,
  nav: true,
  margin: 15,
  dots: false,
  loop: true,
  responsive: {
    0: {
      items: 1,
      margin: 10,
      center: true,
      stagePadding: 40,
    },
    500: {
      items: 1,
    },
    700: {
      items: 2,
      margin: 10,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};
class Newlyopened extends Component {
  constructor(props) {
    super(props);

    this.state = {
      outletList: [],
    };
    this.loadAllOutlet();
  }

  loadAllOutlet() {
    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    axios
      .get(
        apiUrl +
          "outlets/getAllOutles?app_id=" +
          appId +
          "&newoutlet=Yes&loadproduct=Yes" +
          spicelPride,
        headerconfig
      )
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState({ outletList: res.data.result_set });
        }
      });
  }

  render() {
    if (this.state.outletList.length > 0) {
      return (
        <section className="home-newly" id="swimlane_sec5">
          <div className="container">
            {this.props.swimlaneheader}
            <div className="opened-carsouel">
              <OwlCarousel options={newly_opened}>
                {this.state.outletList.map((item, index) => {
                  if (item.products.length > 0) {
                    return (
                      <div key={index}>
                        <div
                          className="opened-inner"
                          style={{
                            background:
                              item.outlet_color_code !== "" &&
                              item.outlet_color_code !== null
                                ? item.outlet_color_code
                                : "linear-gradient(180deg, #0483A4 0%, #1CA9CD 100%)",
                          }}
                        >
                          <Link to={"/food/" + item.outlet_slug}>
                            <div className="open-header">
                              <div className="ob-header-image">
                                <img
                                  src={
                                    item.outlet_image !== "" &&
                                    item.outlet_image !== null
                                      ? mediaUrl + "outlet/" + item.outlet_image
                                      : logo0
                                  }
                                  alt={item.outlet_name}
                                  title={item.outlet_name}
                                />
                              </div>
                              <div className="ob-header-content">
                                <h5>{item.outlet_name}</h5>
                                <span>{item.outlet_open_time}</span>
                              </div>
                            </div>
                          </Link>
                          <div className="open-body">
                            <ul>
                              {item.products.map((pitem, pindex) => {
                                if (pindex === 0) {
                                  var productName =
                                    item.product_alias !== ""
                                      ? stripslashes(item.product_alias)
                                      : stripslashes(item.product_name);
                                  return (
                                    <li key={pindex}>
                                      <Link
                                        to={
                                          "/" +
                                          restaurantLink +
                                          "/" +
                                          item.outlet_slug +
                                          "/" +
                                          pitem.pro_cate_slug +
                                          "/" +
                                          pitem.pro_subcate_slug +
                                          "/" +
                                          pitem.product_slug
                                        }
                                      >
                                        <div className="ob-image">
                                          <img
                                            src={
                                              pitem.product_thumbnail !== "" &&
                                              pitem.product_thumbnail !== null
                                                ? mediaUrl +
                                                  "products/main-image/" +
                                                  pitem.product_thumbnail
                                                : logo0
                                            }
                                            title={
                                              pitem.product_thumbnail_title !==
                                                "" &&
                                              pitem.product_thumbnail_title !==
                                                null
                                                ? pitem.product_thumbnail_title
                                                : productName
                                            }
                                            alt={
                                              pitem.product_thumbnail_alt !==
                                                "" &&
                                              pitem.product_thumbnail_alt !==
                                                null
                                                ? pitem.product_thumbnail_alt
                                                : productName
                                            }
                                          />
                                        </div>
                                        <div className="ob-content">
                                          <h5>
                                            {pitem.product_alias !== ""
                                              ? stripslashes(
                                                  pitem.product_alias
                                                )
                                              : pitem.product_name}
                                          </h5>
                                          <div className="pro-description">
                                            {pitem.product_short_description}
                                          </div>
                                          <span>
                                            {pitem.product_specialprice_applicable ===
                                            "yes"
                                              ? showPriceValue(
                                                  pitem.product_special_price
                                                )
                                              : showPriceValue(
                                                  pitem.product_price
                                                )}
                                          </span>
                                          <br />
                                          {pitem.product_specialprice_applicable ===
                                          "yes"
                                            ? parseFloat(pitem.product_price) >
                                                0 && (
                                                <span className="product-originale-price">
                                                  {showPriceValue(
                                                    pitem.product_price
                                                  )}
                                                </span>
                                              )
                                            : parseFloat(pitem.product_cost) >
                                                0 && (
                                                <span className="product-originale-price">
                                                  {showPriceValue(
                                                    pitem.product_cost
                                                  )}
                                                </span>
                                              )}
                                        </div>
                                      </Link>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </OwlCarousel>
            </div>
          </div>
        </section>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateTopProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateTopProps, mapDispatchToProps)(Newlyopened);
