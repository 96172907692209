/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Axios from "axios";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import {
  appId,
  apiUrlV2,
  deliveryId,
  restaurantLink,
  shopType,
  headerconfig,
  cookieConfig,
} from "../../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../../Helpers/SettingHelper";
import noimage from "../../../common/images/no-img-product.png";
var qs = require("qs");
var settingsBanner = {
  items: 4,
  dots: false,
  autoplay: false,
  nav: true,
  margin: 25,
  /* loop: true, */
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    500: {
      items: 3,
      margin: 20,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 4,
    },
  },
};
import { GET_HIGHLIGHTPROLIST } from "../../../actions";

class SwimlaneProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    // this.props.getHighlightProList();
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#sproIndex-" + this.props.swimlaneid + "_" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#sproIndex-" + this.props.swimlaneid + "_" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");

    if (
      avilablityId !== "" &&
      typeof avilablityId !== undefined &&
      typeof avilablityId !== "undefined"
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg).addClass(
          "active"
        );
        $("#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader(
          "sproIndex-" + this.props.swimlaneid + "_" + IndexFlg,
          "Idtext"
        );
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $(
          "#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg
        )
          .find(".proqty_input")
          .val();
        var specialprice_applicable =
          productDetail.product_specialprice_applicable;

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          outletLocationId: productDetail.outlet_location_id,
          outlet_id: productDetail.outlet_id,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          shopType: shopType,
          outletzone_id: this.state.orderZoneId,
          login_type:
            typeof cookie.load("userAccountType") === "undefined"
              ? ""
              : cookie.load("userAccountType"),
          reference_new_id: getReferenceID(),
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        ).then((res) => {
          $("#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg).removeClass(
            "active"
          );
          hideLoader(
            "sproIndex-" + this.props.swimlaneid + "_" + IndexFlg,
            "Idtext"
          );
          $("#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#sproIndex-" + this.props.swimlaneid + "_" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            var productName =
              productDetail.product_alias !== "" &&
              productDetail.product_alias !== null
                ? productDetail.product_alias
                : productDetail.product_name;
            var proPrice = productDetail.product_price;
            if (specialprice_applicable === "yes") {
              proPrice = productDetail.product_special_price;
            }

            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart.",
              "cart-success-msg"
            );
            removePromoCkValue();
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.form_error !== ""
                ? res.data.form_error
                : res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    if (
      avilablityId !== "" &&
      typeof avilablityId !== undefined &&
      typeof avilablityId !== "undefined"
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        this.props.history.push(
          "/" +
            restaurantLink +
            "/" +
            productDetail.outlet_slug +
            "/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productSlug
        );
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }
  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-li no-stock-product";
    if (Stock > 0) {
      returnText = "products-single-li";
    }
    return returnText;
  }

  render() {
    let highlightprolist = this.props.highlightproductlist;
    let highlightproimagesource = this.props.multiswimlaneimage.image_source;
    return (
      <div className="featured-products-main">
        <OwlCarousel options={settingsBanner}>
          {highlightprolist.map((item, index) => {
            var prodivId =
              "sproIndex-" +
              this.props.swimlaneid +
              "_" +
              item.product_primary_id;
            var comboProId =
              "scomboPro-" + this.props.swimlaneid + "_" + item.product_slug;
            var productName =
              item.product_alias !== ""
                ? stripslashes(item.product_alias)
                : stripslashes(item.product_name);
            return (
              <div
                className={this.chkProStockCls(
                  item.product_slug,
                  item.product_stock
                )}
                id={prodivId}
                key={index}
              >
                {item.product_tag_info !== "" &&
                  item.product_tag_info !== null && (
                    <div className="ribbon">
                      <span>{item.product_tag_info}</span>
                    </div>
                  )}
                <Link
                  to={
                    "/" +
                    restaurantLink +
                    "/" +
                    item.outlet_slug +
                    "/" +
                    item.pro_cate_slug +
                    "/" +
                    item.pro_subcate_slug +
                    "/" +
                    item.product_slug
                  }
                >
                  <div className="products-image-div">
                    {item.product_thumbnail !== "" ? (
                      <img
                        src={
                          highlightproimagesource + "/" + item.product_thumbnail
                        }
                        title={
                          item.product_thumbnail_title !== "" &&
                          item.product_thumbnail_title !== null
                            ? item.product_thumbnail_title
                            : productName
                        }
                        alt={
                          item.product_thumbnail_alt !== "" &&
                          item.product_thumbnail_alt !== null
                            ? item.product_thumbnail_alt
                            : productName
                        }
                      />
                    ) : (
                      <img
                        src={noimage}
                        title={productName}
                        alt={productName}
                      />
                    )}
                    {item.product_stock <= 0 && (
                      <div className="not-available-stock">
                        This product is out of stock
                      </div>
                    )}
                  </div>
                </Link>

                <div className="product-info-div">
                  <div className="product-outlet-name">
                    <Link to={"/" + restaurantLink + "/" + item.outlet_slug}>
                      {item.outlet_name}
                    </Link>
                  </div>
                  <div className="product-title-maindiv">
                    <div className="product-title">
                      <Link
                        to={
                          "/" +
                          restaurantLink +
                          "/" +
                          item.outlet_slug +
                          "/" +
                          item.pro_cate_slug +
                          "/" +
                          item.pro_subcate_slug +
                          "/" +
                          item.product_slug
                        }
                      >
                        <h3>{productName}</h3>
                      </Link>
                    </div>
                  </div>
                  <div className="products-ordernow-action">
                    <div className="product-price">
                      <h3>
                        {item.product_specialprice_applicable === "yes"
                          ? showPriceValue(item.product_special_price)
                          : showPriceValue(item.product_price)}
                      </h3>
                      {item.product_specialprice_applicable === "yes"
                        ? parseFloat(item.product_price) > 0 && (
                            <span className="product-originale-price">
                              {showPriceValue(item.product_price)}
                            </span>
                          )
                        : parseFloat(item.product_cost) > 0 && (
                            <span className="product-originale-price">
                              {showPriceValue(item.product_cost)}
                            </span>
                          )}
                    </div>
                    <div className="addcart_row addcart_done_maindiv">
                      <div className="addcart-row-child">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.proQtyAction.bind(
                              this,
                              item.product_primary_id,
                              "decr"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="proqty_input"
                            readOnly
                            value="1"
                          />
                          <span
                            className="qty_plus"
                            onClick={this.proQtyAction.bind(
                              this,
                              item.product_primary_id,
                              "incr"
                            )}
                          >
                            +
                          </span>
                        </div>
                        <button
                          className="button btn btn_black order_done"
                          onClick={this.addToCartSimple.bind(
                            this,
                            item,
                            "done"
                          )}
                        >
                          Done
                        </button>
                      </div>
                    </div>

                    {item.product_stock > 0 || item.product_stock === null ? (
                      item.product_type === "1" ? (
                        <a
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            item,
                            "initial"
                          )}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv smiple_product_lk"
                        >
                          Add to Cart
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.viewProDetail.bind(this, item)}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv compo_product_lk"
                        >
                          Order Now
                        </a>
                      )
                    ) : (
                      <a className="button disabled disbl_href_action" href="/">
                        Sold Out
                      </a>
                    )}
                  </div>

                  {/*  <div className="product-price">
                    <h3>
                      {item.product_specialprice_applicable === "yes"
                        ? showPriceValue(item.product_special_price)
                        : showPriceValue(item.product_price)}
                    </h3>
                    {item.product_specialprice_applicable === "yes"
                      ? parseFloat(item.product_price) > 0 && (
                          <span className="product-originale-price">
                            {showPriceValue(item.product_price)}
                          </span>
                        )
                      : parseFloat(item.product_cost) > 0 && (
                          <span className="product-originale-price">
                            {showPriceValue(item.product_cost)}
                          </span>
                        )}
                  </div>
                  <div className="products-ordernow-action">
                    <div className="addcart_row addcart_done_maindiv">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            item.product_primary_id,
                            "decr"
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            item.product_primary_id,
                            "incr"
                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="btn btn_black order_done"
                        onClick={this.addToCartSimple.bind(this, item, "done")}
                      >
                        +
                      </button>
                    </div>
                    {item.product_stock > 0 || item.product_stock === null ? (
                      item.product_type === "1" ? (
                        <a
                          className="button order_nowdiv smiple_product_lk disbl_href_action"
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            item,
                            "done"
                          )}
                        >
                          +
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.viewProDetail.bind(this, item)}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv compo_product_lk"
                        >
                          +
                        </a>
                      )
                    ) : (
                      <a className="button disabled disbl_href_action" href="/">
                        Sold Out
                      </a>
                    )}
                  </div> */}
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    // highlightproductlist: state.highlightproductlist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getHighlightProList: () => {
      dispatch({ type: GET_HIGHLIGHTPROLIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(SwimlaneProducts);
