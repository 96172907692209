import { takeEvery, call, put } from "redux-saga/effects";
import {
  GET_PICKUP_OUTLETS,
  SET_PICKUP_OUTLETS,
  GET_TABLEAVAILABILITY,
  SET_TABLEAVAILABILITY,
} from "../actions";
import {
  appId,
  apiUrl,
  pickupId,
  headerconfig,
} from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetPickupOutlets = function* () {
  yield takeEvery(GET_PICKUP_OUTLETS, workerGetPickupOutlets);
};

function* workerGetPickupOutlets() {
  try {
    const uri =
      apiUrl +
      "outlets/pickup_outlets?app_id=" +
      appId +
      "&availability=" +
      pickupId;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PICKUP_OUTLETS, value: resultArr });
  } catch {
    console.log("Get Pickup Outlets Failed");
  }
}
export const watchgetTableAvailability = function* () {
  yield takeEvery(GET_TABLEAVAILABILITY, workergetTableAvailability);
};

function* workergetTableAvailability({ formPayload }) {
  try {
    const result = yield call(getTableData, formPayload);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_TABLEAVAILABILITY, value: resultArr });
  } catch {
    console.log("Find table failed");
  }
}

function getTableData(formPayload) {
  return Axios.post(apiUrl + "tablemanagement/find_table", formPayload);
}
