/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import {
  appId,
  apiUrl,
  headerconfig,
  deliveryId,
  cookieConfig,
} from "../Helpers/Config";
import { removePromoCkValue, showAlert } from "../Helpers/SettingHelper";
import cookie from "react-cookies";
var qs = require("qs");
class Placeorder extends Component {
  constructor(props) {
    super(props);
    var avilablityId =
      cookie.load("defaultAvilablityId") != "" &&
      cookie.load("defaultAvilablityId") != undefined
        ? cookie.load("defaultAvilablityId")
        : "";
    this.state = {
      cartTriggerFlg: "",
      defaultAvilTy: avilablityId,
      globalSettings: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.globalSettings !== nextProps.settingsArr) {
      if (Object.keys(nextProps.settingsArr).length > 0) {
        this.setState({ globalSettings: nextProps.settingsArr }, function () {
          var urlParams = new URLSearchParams(this.props.location.search);
          if (
            (urlParams.get("two_c_two_p_status") == "success" ||
              urlParams.get("two_c_two_p_status") == "failed") &&
            urlParams.get("two_c_two_p_order_id") != ""
          ) {
            this.checkTwoCTwoP(urlParams.get("two_c_two_p_order_id"));
          }
        });
      }
    }
  }

  checkTwoCTwoP(two_c_two_p_order_id) {
    /*   this.setState({ twoCtwoPPayment_loading: true }, function () {
      $.magnificPopup.open({
        items: {
          src: "#processing-popup",
        },
        type: "inline",
        showCloseBtn: false,
        midClick: true,
        closeOnBgClick: false,
      });
    }); */

    var cabtureObjects = {
      app_id: appId,
      order_id: two_c_two_p_order_id,
    };
    axios
      .post(
        apiUrl + "paymentv1/checkTwoCTwoPStatus",
        qs.stringify(cabtureObjects),
        headerconfig
      )
      .then((captureRes) => {
        if (captureRes.data.status === "ok") {
          this.showSuccessPage(captureRes.data.common.local_order_no);
          return;
        } else if (captureRes.data.status === "error") {
          var magnfPopup = $.magnificPopup.instance;
          var omsMsgTxt =
            captureRes.data.message != ""
              ? captureRes.data.message
              : "Your order was not successful.";
          showAlert("Error", omsMsgTxt, magnfPopup);
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
          this.props.history.push("/");
          return false;
        } else {
        }
      })
      .catch((error) => {
        var magnfPopup = $.magnificPopup.instance;
        showAlert("Error", error, magnfPopup);
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        // this.props.history.push("/");
        return false;
      });
  }

  /* sucess page */
  showSuccessPage(localOrderNo) {
    this.deleteOrderCookie();
    cookie.save("ChkOrderid", localOrderNo, cookieConfig);
    const { history } = this.props;
    $.magnificPopup.close();
    var classThis = this;
    if (
      classThis.state.globalSettings.client_enable_auto_push_delivery !== "" &&
      typeof classThis.state.globalSettings.client_enable_auto_push_delivery !==
        undefined &&
      typeof classThis.state.globalSettings.client_enable_auto_push_delivery !==
        "undefined" &&
      this.state.defaultAvilTy === deliveryId
    ) {
      if (
        classThis.state.globalSettings.client_enable_auto_push_delivery === "1"
      ) {
        history.push("/findingdriver/" + localOrderNo);
        return false;
      }
    } else {
      // classThis.props.destroyCartDetail();
      history.push("/thankyou/" + localOrderNo);
    }
  }

  /* this function used to  delete all cookie values */
  deleteOrderCookie() {
    removePromoCkValue();
    cookie.remove("defaultAvilablityId", cookieConfig);
    cookie.remove("orderOutletId", cookieConfig);
    cookie.remove("orderPaymentMode", cookieConfig);
    cookie.remove("orderTableNo", cookieConfig);
    cookie.remove("product_remarks", cookieConfig);

    cookie.remove("carttotalitems", cookieConfig);
    cookie.remove("cartsubtotal", cookieConfig);
    cookie.remove("cartid", cookieConfig);

    /* Delivery avilablity */

    cookie.remove("unitNoOne", cookieConfig);
    cookie.remove("unitNoTwo", cookieConfig);
    cookie.remove("firstNavigation", cookieConfig);

    cookie.remove("promotion_id", cookieConfig);
    cookie.remove("promotion_applied", cookieConfig);
    cookie.remove("promotion_code", cookieConfig);
    cookie.remove("promotion_delivery_charge_applied", cookieConfig);
    cookie.remove("promotion_amount", cookieConfig);
    cookie.remove("promotion_category", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
    cookie.remove("promoOutlet", cookieConfig);
    cookie.remove("promoType", cookieConfig);
    cookie.remove("promoPercentage", cookieConfig);

    /*Remove voucher*/
    cookie.remove("voucher_applied", cookieConfig);
    cookie.remove("voucher_code", cookieConfig);
    cookie.remove("voucher_amount", cookieConfig);

    cookie.remove("points_redeemed", cookieConfig);
    cookie.remove("points_used", cookieConfig);
    cookie.remove("points_amount", cookieConfig);
    cookie.remove("prmo_type", cookieConfig);
  }

  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="checkout-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <p>Placing Order</p>
          </div>
        </div>

        <div id="dvLoading"></div>

        {/* Footer section */}
        <Footer />
        {/* Donate popup - end */}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }
  return {
    settingsArr: globalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateTopProps, mapDispatchToProps)(Placeorder);
