/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FEATUREPRO, SET_FEATUREPRO } from "../actions";
import {
  appId,
  apiUrl,
  deliveryId,
  headerconfig,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";

export const watchGetFeaturePro = function* () {
  yield takeEvery(GET_FEATUREPRO, workerGetFeaturePro);
};

function* workerGetFeaturePro() {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;

    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }

    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    const uri =
      apiUrl +
      "products/new_products_list?app_id=" +
      appId +
      "&availability=" +
      availabilityId +
      spicelPride;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FEATUREPRO, value: resultArr });
  } catch (e) {
    console.log(e, "Get Feature Product Failed");
  }
}
