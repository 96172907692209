/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import update from "immutability-helper";
import axios from "axios";
import moment from "moment";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import CartSideBar from "../Layout/CartSideBar";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  mediaUrl,
  siteURL,
  restaurantLink,
  headerconfig,
  cookieConfig,
  dineinId,
} from "../Helpers/Config";
import {
  getAliasName,
  stripslashes,
  showLoader,
  hideLoader,
  getReferenceID,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ALL_OUTLETS,
} from "../../actions";
var base64 = require("base-64");
import noimage from "../../common/images/no-img-product.png";
import zoomimg from "../../common/images/search-purple.svg";
import clockimg from "../../common/images/clock.svg";
import infoimg from "../../common/images/info.svg";
import logo0 from "../../common/images/no-img-80.png";
import innerbanner from "../../common/images/innerbanner.jpg";

const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");
var settingsBanner = {
  items: 9,
  dots: true,
  autoplay: false,
  nav: false,
  margin: 15,
  /* loop: true, */
  responsive: {
    0: {
      items: 2,
      margin: 10,
    },
    400: {
      items: 3,
    },
    500: {
      items: 4,
    },
    700: {
      items: 5,
    },
    900: {
      items: 7,
    },
    1200: {
      items: 9,
    },
  },
};
class Index extends Component {
  constructor(props) {
    super(props);
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll =
      currentPageUrl[1] !== "" &&
      typeof currentPageUrl[1] !== undefined &&
      typeof currentPageUrl[1] !== "undefined"
        ? currentPageUrl[1]
        : "";
    var current_Page_Url = "";
    var resbreadcrumb = "";
    if (currentPageUrll == "food") {
      current_Page_Url = "food";
      resbreadcrumb = "Restaurants";
    } else if (currentPageUrll == "retail") {
      current_Page_Url = "retail";
      resbreadcrumb = "Retail";
    } else {
      current_Page_Url = "events";
      resbreadcrumb = "Events";
    }

    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    this.state = {
      color_code: "#1c1c40",
      settings: [],
      currentPageUrl: current_Page_Url,
      slugEvent:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent != "undefined"
          ? this.props.match.params.slugEvent
          : "",
      deliveryPostalCode: deliveryPostalCode,
      resbreadcrumb: resbreadcrumb,
      showProDetails: "no",
      proceedview: "no",
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      outlet_slug: this.props.match.params.slugRestaurant,
      defaultAvilTy: avilablityIdTxt,
      outletslist: [],
      tag: [],
      promotion: [],
      restaurantdetails: "",
      socilaMedia: "",
      order_date: new Date(),
      cartOutletList: [],
      load_product: "No",
      searchProResult: [],
      selectedProResult: [],
      totalCartCount: 0,
      orderOutletId: "",
      shopeClosed: 0,
      shareGroupUrl: "",
      showOrdDateOnly: "no",
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",
      cartDetails: "",
      cartGroups: "",
      cartClearTriggerFlg: "no",
      selectPickupTime: "No",
      res_min_date: "",
      outletLocationId:
        cookie.load("outletLocationId") !== "" &&
        typeof cookie.load("outletLocationId") !== undefined &&
        typeof cookie.load("outletLocationId") !== "undefined"
          ? cookie.load("outletLocationId")
          : "",
      alloutletList: [],
      display_Recomend_outlet: "",
      outletTagList: [],
      timestrtxt: "",
      unitnumber1: unitNoOne,
      unitnumber2: unitNoTwo,
    };
    showLoader("restaurant-main", "class");

    this.props.getSettings();
    this.loadAlloutlets();

    /* this.props.getMenuNavigationList(
      this.props.match.params.slugRestaurant,
      avilablityIdTxt,
      this.state.currentPageUrl,
      this.state.slugEvent
    ); */
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.outlet_slug !== nextProps.match.params.slugRestaurant) {
      showLoader("restaurant-main", "class");
      /* this.props.getMenuNavigationList(
        this.props.match.params.slugRestaurant,
        this.state.defaultAvilTy,
        this.state.currentPageUrl,
        this.state.slugEvent
      ); */
    }

    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let navigateMenu = nextProps.navigateMenu;

    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        /* setTimeout(function () {
          if ($("." + proValue).length > 0) {
            $("html, body").animate(
              {
                scrollTop: $("." + proValue).offset().top - 150,
              },
              500
            );
          }
        }, 1000); */
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    /* if (
      selectedNavigation !== this.state.selectedNavigation ||
      (this.state.selectedCategoryName !== nextProps.selectedCatry &&
        this.props.selectedCatry !== nextProps.selectedCatry)
    ) {
      showLoader("productlist-main-div", "class");
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0 && navIndex >= 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }
      this.setState(
        {
          selectedNavigation: selectedNavigation,
          catNavIndex: navIndex,
          catslugType: slugType,
          catslugValue: slugValue,
          navigateMenu: navigateMenu,
          selectedCategoryName: categoryNameTxt,
          searchSubCat: searchSubCat,
          searchProVal: proValue,
          /*  loadproduct: "Yes", 
        },
        function () {}
      );
    } */

    /* if (this.state.load_product === "No") {
      showLoader("productlist-main-div", "class");
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }
      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
        loadproduct: "Yes",
        load_product: "No",
      });
    } */

    if (nextProps.alloutlets !== this.state.outletslist) {
      if (nextProps.alloutlets.length > 0) {
        if (nextProps.alloutlets[0].status !== "error") {
          var resultSetN = nextProps.alloutlets[0].result_set;
          var outletLocationId = this.state.outletLocationId;
          if (resultSetN.length > 0) {
            outletLocationId = resultSetN[0].outlet_location_id;
          }
          this.setState(
            {
              outletslist: resultSetN,
              orderOutletId: resultSetN[0].oa_outlet_id,
              tag: nextProps.alloutlets[0].tag,
              promotion: nextProps.alloutlets[0].promotion,
              outletLocationId: outletLocationId,
            },
            function () {
              this.checkTimeAvilablity();
            }
          );
        }
      }
    }
    if (nextProps.globalsettings !== this.state.globalsettings) {
      if (
        nextProps.globalsettings[0] !== "" &&
        typeof nextProps.globalsettings[0] !== undefined &&
        typeof nextProps.globalsettings[0] !== "undefined"
      ) {
        this.setState({ settings: nextProps.globalsettings[0].result_set });
      }
    }
  }

  loadMenuNavigation(outlet_slug, availability, currentPageUrl, slugEvent) {
    var availability = "";
    if (
      availability !== "" &&
      typeof availability !== undefined &&
      typeof availability !== "undefined"
    ) {
      availability = "&availability=" + availability;
    }
    const uri =
      apiUrlV2 +
      "products/getMenuNavigation?app_id=" +
      appId +
      availability +
      "&outletSlug=" +
      outlet_slug +
      "&shop_type=" +
      currentPageUrl +
      slugEvent;
    axios.get(uri, headerconfig).then((res) => {
      if (res.data.status === "ok") {
        var resultset = res.data.result_set;
        var selectedNavigation = resultset[0].pro_cate_slug;
        var slugValue = selectedNavigation;
        var categoryNameTxt = resultset[0].category_name;
        var navIndex = 0;
        var slugType = "category";
        var searchSubCat = "";
        var proValue =
          typeof this.props.match.params.proValue !== "undefined"
            ? this.props.match.params.proValue
            : "";
        this.setState(
          {
            navigateMenu: resultset,
            selectedNavigation: selectedNavigation,
            catNavIndex: navIndex,
            catslugType: slugType,
            catslugValue: slugValue,
            selectedCategoryName: categoryNameTxt,
            searchSubCat: searchSubCat,
            searchProVal: proValue,
            loadproduct: "Yes",
            load_product: "No",
          },
          function () {
            this.setState({ navigateMenu: resultset });
          }
        );
      }
      showLoader("productlist-main-div", "class");
    });
  }

  componentDidMount() {
    /*SCROLL FIXED PRODUCT PAGE*/
    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });

    $(document)
      .off("click", ".popup-close-icon")
      .on("click", ".popup-close-icon", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $("body").css("overflow", "hidden auto");
        $("body").removeClass("open-product-details");
      });

    // this.props.getAllOutlets(this.state.defaultAvilTy, this.state.outlet_slug)
    this.getSearchProductList();
  }

  loadAlloutlets() {
    axios
      .get(apiUrlV2 + "outlets/getAllOutles?app_id=" + appId, headerconfig)
      .then((res) => {
        if (res.data.status === "ok") {
          this.setState(
            { alloutletList: res.data.result_set, outletTagList: res.data.tag },
            function () {
              this.displayRecomendOutlet();
              this.loadMenuNavigation(
                this.props.match.params.slugRestaurant,
                this.state.defaultAvilTy,
                this.state.currentPageUrl,
                this.state.slugEvent
              );
            }
          );
        }
      });
  }

  displayRecomendOutlet() {
    if (this.state.alloutletList.length > 0) {
      var display_Recomend_outlet = this.state.alloutletList.map(
        (item, index) => {
          return (
            <div key={index}>
              <Link
                to={"/food/" + item.outlet_slug}
                onClick={this.openOutlet.bind(this, item.outlet_slug)}
                className="outlet-inner"
              >
                <div className="outlet-img">
                  <img
                    src={
                      item.outlet_extra_image !== "" &&
                      item.outlet_extra_image !== null
                        ? mediaUrl + "outlet/" + item.outlet_extra_image
                        : logo0
                    }
                    alt={item.outlet_name}
                    title={item.outlet_name}
                  />
                </div>
                <div className="outlet-content">
                  <h5>{item.outlet_name}</h5>
                </div>
              </Link>
            </div>
          );
        }
      );
      this.setState({ display_Recomend_outlet: display_Recomend_outlet });
    }
  }
  dispalyshortOutetTag(outletTag) {
    var result = "";
    if (
      Object.keys(this.state.outletTagList).length > 0 &&
      outletTag !== "" &&
      outletTag !== null
    ) {
      var outlet_Tag = outletTag.split(",");
      Object.keys(this.state.outletTagList).map((item) => {
        if (outlet_Tag.indexOf(item) >= 0) {
          var tagName = this.state.outletTagList[item];
          result += tagName + ", ";
        }
      });
    }
    if (result.length !== "") {
      return result.substring(0, result.length - 2);
    }
  }
  openOutlet(outletSlug, e) {
    e.preventDefault();
    this.props.history.push("/refpage/restaurant/" + outletSlug);
  }

  checkTimeAvilablity() {
    if (this.state.orderOutletId !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      if (deliveryTime !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkOutletTimesAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              this.state.defaultAvilTy +
              "&outletID=" +
              this.state.orderOutletId +
              "&orderDateTime=" +
              orderDate +
              " " +
              deliveryTime,
            headerconfig
          )
          .then((response) => {
            var res_min_date = moment(response.data.min_date).format(
              "D MMM YY"
            );
            if (response.data.status === "success") {
              this.setState(
                {
                  shopeClosed: 0,
                  res_min_date: res_min_date,
                },
                function () {
                  this.displayOutletDetails();
                }
              );
            } else {
              this.setState(
                {
                  shopeClosed: 1,
                  res_min_date: res_min_date,
                },
                function () {
                  this.displayOutletDetails();
                }
              );
            }
          });
      } else {
        this.displayOutletDetails();
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "cartclearflg") {
      this.setState({ cartClearTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      var strdate = new Date();
      var dateqwq = strdate.toTimeString();
      var dateArray = dateqwq.split(" ");
      this.setState(
        {
          viewProductSlug: value,
          showProDetails: "yes",
          proceedview: "yes",
          timestrtxt: dateArray[0],
        },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "cartOutletList" && value !== "") {
      this.setState({ cartOutletList: value });
    }
    if (field === "loadproduct" && value !== "") {
      this.setState({ loadproduct: "No" });
    }
    if (field === "showprodetails" && value !== "") {
      console.log(value, "valuevaluevaluevalue");
      this.setState({ showProDetails: value });
    }
    if (field === "proceedview" && value !== "") {
      console.log(value, "valuevaluevaluevalue");
      this.setState({ proceedview: value });
    }

    if (field === "totalCartCount" && value !== "") {
      this.setState({ totalCartCount: value }, function () {
        this.displayOutletDetails();
      });
    }
    if (field === "cartDetails" && value !== "") {
      if (
        value.cart_id !== "" &&
        typeof value.cart_id !== undefined &&
        typeof value.cart_id !== "undefined"
      ) {
        var cart_id = base64.encode(value.cart_id);
        var orderOutletId = base64.encode(cookie.load("orderOutletId"));
        var deliveryDate =
          cookie.load("deliveryDate") !== "" &&
          typeof cookie.load("deliveryDate") !== "undefined" &&
          typeof cookie.load("deliveryDate") !== undefined
            ? base64.encode(cookie.load("deliveryDate").split("/").join("-"))
            : "";
        var orderDateTime =
          cookie.load("orderDateTime") !== "" &&
          typeof cookie.load("orderDateTime") !== "undefined" &&
          typeof cookie.load("orderDateTime") !== undefined
            ? base64.encode(cookie.load("orderDateTime").split('"').join(""))
            : "";
        var deliveryTime =
          cookie.load("deliveryTime") !== "" &&
          typeof cookie.load("deliveryTime") !== "undefined" &&
          typeof cookie.load("deliveryTime") !== undefined
            ? base64.encode(cookie.load("deliveryTime"))
            : "";
        this.setState({
          cartDetails: value,
          shareGroupUrl:
            siteURL +
            "grouporder/" +
            this.state.currentPageUrl +
            "/" +
            this.state.outlet_slug +
            "/" +
            cart_id +
            "/" +
            orderOutletId +
            "/" +
            deliveryDate +
            "/" +
            orderDateTime +
            "/" +
            deliveryTime,
        });
      }
    }
    if (field === "cartGroups" && value !== "") {
      this.setState({ cartGroups: value });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug);
    /*$("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });*/
  }

  openGropShare(event) {
    if (event !== "" && event !== undefined && event !== "undefined") {
      event.preventDefault();
    }
    if (
      this.state.defaultAvilTy === "" ||
      typeof this.state.defaultAvilTy === undefined ||
      typeof this.state.defaultAvilTy === "undefined"
    ) {
      $(".required_availability").show();
      $("html, body").animate(
        {
          scrollTop: $(".required_availability").offset().top - 140,
        },
        1000
      );
      setTimeout(function () {
        $(".required_availability").hide();
      }, 3000);
      return false;
    }

    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      if (this.state.shareGroupUrl === "") {
        var outletLocationId = this.state.outletLocationId;
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var shopType =
          cookie.load("shopType") !== "" &&
          typeof cookie.load("shopType") !== undefined &&
          typeof cookie.load("shopType") !== "undefined"
            ? cookie.load("shopType")
            : "";
        var qs = require("qs");
        var postObject = {
          app_id: appId,
          outletLocationId: outletLocationId,
          availability_id: availabilityId,
          availability_name: availabilityName,
          customer_id: customerId,
          shopType: shopType,
          login_type:
            typeof cookie.load("userAccountType") === "undefined"
              ? ""
              : cookie.load("userAccountType"),
          reference_new_id: getReferenceID(),
        };
        axios
          .post(
            apiUrl + "cart/insert_cart_without_product",
            qs.stringify(postObject),
            headerconfig
          )
          .then((response) => {
            hideLoader("cancel_order", "class");
            if (response.data.status === "ok") {
              var cart_id = base64.encode(response.data.contents);
              var orderOutletId = base64.encode(cookie.load("orderOutletId"));

              var deliveryDate =
                cookie.load("deliveryDate") !== "" &&
                typeof cookie.load("deliveryDate") !== "undefined" &&
                typeof cookie.load("deliveryDate") !== undefined
                  ? base64.encode(
                      cookie.load("deliveryDate").split("/").join("-")
                    )
                  : "";
              var orderDateTime =
                cookie.load("orderDateTime") !== "" &&
                typeof cookie.load("orderDateTime") !== "undefined" &&
                typeof cookie.load("orderDateTime") !== undefined
                  ? base64.encode(
                      cookie.load("orderDateTime").split('"').join("")
                    )
                  : "";
              var deliveryTime =
                cookie.load("deliveryTime") !== "" &&
                typeof cookie.load("deliveryTime") !== "undefined" &&
                typeof cookie.load("deliveryTime") !== undefined
                  ? base64.encode(cookie.load("deliveryTime"))
                  : "";

              this.setState(
                {
                  shareGroupUrl:
                    siteURL +
                    "grouporder/" +
                    this.state.currentPageUrl +
                    "/" +
                    this.state.outlet_slug +
                    "/" +
                    cart_id +
                    "/" +
                    orderOutletId +
                    "/" +
                    deliveryDate +
                    "/" +
                    orderDateTime +
                    "/" +
                    deliveryTime,
                },
                function () {
                  $("#sharegroup-popup").modal({
                    backdrop: "static",
                    keyboard: false,
                  });
                }
              );
            } else {
            }
            return false;
          });
      } else {
        $("#sharegroup-popup").modal({ backdrop: "static", keyboard: false });
      }
      this.state.cartDetails;
    } else {
      cookie.save("openGroupShare", "Yes", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }
  copyGropurl(event) {
    event.preventDefault();
    var copyText = document.getElementById("shareGroupUrl");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    $("#coppy-success-msg").fadeIn();
    setTimeout(function () {
      $("#coppy-success-msg").fadeOut();
    }, 3000);
  }
  cancelGrouporder(event) {
    event.preventDefault();
    showLoader("cancel_order", "class");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
      cart_id: cookie.load("cart_id"),
    };
    axios
      .post(
        apiUrl + "cart/cancel_group",
        qs.stringify(postObject),
        headerconfig
      )
      .then((response) => {
        hideLoader("cancel_order", "class");
        if (response.data.status === "ok") {
          this.setState({ cartTriggerFlg: "yes" });
          cookie.remove("cart_group_id", cookieConfig);
          cookie.remove("cart_id", cookieConfig);
          cookie.remove("group_order_confirm", cookieConfig);
          this.handleShowAlertFun("success", response.data.message);
        } else {
          this.handleShowAlertFun("Error", response.data.message);
        }
        return false;
      });
  }
  cancelorder() {
    event.preventDefault();
    this.setState({ cartClearTriggerFlg: "yes" });
  }
  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  displayOutletDetails() {
    var color_code = "";
    if (
      this.state.outletslist[0] !== "" &&
      typeof this.state.outletslist[0] !== undefined &&
      typeof this.state.outletslist[0] !== "undefined"
    ) {
      let outlet = this.state.outletslist[0];
      var currentShopType = this.props.match.path;
      if (currentShopType === "/events/:slugEvent/:slugRestaurant/") {
        currentShopType = 3;
      }
      color_code = outlet.outlet_color_code;
      let restaurantdetails = (
        <div>
          <Helmet>
            <link
              rel="canonical"
              href={siteURL + restaurantLink + "/" + outlet.outlet_slug}
            />
            {outlet.outlet_meta_title !== "" &&
              outlet.outlet_meta_title !== null && (
                <meta name="title" content={outlet.outlet_meta_title} />
              )}
            {outlet.outlet_meta_description !== "" &&
              outlet.outlet_meta_description !== null && (
                <meta
                  name="description"
                  content={outlet.outlet_meta_description}
                />
              )}
            {outlet.outlet_meta_keyword !== "" &&
              outlet.outlet_meta_keyword !== null && (
                <meta name="keywords" content={outlet.outlet_meta_keyword} />
              )}
          </Helmet>
          <div className="restaurant-info">
            {/*  <div className="restaurant-info-rhs">
              <img
                src={
                  outlet.outlet_banner_image !== "" &&
                  outlet.outlet_banner_image !== null
                    ? mediaUrl + "outlet/" + outlet.outlet_banner_image
                    : innerbanner
                }
                alt={outlet.outlet_name}
                title={outlet.outlet_name}
              />
              <img
                className="restaurant-logo"
                src={
                  outlet.outlet_image !== "" && outlet.outlet_image !== null
                    ? mediaUrl + "outlet/" + outlet.outlet_image
                    : noimage
                }
                alt={outlet.outlet_name}
                title={outlet.outlet_name}
              />
            </div> */}
            <div className="restaurant-info-lhs">
              {this.state.shopeClosed === 1 && (
                <div className="restaurant-error">
                  <label>
                    This restaurant is unavailable for your selected date and
                    time.{" "}
                  </label>
                  <p>
                    To order from this restaurant, please{" "}
                    <a href={void 0} onClick={this.trgAvbltyPopup.bind(this)}>
                      update your delivery / SPU selection.
                    </a>
                  </p>
                </div>
              )}

              <div className="working-info-parent">
                <div className="working-info-parent__left">
                  <h3>
                    {outlet.outlet_name}
                    {outlet.outlet_tag_id !== "" &&
                      outlet.outlet_tag_id !== null && (
                        <span>
                          ({this.dispalyshortOutetTag(outlet.outlet_tag_id)})
                        </span>
                      )}
                  </h3>

                  <div className="working-hr">
                    {outlet.outlet_open_time.trim() !== "" && (
                      <strong>Available from {outlet.outlet_open_time}</strong>
                    )}
                  </div>
                </div>
                <div className="working-info-parent__right">
                  <h3>Table</h3>
                  <span>{cookie.load("orderTableNo")}</span>
                </div>
              </div>
            </div>

            <div className="restaurant-outlet-slider">
              <div className="container">
                <OwlCarousel options={settingsBanner}>
                  {this.state.display_Recomend_outlet}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      );
      var socilaMedia = "";
      if (
        (outlet.outlet_fb !== "" && outlet.outlet_fb !== null) ||
        (outlet.outlet_twitter !== "" && outlet.outlet_twitter !== null) ||
        (outlet.outlet_instagram !== "" && outlet.outlet_instagram !== null)
      ) {
        socilaMedia = (
          <ul>
            {outlet.outlet_fb !== "" && outlet.outlet_fb !== null && (
              <li>
                <a
                  href={outlet.outlet_fb}
                  target="_blank"
                  style={{ color: color_code }}
                >
                  <i class="fa fa-facebook-square" aria-hidden="true"></i>
                </a>
              </li>
            )}
            {outlet.outlet_twitter !== "" && outlet.outlet_twitter !== null && (
              <li>
                <a
                  href={outlet.outlet_twitter}
                  target="_blank"
                  style={{ color: color_code }}
                >
                  <i class="fa fa-twitter-square" aria-hidden="true"></i>
                </a>
              </li>
            )}
            {outlet.outlet_instagram !== "" &&
              outlet.outlet_instagram !== null && (
                <li>
                  <a
                    href={outlet.outlet_instagram}
                    target="_blank"
                    style={{ color: color_code }}
                  >
                    <i class="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
              )}
          </ul>
        );
      }
      this.setState({
        restaurantdetails: restaurantdetails,
        socilaMedia: socilaMedia,
        color_code: color_code,
      });
    }
  }
  loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}{" "}
              </span>
            );
          }
        });
      }
    }
  }

  trgAvbltyPopup(event) {
    event.preventDefault();
    /*$.magnificPopup.open({
		items: {
		  src: "#order-popup",
		},
		type: "inline",
	  });*/
    this.setState({ showOrdDateOnly: "yes" }, function () {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
    });
    return false;
  }

  loadPromo(outlet_id) {
    let promotion = this.state.promotion;
    if (promotion !== "") {
      if (
        promotion[outlet_id] !== "" &&
        typeof promotion[outlet_id] !== undefined &&
        typeof promotion[outlet_id] !== "undefined"
      ) {
        return promotion[outlet_id].map(function (item, index) {
          return <span key={index}>{item}</span>;
        });
      }
    }
  }
  changeAvailability(availability, event) {
    event.preventDefault();
    if (availability === deliveryId && this.state.deliveryPostalCode === "") {
      this.setState({ selectPostalCode: "Yes" });
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
      return false;
    }

    var orderDateTime = cookie.load("orderDateTime");
    if (availability === pickupId) {
      if (
        orderDateTime === "" ||
        typeof orderDateTime === undefined ||
        typeof orderDateTime === "undefined"
      ) {
        cookie.save("defaultAvilablityId", pickupId, cookieConfig);
        this.setState({ selectPickupTime: "Yes" });
        return false;
      }
    }

    if (this.state.defaultAvilTy !== availability) {
      axios
        .get(
          apiUrlV2 +
            "outlets/checkOutletAvailability?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&outlet_slug=" +
            this.state.outlet_slug,
          headerconfig
        )
        .then((response) => {
          if (response.data.status === "ok") {
            cookie.save("defaultAvilablityId", availability, cookieConfig);
            this.setState(
              { defaultAvilTy: availability, load_product: "No" },
              function () {
                axios
                  .get(
                    apiUrl +
                      "cart/update_availability?app_id=" +
                      appId +
                      "&reference_id=" +
                      getReferenceID() +
                      "&customer_id=" +
                      cookie.load("UserId") +
                      "&availability_id=" +
                      availability,
                    headerconfig
                  )
                  .then((res) => {
                    location.reload();
                    this.setState({ cartTriggerFlg: "yes" }, function () {
                      this.setState({ cartTriggerFlg: "yes" });
                    });
                  });

                this.props.getMenuNavigationList(
                  this.props.match.params.slugRestaurant,
                  availability,
                  this.state.currentPageUrl,
                  this.state.slugEvent
                );
              }
            );
          } else {
            $.magnificPopup.open({
              items: {
                src: "#warning-not-deliver",
              },
              type: "inline",
            });
            this.props.history.push("/" + this.state.currentPageUrl);
          }
        });
    }
  }

  getSearchProductList() {
    showLoader("restaurant_product_search", "class");
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A&availability=" +
          this.state.defaultAvilTy +
          "&outletSlug=" +
          this.state.outlet_slug,
        headerconfig
      )
      .then((response) => {
        hideLoader("restaurant_product_search", "class");
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;
                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                      description:
                        productsArr[prokey].product_short_description,
                      product_thumbnail: productsArr[prokey].product_thumbnail,
                      product_thumbnail_title:
                        productsArr[prokey].product_thumbnail_title,
                      product_thumbnail_alt:
                        productsArr[prokey].product_thumbnail_alt,
                      product_price: productsArr[prokey].product_price,
                      product_cost: productsArr[prokey].product_cost,
                      product_stock: productsArr[prokey].product_stock,
                    });
                  }
                }
              }
            }
          }
        }
        this.setState({ searchProResult: searchResult });
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();
    var value = event.target.value.toLowerCase();
    let matches = "";
    if (value !== "") {
      console.log(this.state.searchProResult, "aaaaa");
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item.label;
        }
      });
    }
    this.setState({ selectedProResult: matches, searchKeyWord: value });
  };
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            to={
              "/" +
              this.state.currentPageUrl +
              "/" +
              this.state.outlet_slug +
              "/" +
              loaddata.cate_slug +
              "/" +
              loaddata.subcate_slug +
              "/" +
              loaddata.value
            }
            onClick={this.openProductLink.bind(
              this,
              "/" +
                this.state.currentPageUrl +
                "/" +
                this.state.outlet_slug +
                "/" +
                loaddata.cate_slug +
                "/" +
                loaddata.subcate_slug +
                "/" +
                loaddata.value
            )}
            title="Product Details"
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      if (this.state.searchKeyWord !== "") {
        return <li className="no-pro-found">No Product found</li>;
      } else {
        return "";
      }
    }
  }

  openProductLink(plink, event) {
    event.preventDefault();
    $("#searchKeyWord").val("");
    this.setState({ selectedProResult: [], searchKeyWord: "" }, function () {
      this.props.history.push(plink);
    });
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, cookieConfig);
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, cookieConfig);
      this.setState({ unitnumber2: event.target.value });
    }
  }
  openOrderType() {
    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  render() {
    let completGroupOrder = "";

    let outlet = (this.state.outletslist || [])[0] || null;

    if (this.state.cart_group_id !== "") {
      if (
        this.state.cartGroups[this.state.cart_group_id] !== "" &&
        typeof this.state.cartGroups[this.state.cart_group_id] !== undefined &&
        typeof this.state.cartGroups[this.state.cart_group_id] !== "undefined"
      ) {
        if (
          this.state.cartGroups[this.state.cart_group_id].cart_group_status ===
          "C"
        ) {
          completGroupOrder = "Y";
        }
      }
    }
    var totalGroup = 0;
    if (Object.keys(this.state.cartGroups).length) {
      for (var groupO in this.state.cartGroups) {
        /* if (this.state.cartGroups[groupO].cart_group_status === "C") { */
        totalGroup++;
        /* } */
      }
      /* totalGroup =
        parseInt(Object.keys(this.state.cartGroups).length) - 1 + parseInt(1); */
    }
    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          cartClearTriggerFlg={this.state.cartClearTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
          selectPostalCode={this.state.selectPostalCode}
          selectPickupTime={this.state.selectPickupTime}
          showOrdDateOnly={this.state.showOrdDateOnly}
        />
        {/* Header End */}

        <div className="restaurant-outlet-list">
          <div className="restaurant-outlet-info">
            <div className="container">
              <div className="bluebar-parent">
                <div className="bluebar-lhs">
                  <input
                    type="text"
                    placeholder="Select Order Type"
                    readOnly
                    value={
                      this.state.defaultAvilTy === deliveryId
                        ? "Delivery"
                        : this.state.defaultAvilTy === dineinId
                        ? "Dine In"
                        : "Takeaway"
                    }
                    onClick={this.openOrderType.bind(this)}
                  />
                </div>
                <div className="bluebar-middle">
                  {this.state.defaultAvilTy === deliveryId && (
                    <ul>
                      <li>
                        Deliver to{" "}
                        <strong>S{cookie.load("orderPostalCode")}</strong>{" "}
                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                      </li>
                      <li>
                        <input
                          type="text"
                          placeholder="Block"
                          id="unit_no1_id"
                          name="unit_no1"
                          value={this.state.unitnumber1}
                          className="form-control input-focus"
                          onChange={this.handleAddrChange.bind(this)}
                        />
                      </li>
                      <li>
                        <input
                          type="text"
                          placeholder="Unit No"
                          name="unit_no2"
                          value={this.state.unitnumber2}
                          className="form-control input-focus"
                          onChange={this.handleAddrChange.bind(this)}
                        />
                      </li>
                    </ul>
                  )}
                </div>
                <div className="bluebar-rhs">
                  <input type="text" placeholder="Order Date & Time" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="restaurant-details">
          <div className="container restaurant-main">
            <div className="restaurant-details-rhs">
              {!!outlet && (
                <div className="restaurant-image-mobile">
                  <img
                    src={
                      outlet.outlet_banner_image !== "" &&
                      outlet.outlet_banner_image !== null
                        ? mediaUrl + "outlet/" + outlet.outlet_banner_image
                        : innerbanner
                    }
                    alt={outlet.outlet_name}
                    title={outlet.outlet_name}
                  />
                </div>
              )}
              {this.state.navigateMenu.length > 0 &&
                window.innerWidth < 981 && (
                  <MenuNavigation
                    {...this.props}
                    mobile
                    displaysec="mobile"
                    productState={this.state}
                    navigateMenu={this.state.navigateMenu}
                  />
                )}
              {this.state.restaurantdetails}
              <div className="search-product">
                <div className="spm-lhs restaurant_product_search">
                  <input
                    type="text"
                    className="search-rest"
                    id="searchKeyWord"
                    placeholder="Search within menu."
                    onKeyUp={this.searchProKeyPress}
                  />
                  <img className="search-glass" src={zoomimg} alt="" />

                  <ul
                    className="search_result"
                    id="search_result"
                    style={{ display: "none" }}
                  >
                    {this.loadProSearchList()}
                  </ul>
                </div>
              </div>
              {this.state.navigateMenu.length > 0 &&
                window.innerWidth > 980 && (
                  <MenuNavigation
                    {...this.props}
                    mobile={false}
                    displaysec="mobile"
                    productState={this.state}
                    navigateMenu={this.state.navigateMenu}
                  />
                )}
              {this.state.socilaMedia !== "" && (
                <div className="restaurant-mobile-social">
                  <span>Follow us</span>
                  {this.state.socilaMedia}
                </div>
              )}
              {this.state.restaurantdetails === "" && (
                <div className="no-product">
                  <div id="dvLoading" className="dvLoadrCls"></div>
                </div>
              )}

              {
                /* this.state.navigateMenu.length > 0 && ( */
                <div className="product-menu-listing">
                  <ProductList
                    {...this.props}
                    productState={this.state}
                    sateValChange={this.sateValChange}
                    cartOutletList={this.state.cartOutletList}
                    navigateMenu={this.state.navigateMenu}
                    color_code={this.state.color_code}
                  />
                  <li className="htico_cart">
                    <CartSideBar
                      {...this.props}
                      showAlways
                      headerState={this.state}
                      prpSateValChange={this.sateValChange}
                      cartTriggerFlg={this.props.cartTriggerFlg}
                      cartClearTriggerFlg={this.props.cartClearTriggerFlg}
                      cart_Outlet_List={this.cart_Outlet_List}
                    />
                  </li>
                </div>
                /* ) */
              }
            </div>
          </div>
        </section>

        {/* Footer section */}
        <Footer />
        <ProductDetail
          {...this.props}
          productState={this.state}
          sateValChange={this.sateValChange}
          cartOutletList={this.state.cartOutletList}
          color_code={this.state.color_code}
        />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  var tableAvailability = Array();
  if (Object.keys(state.tableavailability).length > 0) {
    // if (state.tableavailability[0].status === "ok") {
    tableAvailability = state.tableavailability[0];
    // }
  }

  return {
    globalsettings: state.settings,
    /* navigateMenu: navigateRst, */
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    alloutlets: state.alloutlets,

    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    secondaryaddresslist: secondarydataArr,
    staticblack: blacksArr,
    tableAvailability: tableAvailability,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (availability, outlet_slug) => {
      dispatch({ type: GET_ALL_OUTLETS, availability, outlet_slug });
    },

    getMenuNavigationList: (
      outlet_slug,
      availability,
      currentPageUrl,
      slugEvent
    ) => {
      dispatch({
        type: GET_MENU_NAVIGATION,
        outlet_slug,
        availability,
        currentPageUrl,
        slugEvent,
      });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Index);
