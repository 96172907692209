/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import mainLogo from "../../common/images/footer-logo.png";
import { appId, apiUrl } from "../Helpers/Config";

import { GET_STATIC_BLOCK } from "../../actions";
var Parser = require("html-react-parser");
import axios from "axios";
var qs = require("qs");
import { showAlert } from "../Helpers/SettingHelper";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      footerBlocks: "",
      footerlink: "",
      newsLetterEmail: "",
      isChecked: false,
    };
  }

  componentDidMount() {
    this.props.getStaticBlock();

    var btn = $("#scrollbutton");

    $(window).scroll(function () {
      if ($(window).scrollTop() > 300) {
        btn.addClass("show");
      } else {
        btn.removeClass("show");
      }
    });

    btn.on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.footerBlocks) {
      var footerPas = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "footer-link") {
            footerPas = data.staticblocks_description;
            return "";
          }
        });
      }
      footerPas = footerPas !== "" ? Parser(footerPas) : footerPas;
      this.setState({
        footerBlocks: PropsData.staticblack,
        footerlink: footerPas,
      });
    }
  }

  toggleChange = () => {
    this.setState({
      isChecked: !this.state.isChecked,
    });
  };
  checkEmail() {
    /* if (this.state.newsLetterEmail !== "" && this.state.isChecked == true) { */
    var postObject = {
      app_id: appId,
      email: this.state.newsLetterEmail,
    };
    axios
      .post(apiUrl + "newsletter/subscription_new", qs.stringify(postObject))
      .then((res) => {
        if (res.data.status === "ok") {
          var magnfPopup = $.magnificPopup.instance;
          showAlert("Success", "Newsletter Subscribed", magnfPopup);
          setTimeout(function () {
            window.location.reload();
          }, 3000);
        } else {
          showAlert("Error", "Enter a valid email", magnfPopup);
        }
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      });
    /*  } */
  }

  handleChangeemail(event) {
    this.setState({ newsLetterEmail: event.target.value });
  }

  render() {
    var todayTimeSp = new Date();
    var yearSp = todayTimeSp.getFullYear();

    return (
      <footer className="footer-main">
        <div className="container">
          <div className="footer-top">
            <div className="footer-newletter">
              <h5>Members-only Club</h5>
              <p>
                While Singapore Swimming Club remains a premier members-only
                club, we welcome members to introduce guests to the Club. All
                guests have to be signed in by members.{" "}
              </p>
              <em>
                The Premier Family Club where the People make the Difference
              </em>
            </div>

            <div className="footer-logo">
              <a href="#" title="Logo" className="float-logo">
                <img src={mainLogo} alt="Logo" />
              </a>
              <div className="company-info">
                <h5>Singapore Swimming Club</h5>
                <p>
                  <a href="#">(65) 6916 4001</a>{" "}
                </p>
                <p>
                  <a href="#">fnb@sswimclub.org.sg</a>
                </p>
              </div>
              <div className="social-media">
                <a href="https://www.instagram.com" target="_blank"></a>
              </div>
            </div>

            <div className="footer-nav">
              <div className="footer-nav-inner">
                <h5>About Us</h5>
                <ul>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/terms-conditions">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
              <div className="footer-nav-inner">
                <h5>Helpful Links</h5>
                <ul>
                  <li>
                    <a href="/faq">Delivery Information</a>
                  </li>
                  <li>
                    <a href="/faq">FAQ</a>
                  </li>
                  <li>
                    <a href="/terms-conditions">Terms &amp; Conditions</a>
                  </li>
                  <li>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </li>
                </ul>
              </div>
            </div>

            {/* {this.state.footerlink}*/}

            <div className="mobile-footer-nav">
              <Accordion>
                <AccordionItem title="About Us">
                  <ul>
                    <li>
                      <a href="/page/about-us">About Us</a>
                    </li>
                    <li>
                      <a href="/locations">Visit Us</a>
                    </li>
                  </ul>
                </AccordionItem>
                <AccordionItem title="Helpful Links">
                  <ul>
                    <li>
                      <Link to={"/faq"}>FAQ</Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <div className="container">
            <p>{yearSp} Singapore Swimming Club All rights reserved</p>
          </div>
        </div>

        <div className="scrolltop" id="scrollbutton">
          <a href="/" className="disbl_href_action">
            <span>
              <i className="fa fa-angle-up ars2"></i>
            </span>
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    staticblack: blacksArr,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);
