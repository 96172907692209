/* eslint-disable */
import cookie from "react-cookies";
import { deliveryId, cookieConfig } from "./Config";
import $ from "jquery";
import moment from "moment";
/* stripslashes  */
export const stripslashes = function (str) {
  if (str !== "" && typeof str !== undefined && typeof str !== "undefined") {
    str = str.replace(/\\'/g, "'");
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, "\0");
    str = str.replace(/\\\\/g, "\\");
    return str;
  }
};

/* Random ID  */
export const randomId = function () {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (var i = 0; i < 50; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

/* sample funtion */
export const showSubTotalValue = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = price.toFixed(2);
  return priceTxt;
};

/*Reference ID Generate*/
export const getReferenceID = function () {
  if (typeof cookie.load("referenceId") === "undefined") {
    var randomKey = randomId();
    cookie.save("referenceId", randomKey, cookieConfig);
    return randomKey;
  } else {
    return cookie.load("referenceId");
  }
};

/*remove promotion value*/
export const removePromoCkValue = function () {
  cookie.remove("reedemPointVal", cookieConfig);
  cookie.remove("promoCodeVal", cookieConfig);
  cookie.remove("promoTitle", cookieConfig);
  cookie.remove("promotionApplied", cookieConfig);
  cookie.remove("promotionType", cookieConfig);
  cookie.remove("promotionAmount", cookieConfig);
  cookie.remove("promotionSource", cookieConfig);
  cookie.remove("promoIsDelivery", cookieConfig);
  cookie.remove("usedPoints", cookieConfig);
  cookie.remove("promoOutlet", cookieConfig);
  cookie.remove("promoType", cookieConfig);
  cookie.remove("promoPercentage", cookieConfig);
  cookie.remove("promooutletproducts", cookieConfig);
  cookie.remove("promotionID", cookieConfig);
};

/*remove order date time*/
export const removeOrderDateTime = function () {
  cookie.remove("orderDateTime", cookieConfig);
  cookie.remove("deliveryDate", cookieConfig);
  cookie.remove("deliveryTime", cookieConfig);
  cookie.remove("deliveryPincodeText", cookieConfig);
  cookie.remove("pickupLocationText", cookieConfig);
  cookie.remove("deliveryPickupDatetimetext", cookieConfig);
};

/*remove promotion value*/
export const getPromoCkValue = function () {
  var reedemPointVal =
    typeof cookie.load("reedemPointVal") === "undefined"
      ? ""
      : cookie.load("reedemPointVal");
  var promoCodeVal =
    typeof cookie.load("promoCodeVal") === "undefined"
      ? ""
      : cookie.load("promoCodeVal");
  var promotionApplied =
    typeof cookie.load("promotionApplied") === "undefined"
      ? ""
      : cookie.load("promotionApplied");
  var promotionType =
    typeof cookie.load("promotionType") === "undefined"
      ? ""
      : cookie.load("promotionType");
  var promotionAmount =
    typeof cookie.load("promotionAmount") === "undefined"
      ? ""
      : cookie.load("promotionAmount");
  var promotionSource =
    typeof cookie.load("promotionSource") === "undefined"
      ? ""
      : cookie.load("promotionSource");
  var promoIsDelivery =
    typeof cookie.load("promoIsDelivery") === "undefined"
      ? ""
      : cookie.load("promoIsDelivery");
  var usedPoints =
    typeof cookie.load("usedPoints") === "undefined"
      ? 0
      : cookie.load("usedPoints");
  var promoOutlet =
    typeof cookie.load("promoOutlet") === "undefined"
      ? 0
      : cookie.load("promoOutlet");
  var promoType =
    typeof cookie.load("promoType") === "undefined"
      ? 0
      : cookie.load("promoType");
  var promoPercentage =
    typeof cookie.load("promoPercentage") === "undefined"
      ? 0
      : cookie.load("promoPercentage");

  var resultArr = [];
  resultArr["reedemPointVal"] = reedemPointVal;
  resultArr["promoCodeVal"] = promoCodeVal;
  resultArr["promotionApplied"] = promotionApplied;
  resultArr["promotionType"] = promotionType;
  resultArr["promotionAmount"] = promotionAmount;
  resultArr["promotionSource"] = promotionSource;
  resultArr["promoIsDelivery"] = promoIsDelivery;
  resultArr["usedPoints"] = usedPoints;
  resultArr["promoOutlet"] = promoOutlet;
  resultArr["promoType"] = promoType;
  resultArr["promoPercentage"] = promoPercentage;
  return resultArr;
};

/* address format */
export const addressFormat = function (
  unitOne,
  unitTwo,
  addressOne,
  addressTwo,
  postCode
) {
  var unit =
    unitTwo !== "" && unitOne !== ""
      ? "#" + unitOne + "-" + unitTwo + ","
      : unitOne !== ""
      ? "#" + unitOne + ","
      : "";
  unit = addressOne !== "" ? unit + addressOne + "," : unit;
  unit = addressTwo !== "" ? unit + addressTwo + "," : unit;
  unit = postCode !== "" ? unit + " Singapore " + postCode : unit;

  return unit !== "" ? unit.replace(/,\s*$/, "") : "N/A";
};

/* delivery charge */
export const getCalculatedAmount = function (
  settingsArr,
  zoneDetailsArr,
  cartDetailsArr,
  promoTionArr,
  siteLocID,
  outletItems = "",
  outletTax = ""
) {
  var deliveryAmount = 0;
  var additionalDelivery = 0;
  var freeDeliveryAmnt = 0;
  var orderDisplayGst = 0;
  var orderGstAmount = 0;
  var cartSubTotal = 0;
  var promotionAmount = 0;
  var grandTotalAmount = 0;

  var promotionApplied = "";
  var promotionTypeTxt = "";
  var zoneName = "";

  if (Object.keys(settingsArr).length > 0) {
    deliveryAmount =
      settingsArr.client_delivery_surcharge !== ""
        ? parseFloat(settingsArr.client_delivery_surcharge)
        : 0;
    additionalDelivery =
      settingsArr.additional_delivery_charge !== ""
        ? parseFloat(settingsArr.additional_delivery_charge)
        : 0;
    freeDeliveryAmnt =
      settingsArr.client_free_delivery_amount !== ""
        ? parseFloat(settingsArr.client_free_delivery_amount)
        : 0;
    /* orderDisplayGst =
      settingsArr.client_tax_surcharge !== ""
        ? parseFloat(settingsArr.client_tax_surcharge)
        : 0; */
  }
  if (Object.keys(zoneDetailsArr).length > 0) {
    deliveryAmount =
      zoneDetailsArr[0].zone_delivery_charge !== ""
        ? parseFloat(zoneDetailsArr[0].zone_delivery_charge)
        : 0;
    additionalDelivery =
      zoneDetailsArr[0].zone_additional_delivery_charge !== ""
        ? parseFloat(zoneDetailsArr[0].zone_additional_delivery_charge)
        : 0;
    freeDeliveryAmnt =
      zoneDetailsArr[0].zone_free_delivery !== ""
        ? parseFloat(zoneDetailsArr[0].zone_free_delivery)
        : 0;
    zoneName =
      zoneDetailsArr[0].zone_name !== "" ? zoneDetailsArr[0].zone_name : "";
  }

  if (Object.keys(cartDetailsArr).length > 0) {
    cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);
  }
  var applyfreedelivery = cookie.load("applyfreedelivery");
  if (
    freeDeliveryAmnt > 0 &&
    freeDeliveryAmnt <= cartSubTotal &&
    applyfreedelivery === "Yes"
  ) {
    deliveryAmount = 0;
  }
  var promoCodeVal = "";
  var promotionApplied = "";
  if (promoTionArr["promotionApplied"] === "Yes") {
    promotionApplied = "Yes";
    promoCodeVal = cookie.load("promoCodeVal");
    if (promoTionArr["promoIsDelivery"] === "Yes") {
      deliveryAmount = 0;
    } else {
      promotionAmount = promoTionArr["promotionAmount"];
    }
  }
  if (cookie.load("defaultAvilablityId") !== deliveryId) {
    deliveryAmount = 0;
    additionalDelivery = 0;
  }

  grandTotalAmount =
    cartSubTotal + deliveryAmount + additionalDelivery - promotionAmount;

  if (orderDisplayGst > 0) {
    //orderGstAmount = (orderDisplayGst / 100) * grandTotalAmount;
  }

  if (outletTax !== "" && outletItems !== "") {
    if (
      Object.keys(outletTax).length > 0 &&
      Object.keys(outletItems).length > 0
    ) {
      Object.keys(outletItems).map((index2) => {
        Object.keys(outletItems[index2]).map((index) => {
          Object.keys(outletItems[index2][index]).map((index1) => {
            var cartTax =
              outletTax[index1] !== "" &&
              typeof outletTax[index1] !== undefined &&
              typeof outletTax[index1] !== "undefined"
                ? outletTax[index1]
                : 0;
            let productTotalAmt = 0;
            if (parseFloat(cartTax) > 0) {
              outletItems[index2][index][index1].map((item) => {
                productTotalAmt =
                  parseFloat(productTotalAmt) +
                  parseFloat(item.cart_item_total_price);
              });
              orderGstAmount =
                parseFloat(orderGstAmount) +
                parseFloat((cartTax / 100) * productTotalAmt);
            }
          });
        });
      });
    }
  }
  orderGstAmount = 0;
  grandTotalAmount = parseFloat(grandTotalAmount) + parseFloat(orderGstAmount);
  var resultArr = [];
  resultArr["deliveryCharge"] = deliveryAmount;
  resultArr["additionalDelivery"] = additionalDelivery;
  resultArr["freeDeliveryAmnt"] = freeDeliveryAmnt;
  resultArr["promotionApplied"] = promotionApplied;
  resultArr["promotionTypeTxt"] = promotionTypeTxt;
  resultArr["promoCodeVal"] = promoCodeVal;

  resultArr["promotionAmount"] = promotionAmount;
  resultArr["orderDisplayGst"] = orderDisplayGst;
  resultArr["orderGstAmount"] = orderGstAmount.toFixed(2);
  resultArr["cartSubTotalAmount"] = cartSubTotal.toFixed(2);
  resultArr["grandTotalAmount"] = grandTotalAmount.toFixed(2);
  resultArr["zoneName"] = zoneName;

  return resultArr;
};

/* show Alert */
export const searchFilters = function (needles, haystack) {
  var l = 0;
  for (var i = 0; i < needles.length; i++) {
    if ($.inArray(needles[i], haystack) >= 0) {
      l++;
    }
  }
  if (l == 0) {
    return false;
  } else {
    return true;
  }
};
/* show Alert */
export const showAlert = function (
  header,
  message,
  autoClose = "No",
  popupconfig
) {
  $(".alert_popup").remove();
  if (autoClose === "No") {
    /* <div class="alert_header">' +
        header +
        ' */
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="edit-alert-body"><h4>' +
        header +
        "</h4><p>" +
        message +
        '</p><div class="button-full"><a href="javascript:;" class="popup-modal-dismiss button">OK</a></div></div></div></div></div></div>'
    );
  } else {
    /* <div class="alert_header">' +
        header +
        ' */
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="edit-alert-body"><h4>' +
        header +
        "</h4><p>" +
        message +
        '</p><div class="button-full"></div></div></div></div></div></div>'
    );
    setTimeout(function () {
      popupconfig.close();
    }, 3000);
  }
};

export const showToastrAlert = function (message) {
  if (message !== "") {
    $("body").append('<div class="toaser-alert">' + message + "</div>");
    setTimeout(function () {
      $("body .toaser-alert").fadeOut();
    }, 5000);
  }
};

/* smooth Scroll */
export const smoothScroll = function (limit, value) {
  var limitTxt = limit !== "" ? parseInt(limit) : 0;
  var valueTxt = value !== "" ? parseInt(value) : 0;

  var stickyTop = $(window).scrollTop();
  if (stickyTop > limitTxt) {
    var i = 10;
    var int = setInterval(function () {
      window.scrollTo(0, i);
      i += 10;
      if (i >= valueTxt) clearInterval(int);
    }, 20);
  }
};

/* show Custom Alert */
export const showCustomAlert = function (type, message, clsIdTxt) {
  if (clsIdTxt === "") {
    var clsIdTxt =
      type === "success" ? "jquery-success-msg" : "jquery-error-msg";
  }
  $(".custom_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_alertcls").hide();
  }, 6000);
};

/* show Custom Center Alert */
export const showCustomCenterAlert = function (type, message) {
  var clsIdTxt =
    type === "success"
      ? "jquery-common-success-msg"
      : "jquery-common-error-msg";
  $(".custom_center_alertcls").hide();
  $("." + clsIdTxt).html(message);
  $("#" + clsIdTxt).fadeIn();
  setTimeout(function () {
    $(".custom_center_alertcls").hide();
  }, 6000);
};

/* show Cart Count */
export const showCartItemCount = function (cartDetail) {
  var itemCount =
    Object.keys(cartDetail).length > 0 ? cartDetail.cart_total_items : 0;
  var subTotal =
    Object.keys(cartDetail).length > 0 ? cartDetail.cart_sub_total : 0;
  cookie.save("cartTotalItems", itemCount, cookieConfig);
  $(".hcart_round").html(itemCount);
  if (parseFloat(subTotal) > 0) {
    $(".crttotl_amt").show();
  } else {
    $(".crttotl_amt").hide();
  }
  var subTotalHtml = "<sup>$</sup>" + subTotal;
  $(".crttotl_amt").html(subTotalHtml);
};

export const capitalize_Words = function (str) {
  if (str !== "" && typeof str !== undefined && typeof str !== "undefined") {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
};

/* show Loader */
export const showLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  } else {
    $("#" + divRef)
      .addClass("loader-main-cls")
      .append('<div class="loader-sub-div"></div>');
  }
};

/* hide Loader */
export const hideLoader = function (divRef, type) {
  if (type === "class") {
    $("." + divRef).removeClass("loader-main-cls");
    $("." + divRef)
      .find(".loader-sub-div")
      .remove();
  } else {
    console.log($("#" + divRef).length, "loader-main-cls");
    $("#" + divRef).removeClass("loader-main-cls");
    $("#" + divRef)
      .find(".loader-sub-div")
      .remove();
  }
};

/* get subtotal value  */
export const getsubTotal = function (
  subTotal,
  OriginalAmount,
  promotionApplied,
  redeemptionApplied = null
) {
  if (promotionApplied === "Yes") {
    return subTotal;
  } else if (redeemptionApplied === "Yes") {
    return subTotal;
  } else {
    return OriginalAmount;
  }
};

/* get subtotal value  */
export const getDeliveryCharge = function (
  promotionApplied,
  deliveryEnabled,
  OriginalAmount,
  isFreeDelivery = null
) {
  if (
    (promotionApplied === "Yes" && deliveryEnabled === "Yes") ||
    isFreeDelivery === "Yes"
  ) {
    return 0;
  } else {
    return OriginalAmount;
  }
};

/* sample funtion */
export const showPriceValue = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = "$" + price.toFixed(2);
  return priceTxt;
};

/* sample funtion */
export const getGstValue = function (gst, subtotal, format) {
  gst = parseFloat(gst);
  subtotal = parseFloat(subtotal);
  var gstResult = 0;

  if (gst > 0) {
    gstResult = (gst / 100) * subtotal;
  }

  if (format === "format") {
    return gstResult.toFixed(2);
  } else {
    return gstResult;
  }
};

/* GST Reverse Calculation funtion */
export const getReverseGST = function (total) {
  var vatDivisor = 1 + 7 / 100;
  var gstpercentage = 7 / 100;
  var productvalue = total / vatDivisor;
  var gst = productvalue * gstpercentage;
  return "GST Inclusive (7%): $" + gst.toFixed(2);
};

/* time conversion  */
export const timeToConv12 = function (time24) {
  var ts = time24;
  if (ts !== "" && typeof ts !== "undefined") {
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
  }
  return ts;
};

/* Date conversion  */
export const getOrderDateTime = function (dateTxt, TatTxt) {
  var dateStr = new Date();
  var TatTxtVl =
    TatTxt !== "" && typeof TatTxt !== "undefined" ? parseInt(TatTxt) : 0;
  var deliveryTime =
    typeof cookie.load("deliveryTime") === "undefined"
      ? ""
      : cookie.load("deliveryTime");
  if (dateTxt !== "" && typeof dateTxt !== "undefined" && deliveryTime !== "") {
    dateTxt = dateTxt.replace(/\"/g, "");
    var dateTxtArr = dateTxt.split("T");
    var selectTmArr = deliveryTime.split(":");
    var seletedDate = new Date(dateTxtArr[0]);
    seletedDate.setHours(parseInt(selectTmArr[0]));
    seletedDate.setMinutes(parseInt(selectTmArr[1]));
    seletedDate.setSeconds(0);
    dateStr = seletedDate;
  } else {
    var CurrentDate = new Date();
    CurrentDate.setMinutes(CurrentDate.getMinutes() + TatTxtVl);
    dateStr = CurrentDate;
  }

  return dateStr;
};

/* Date conversion  */
export const dateConvFun = function (dateTxt, type) {
  var dateStr = dateTxt;
  if (dateStr !== "" && typeof dateStr !== "undefined") {
    var newDateTxtone = dateTxt.replace(/-/g, "/");
    var todayTime = new Date(newDateTxtone);
    var month = todayTime.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    var day = todayTime.getDate();
    day = day > 9 ? day : "0" + day;
    var year = todayTime.getFullYear();

    if (type === 1) {
      dateStr = day + "/" + month + "/" + year;
    } else if (type === 2) {
      dateStr = day + "-" + month + "-" + year;
    }
  }

  return dateStr;
};

/* Date conversion  */
export const getTimeFrmDate = function (timeTxt, type) {
  var timeStr = timeTxt;
  if (timeStr !== "" && typeof timeStr !== "undefined") {
    var newtimeStr = timeStr.replace(/-/g, "/");
    var todayTime = new Date(newtimeStr);
    var hours = todayTime.getHours();
    var minut = todayTime.getMinutes();

    hours = parseInt(hours) < 10 ? "0" + hours : hours;
    minut = parseInt(minut) < 10 ? "0" + minut : minut;

    if (type === 1) {
      timeStr = hours + " : " + minut;
    } else if (type === 2) {
      timeStr = hours + ":" + minut;
      timeStr = timeToConv12(timeStr);
    }
  }

  return timeStr;
};

/* Date conversion  */
export const getCurrentDateTm = function () {
  var dateTimeStr = "";
  var todayTime = new Date();

  var month = todayTime.getMonth() + 1;
  month = month > 9 ? month : "0" + month;
  var day = todayTime.getDate();
  day = day > 9 ? day : "0" + day;
  var year = todayTime.getFullYear();

  var hours = todayTime.getHours();
  var minut = todayTime.getMinutes();
  var second = todayTime.getSeconds();

  hours = parseInt(hours) < 10 ? "0" + hours : hours;
  minut = parseInt(minut) < 10 ? "0" + minut : minut;
  second = parseInt(minut) < 10 ? "0" + second : second;

  dateTimeStr =
    year + "-" + month + "-" + day + " " + hours + ":" + minut + ":" + second;

  return dateTimeStr;
};

/* sample funtion */
export const validateEmailFun = function (mailIdVal) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailIdVal)) {
    return true;
  }

  return false;
};

/* sample funtion */
export const showCartLst = function () {
  setTimeout(function () {
    $(".hcart_dropdown").toggleClass("open");
    $(".hcartdd_trigger").toggleClass("active");
  }, 1000);
};

/* sample funtion */
export const resetCrtStyle = function () {
  $(".cart_body").find(".cart_row").removeAttr("style");
};

/* sample funtion */
export const getAliasName = function (alias, productName) {
  return alias !== "" ? alias : productName;
};

/* Uc first funtion */
export const jsUcfirstFun = function (string) {
  if (string !== "") {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};
export const firstLetterCapsFun = function (str) {
  if (str !== "" && typeof str !== undefined && typeof str !== "undefined") {
    var arr = str.split("-");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    var str2 = arr.join(" ");
    return str2;
  }
};

export const loadHeaderTime = function (string) {
  if (string !== "") {
    var todayDate = new Date();

    var Month = parseInt(todayDate.getMonth()) + 1;
    if (Month <= 9) {
      Month = "0" + Month;
    }
    var DateDisplay = parseInt(todayDate.getDate());
    if (DateDisplay <= 9) {
      DateDisplay = "0" + DateDisplay;
    }

    var date = todayDate.getFullYear() + "-" + Month + "-" + DateDisplay;
    if (todayDate !== "Invalid date") {
      return moment(date + " " + string).format("hh:mm A");
    } else {
      return "-";
    }
  } else {
    return "";
  }
};

/* int to str funtion */
export const cnvrtStr = function (d) {
  return d < 10 ? "0" + d.toString() : d.toString();
};
