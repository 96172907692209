/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
var base64 = require("base-64");
import noproduct from "../../../common/images/no-img-product.png";
import seeall from "../../../common/images/seeall-arrow.svg";

import { GET_PROMOTIONLIST } from "../../../actions";
var promoBanner = {
  items: 3,
  dots: false,
  autoplay: true,
  nav: true,
  margin: 18,
  loop: true,
  responsive: {
    0: {
      items: 1,
      center: true,
      stagePadding: 30,
    },
    700: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};
class Promodeals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promotions: [],
    };
    var customerParam = "";
    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    customerParam += "&home_flag=1";
    this.props.getPromotionList(customerParam);
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.promotionlist !== this.props.promotionlist) {
      if (PropsData.promotionlist[0].status === "ok") {
        this.setState({
          promotions: PropsData.promotionlist[0].result_set.my_promo,
        });
      }
    }
  }

  render() {
    if (this.state.promotions.length > 0) {
      return (
        <section className="promo-home" id="swimlane_sec2">
          <div className="container">
            <div className="promo-home-header">
              <h4>Promotions</h4>
            </div>
            <div className="promo-banner">
              <OwlCarousel options={promoBanner}>
                {this.state.promotions.map((item, index) => {
                  return (
                    <div key={index}>
                      <Link
                        to={"/promotions/" + base64.encode(item.promotion_id)}
                      >
                        <img
                          src={
                            item.promotion_image !== "" &&
                            item.promotion_image !== null
                              ? this.props.promoSource + item.promotion_image
                              : noproduct
                          }
                          alt={item.promotion_title}
                          title={item.promotion_title}
                        />
                      </Link>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </section>
      );
    } else {
      return <></>;
    }
  }
}

const mapStateTopProps = (state) => {
  var promoSource = "";
  if (Object.keys(state.promotionlist).length > 0) {
    promoSource = state.promotionlist[0].common.promo_image_source;
  }
  return {
    promotionlist: state.promotionlist,
    promoSource: promoSource,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Promodeals);
