/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  showAlert,
  showLoader,
  hideLoader,
  showCustomAlert,
  showToastrAlert,
} from "../Helpers/SettingHelper";
import { appId, apiUrl, headerconfig, cookieConfig } from "../Helpers/Config";
import cookie from "react-cookies";
import axios from "axios";

import PropTypes from "prop-types";
import "../../common/css/owl.carousel.css";
import { connect } from "react-redux";

import moment from "moment";

import {
  GET_PROMOTIONLIST,
  GET_PROMOTIONRECEIPT,
  GET_APPLYPROMOTION,
} from "../../actions";
import promotionImage from "../../common/images/promo-placeholder.png";
var Parser = require("html-react-parser");
var qs = require("qs");
class Promotions extends Component {
  constructor(props) {
    super(props);
    var promoID =
      this.props.match.params.promoID !== "" &&
      typeof this.props.match.params.promoID !== undefined &&
      typeof this.props.match.params.promoID !== "undefined"
        ? this.props.match.params.promoID
        : "";
    this.state = {
      promoused: [],
      promoID: promoID,
      promotions: [],
      status: "",
      promo: [],
      cartItems: [],
      promodetails: [],
      promodetailsdispaly: "",
      cartDetails: [],
      cart_sub_total: 0,
      userID:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") !== "undefined"
          ? cookie.load("UserId")
          : "",
      loadingPromo: true,
    };
  }

  componentDidMount() {
    var customerParam = "&customer_id=" + cookie.load("UserId");
    this.props.getPromotionList(customerParam);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadingPromo !== this.state.loadingPromo) {
      this.setState({ loadingPromo: nextProps.loadingPromo }, function () {
        $("#dvLoading").fadeOut(2000);
      });
    }
    if (nextProps.match.params.promoID !== this.state.promoID) {
      this.setState({ promoID: "", promodetailsdispaly: "" });
    }
    if (nextProps.promotionlist !== this.props.promotionlist) {
      if (nextProps.promotionlist[0].status === "ok") {
        this.setState({
          status: nextProps.promotionlist[0].status,
          promotions: nextProps.promotionlist[0].result_set.my_promo,
        });
      }
    }
    if (nextProps.promotionreceipt !== this.props.promotionreceipt) {
      if (nextProps.promotionreceipt[0].status === "ok") {
        this.setState(
          {
            promodetails: nextProps.promotionreceipt[0].result_set,
          },
          function () {
            this.displayPromoDetails();
          }
        );
      }
    }
  }

  /*  get promo category details  */
  getProductIdsDet(cartItemsArr) {
    var product_cartid = "";
    if (Object.keys(cartItemsArr).length > 0) {
      Object.keys(cartItemsArr).map((index) => {
        Object.keys(cartItemsArr[index]).map((index2) => {
          Object.keys(cartItemsArr[index][index2]).map((index1) => {
            let product = cartItemsArr[index][index2][index1];
            product.map((pitem) => {
              product_cartid +=
                pitem.cart_item_product_id +
                "|" +
                pitem.cart_item_total_price +
                "|" +
                pitem.cart_item_qty +
                ";";
            });
          });
        });
      });
    }
    return product_cartid;
  }

  /*apply coupon  function- start*/
  applyCoupon(index, promocode) {
    if (this.state.userID === "") {
      return false;
    }
    if (promocode === "") {
      this.handleShowAlertFunct("Error", "Pleae enter your Promo Code.");
      return false;
    }

    var cartCount = this.props.cartTotalItmCount;
    console.log(promocode, cartCount, "cartCount");
    cartCount = cartCount !== "" ? parseInt(cartCount) : 0;
    if (cartCount === 0) {
      this.handleShowAlertFunct(
        "Error",
        "Cart Quantity is not enough to apply promotion."
      );
      return false;
    }

    if (cartCount > 0) {
      var cartDetailsArr = this.props.overAllcart.cart_details;
      var cartItemsSateArr = this.props.overAllcart.cart_items;

      var subTotal =
        Object.keys(cartDetailsArr).length > 0
          ? cartDetailsArr.cart_sub_total
          : 0;
      var totalItems =
        Object.keys(cartDetailsArr).length > 0
          ? cartDetailsArr.cart_total_items
          : 0;

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
      var avilablityId = cookie.load("defaultAvilablityId");
      var promotionApplied = "";
      var promotionType = "";
      var promotionAmount = "";
      var promoOutlet = "";
      var promotionSource = "";
      var promoIsDelivery = "";
      var reedemPointVal = "";
      var promotion_percentage = "";
      var promoTitle = "";
      var promotion_type = "";
      var promoCodeVal = promocode;
      var usedPoints = 0;
      var promooutletproducts = "";

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        promo_code: promocode,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: avilablityId,
      };

      showLoader("promo-codediv-" + index, "idtext");

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          var promotionID = "";
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promoOutlet = pointDet.promotion_outlet_id;
            promotionID = pointDet.promotion_id;
            promoTitle = pointDet.promotion_title;

            promotion_percentage = pointDet.promotion_percentage;
            promotion_type = pointDet.promotion_type;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            promooutletproducts = JSON.stringify(
              pointDet.outlet_promo_products
            );
            reedemPointVal = "";
            usedPoints = 0;
            $.magnificPopup.close();
            showToastrAlert("Promo applied successfully");
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Did not applied promo code";
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promoOutlet = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            showCustomAlert("error", msgTxt);
            this.handleShowAlertFunct("Error", msgTxt);
          }

          hideLoader("promo-codediv-" + index, "idtext");

          cookie.save("promotionID", promotionID, cookieConfig);
          cookie.save("reedemPointVal", reedemPointVal, cookieConfig);
          cookie.save("promoCodeVal", promoCodeVal, cookieConfig);
          cookie.save("promoTitle", promoTitle, cookieConfig);

          cookie.save("promotionApplied", promotionApplied, cookieConfig);
          cookie.save("promotionType", promotionType, cookieConfig);
          cookie.save("promotionAmount", promotionAmount, cookieConfig);
          cookie.save("promoOutlet", promoOutlet, cookieConfig);
          cookie.save("promooutletproducts", promooutletproducts, cookieConfig);

          cookie.save("promotionSource", promotionSource, cookieConfig);
          cookie.save("promoIsDelivery", promoIsDelivery, cookieConfig);
          cookie.save("promoPercentage", promotion_percentage, cookieConfig);
          cookie.save("promoType", promotion_type, cookieConfig);
          cookie.save("usedPoints", usedPoints, cookieConfig);

          if (promotionApplied === "Yes") {
            const { history } = this.props;
            //setTimeout(function () {
            history.push("/refpage/checkout");
            //}, 1000);
          }
        });
    }
  }
  /*apply coupon  function - end */

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  promotioncheckout() {
    $.magnificPopup.close();
    const { history } = this.props;
    cookie.save("fromCkeckOutVld", "Yes", cookieConfig);
    history.push("/checkout");
  }
  /*promotion list */

  __promotionListing() {
    var promotionsArr =
      this.state.promotions !== undefined ? this.state.promotions : Array();
    if (this.state.status === "ok" && Object.keys(promotionsArr).length > 0) {
      return this.state.promotions.map((promo, index) => {
        if (promo.promotion_method !== "4") {
          return (
            <li className="promo-code-col" key={index}>
              <div className="promo-code-col-item">
                <div className="promo-code-col-image ">
                  {promo.promotion_image !== "" &&
                  promo.promotion_image !== null ? (
                    <img src={this.props.promoSource + promo.promotion_image} />
                  ) : (
                    <img src={promotionImage} />
                  )}
                </div>
                <div className="promo-code-earned-info">
                  <div className="promo-code-earned-top">
                    <h6>
                      {" "}
                      {promo.promotion_title !== ""
                        ? promo.promotion_title
                        : promo.promo_code}
                    </h6>
                    {promo.promotion_short_desc !== "" &&
                      promo.promotion_short_desc !== null && (
                        <div className="promo-description">
                          {Parser(promo.promotion_short_desc)}
                        </div>
                      )}
                    <span className="promo-valid">
                      Valid till{" "}
                      {moment(promo.promotion_end_date).format("DD MMM YYYY")}
                    </span>
                    {promo.outlet_name !== "" && promo.outlet_name !== null && (
                      <span className="promo-valid">
                        *Applicable only for items from {promo.outlet_name}
                      </span>
                    )}
                  </div>
                  <button
                    className={
                      this.state.userID !== ""
                        ? "applyBtn button"
                        : "applyBtn button disabled"
                    }
                    onClick={this.applyCoupon.bind(this, 0, promo.promo_code)}
                  >
                    +
                  </button>
                </div>
              </div>
            </li>
          );
        }
      });
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleAddrChange(event) {
    if (event.target.name === "promo_code") {
      this.setState({ promo_code_val: event.target.value });
    }
  }

  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="promo-main-div">
        <div className="promo-popup-listing-page">
          <h3>Promo code</h3>
          <div className="focus-out">
            <input
              type="text"
              className="form-control input-focus"
              placeholder="Enter your promo code"
              id="promocodeValue"
              name="promo_code"
              onChange={this.handleAddrChange.bind(this)}
            />
            <a
              href={void 0}
              className="checkout-apply-promo"
              onClick={this.applyCoupon.bind(
                this,
                0,
                this.state.promo_code_val
              )}
            >
              Apply
            </a>
          </div>

          {/* container div - start */}
          {this.state.promodetailsdispaly === "" && (
            <ul className="promo-running">
              {this.state.promotions && this.__promotionListing()}
            </ul>
          )}
          {this.state.promodetailsdispaly !== "" &&
            this.state.promodetailsdispaly}

          {/* container div - end */}
        </div>

        {/* <div id="dvLoading" className="dvLoadrCls"></div> */}
        <div
          className="white-popup mfp-hide popup_sec"
          id="promotion-popup"
          style={{ maxWidth: 500 }}
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Success</div>
                <div className="alert_body">
                  <p>Promotion is applied successfully</p>
                  <div className="alt_btns">
                    <a
                      href={void 0}
                      className="btn btn-sm btn_yellow"
                      onClick={this.promotioncheckout.bind(this)}
                    >
                      Ok
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var overAllcart = Array();
  var cartTotalItmCount = 0;
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  var promoSource = "";
  var promogroupSource = "";
  var promoproSource;
  var loadingPromo = true;
  var promoreciptSource = "";
  if (Object.keys(state.promotionlist).length > 0) {
    loadingPromo = false;
    promoSource = state.promotionlist[0].common.promo_image_source;
  }
  if (Object.keys(state.promotionreceipt).length > 0) {
    if (state.promotionreceipt[0].status === "ok") {
      promogroupSource =
        state.promotionreceipt[0].common.promo_group_image_source;
      promoproSource =
        state.promotionreceipt[0].common.promo_product_image_source;
      promoreciptSource = state.promotionreceipt[0].common.promo_image_source;
    }
  }
  return {
    activitycount: state.activitycount,
    promotionlist: state.promotionlist,
    promoSource: promoSource,
    promogroupSource: promogroupSource,
    promoproSource: promoproSource,
    promoreciptSource: promoreciptSource,
    overAllcart: overAllcart,
    cartTotalItmCount: cartTotalItmCount,
    promotionreceipt: state.promotionreceipt,
    applypromotion: state.applypromotion,
    loadingPromo: loadingPromo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
    getPromotionReceipt: (params) => {
      dispatch({ type: GET_PROMOTIONRECEIPT, params });
    },
    getApplyPromotion: (postData) => {
      dispatch({ type: GET_APPLYPROMOTION, postData });
    },
  };
};

Promotions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Promotions)
);
