import React, { Component } from "react";
import cookie from "react-cookies";
import { deliveryId, pickupId, cookieConfig } from "../Helpers/Config";

class Refpage extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
    let slugtextadditional =
      typeof this.props.match.params.slugtextadditional !== "undefined"
        ? this.props.match.params.slugtextadditional
        : "";

    if (slugtext === "delivery") {
      cookie.save("triggerAvlPop", deliveryId, cookieConfig);
    } else if (slugtext === "pickup") {
      cookie.save("triggerAvlPop", pickupId, cookieConfig);
    } else if (slugtext === "ordernow") {
      cookie.save("orderPopuptrigger", "Yes", cookieConfig);
    } else if (slugtext === "promopopup") {
      cookie.save("promoPopupTrigger", "Yes", cookieConfig);
    } else if (slugtext === "restaurant" || slugtext === "checkout") {
    } else {
      cookie.remove("triggerAvlPop", cookieConfig);
      cookie.remove("orderPopuptrigger", cookieConfig);
      cookie.remove("promoPopupTrigger", cookieConfig);
    }
    if (slugtext === "restaurant" && slugtextadditional !== "") {
      this.props.history.push("/food/" + slugtextadditional);
    } else if (slugtext === "checkout") {
      this.props.history.push("/checkout");
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return <div></div>;
  }
}

export default Refpage;
