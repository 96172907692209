/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import {
  showAlert,
  showLoader,
  hideLoader,
  showCustomAlert,
  stripslashes,
  showPriceValue,
  showToastrAlert,
} from "../Helpers/SettingHelper";
import {
  appId,
  apiUrl,
  restaurantLink,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import cookie from "react-cookies";
import axios from "axios";
var qs = require("qs");

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PropTypes from "prop-types";
import OwlCarousel from "react-owl-carousel2";
import Slider from "react-slick";
import "../../common/css/owl.carousel.css";
import { connect } from "react-redux";
var Parser = require("html-react-parser");
var qs = require("qs");
var base64 = require("base-64");
import moment from "moment";

import {
  GET_PROMOTIONLIST,
  GET_PROMOTIONRECEIPT,
  GET_APPLYPROMOTION,
} from "../../actions";
import promotionImage from "../../common/images/promo-placeholder.png";
import noimage from "../../common/images/no-img-product.png";
var settingsBanner = {
  items: 2,
  dots: false,
  autoplay: false,
  nav: true,
  loop: true,
  margin: 25,
  responsive: {
    0: {
      items: 2,
    },
    500: {
      items: 3,
    },
    900: {
      items: 4,
    },
    1200: {
      items: 5,
    },
  },
};
class Promotions extends Component {
  constructor(props) {
    super(props);
    var promoID =
      this.props.match.params.promoID !== "" &&
      typeof this.props.match.params.promoID !== undefined &&
      typeof this.props.match.params.promoID !== "undefined"
        ? this.props.match.params.promoID
        : "";
    this.state = {
      promoused: [],
      promoID: promoID,
      promotions: [],
      status: "",
      promo: [],
      cartItems: [],
      promodetails: [],
      promodetailsdispaly: "",
      cartDetails: [],
      cart_sub_total: 0,
      userID:
        cookie.load("UserId") !== "" &&
        typeof cookie.load("UserId") !== undefined &&
        typeof cookie.load("UserId") !== "undefined"
          ? cookie.load("UserId")
          : "",
      loadingPromo: true,
    };
    this.viewPromoDetails(promoID, "D");
  }

  componentDidMount() {
    var customerParam = "&customer_id=" + cookie.load("UserId");
    this.props.getPromotionList(customerParam);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loadingPromo !== this.state.loadingPromo) {
      this.setState({ loadingPromo: nextProps.loadingPromo }, function () {
        $("#dvLoading").fadeOut(2000);
      });
    }
    if (nextProps.match.params.promoID !== this.state.promoID) {
      this.setState({ promoID: "", promodetailsdispaly: "" });
    }
    if (nextProps.promotionlist !== this.props.promotionlist) {
      if (nextProps.promotionlist[0].status === "ok") {
        this.setState({
          status: nextProps.promotionlist[0].status,
          promotions: nextProps.promotionlist[0].result_set.my_promo,
        });
      }
    }
    if (nextProps.promotionreceipt !== this.props.promotionreceipt) {
      if (nextProps.promotionreceipt[0].status === "ok") {
        this.setState(
          {
            promodetails: nextProps.promotionreceipt[0].result_set,
          },
          function () {
            this.displayPromoDetails();
          }
        );
      }
    }
  }

  /*  get promo category details  */
  getProductIdsDet(cartItemsArr) {
    var product_cartid = "";
    if (Object.keys(cartItemsArr).length > 0) {
      Object.keys(cartItemsArr).map((index) => {
        Object.keys(cartItemsArr[index]).map((index2) => {
          Object.keys(cartItemsArr[index][index2]).map((index1) => {
            let product = cartItemsArr[index][index2][index1];
            product.map((pitem) => {
              product_cartid +=
                pitem.cart_item_product_id +
                "|" +
                pitem.cart_item_total_price +
                "|" +
                pitem.cart_item_qty +
                ";";
            });
          });
        });
      });
    }
    return product_cartid;
  }

  /*apply coupon  function- start*/
  applyCoupon(index, promocode) {
    if (this.state.userID === "") {
      return false;
    }
    if (promocode === "") {
      this.handleShowAlertFunct("Error", "Pleae enter your Promo Code.");
      return false;
    }

    var cartCount = this.props.cartTotalItmCount;
    cartCount = cartCount !== "" ? parseInt(cartCount) : 0;
    if (cartCount === 0) {
      this.handleShowAlertFunct(
        "Error",
        "Cart Quantity is not enough to apply promotion."
      );
      return false;
    }

    if (cartCount > 0) {
      var cartDetailsArr = this.props.overAllcart.cart_details;
      var cartItemsSateArr = this.props.overAllcart.cart_items;

      var subTotal =
        Object.keys(cartDetailsArr).length > 0
          ? cartDetailsArr.cart_sub_total
          : 0;
      var totalItems =
        Object.keys(cartDetailsArr).length > 0
          ? cartDetailsArr.cart_total_items
          : 0;

      var categoryIdsDet = this.getProductIdsDet(cartItemsSateArr);
      var avilablityId = cookie.load("defaultAvilablityId");
      var promotionApplied = "";
      var promotionType = "";
      var promotionAmount = "";
      var promoOutlet = "";
      var promotionSource = "";
      var promoIsDelivery = "";
      var reedemPointVal = "";
      var promotion_percentage = "";
      var promotion_type = "";
      var promoTitle = "";
      var promoCodeVal = promocode;
      var usedPoints = 0;
      var promooutletproducts = "";

      var postObject = {
        app_id: appId,
        reference_id: cookie.load("UserId"),
        promo_code: promocode,
        cart_amount: subTotal,
        cart_quantity: totalItems,
        category_id: categoryIdsDet,
        availability_id: avilablityId,
      };

      showLoader("promo-codediv-" + index, "idtext");

      axios
        .post(
          apiUrl + "promotion_api_v2/apply_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((res) => {
          var promotionID = "";
          if (res.data.status === "success") {
            var pointDet = res.data.result_set;
            var IsDelivery =
              pointDet.promotion_delivery_charge_applied == "Yes"
                ? "Yes"
                : "No";
            promotionApplied = "Yes";
            promotionType = "promoCode";
            promotionAmount = pointDet.promotion_amount;
            promoOutlet = pointDet.promotion_outlet_id;
            promotionID = pointDet.promotion_id;
            promoTitle = pointDet.promotion_title;

            promotion_percentage = pointDet.promotion_percentage;
            promotion_type = pointDet.promotion_type;
            promotionSource = promoCodeVal;
            promoIsDelivery = IsDelivery;
            promooutletproducts = JSON.stringify(
              pointDet.outlet_promo_products
            );
            reedemPointVal = "";
            usedPoints = 0;
            $.magnificPopup.close();
            showToastrAlert("Promo applied successfully");
          } else {
            var msgTxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Did not applied promo code";
            if (promotionType === "promoCode") {
              promotionApplied = "";
              promotionType = "";
              promotionAmount = "";
              promoOutlet = "";
              promotionSource = "";
              promoIsDelivery = "";
            }
            showCustomAlert("error", msgTxt);
            this.handleShowAlertFunct("Error", msgTxt);
          }

          hideLoader("promo-codediv-" + index, "idtext");

          cookie.save("promotionID", promotionID, cookieConfig);
          cookie.save("reedemPointVal", reedemPointVal, cookieConfig);
          cookie.save("promoCodeVal", promoCodeVal, cookieConfig);
          cookie.save("promotionApplied", promotionApplied, cookieConfig);
          cookie.save("promotionType", promotionType, cookieConfig);
          cookie.save("promotionAmount", promotionAmount, cookieConfig);
          cookie.save("promoOutlet", promoOutlet, cookieConfig);
          cookie.save("promoTitle", promoTitle, cookieConfig);
          cookie.save("promooutletproducts", promooutletproducts, cookieConfig);

          cookie.save("promotionSource", promotionSource, cookieConfig);
          cookie.save("promoIsDelivery", promoIsDelivery, cookieConfig);
          cookie.save("promoPercentage", promotion_percentage, cookieConfig);
          cookie.save("promoType", promotion_type, cookieConfig);
          cookie.save("usedPoints", usedPoints, cookieConfig);

          if (promotionApplied === "Yes") {
            const { history } = this.props;
            setTimeout(function () {
              history.push("/checkout");
            }, 1000);
          }
        });
    }
  }
  /*apply coupon  function - end */

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  promotioncheckout() {
    $.magnificPopup.close();
    const { history } = this.props;
    cookie.save("fromCkeckOutVld", "Yes", cookieConfig);
    history.push("/checkout");
  }
  /*promotion list */

  __promotionListing() {
    var promotionsArr =
      this.state.promotions !== undefined ? this.state.promotions : Array();
    if (this.state.status === "ok" && Object.keys(promotionsArr).length > 0) {
      return this.state.promotions.map((promo, index) => (
        <li
          className="promo-earned-col"
          key={index}
          onClick={this.viewPromoDetails.bind(this, promo.promotion_id, "O")}
        >
          <div className="promo-earned-col-item">
            <div className="promo-earned-col-image ">
              {promo.promotion_thumbnail_image !== "" &&
              promo.promotion_thumbnail_image !== null ? (
                <img
                  src={this.props.promoSource + promo.promotion_thumbnail_image}
                />
              ) : (
                <img src={promotionImage} />
              )}
            </div>
            <div className="promo-earned-info">
              <div className="promo-earned-top">
                <h6 className="promo-earned-code">
                  {" "}
                  {promo.promo_code !== ""
                    ? promo.promo_code
                    : promo.promotion_title}
                </h6>
                <span className="promo-valid">
                  From{" "}
                  {moment(promo.promotion_start_date).format("DD MMM YYYY")} to{" "}
                  {moment(promo.promotion_end_date).format("DD MMM YYYY")}
                </span>
                {promo.outlet_name !== "" && promo.outlet_name !== null && (
                  <span className="promo-valid">
                    *Applicable only for items from {promo.outlet_name}
                  </span>
                )}
              </div>
            </div>
          </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }
  viewPromoDetails(promotion_id, type) {
    if (type === "O") {
      this.props.history.push("/promotions/" + base64.encode(promotion_id));
      promotion_id = base64.encode(promotion_id);
    }
    var customerParam = "&promotion_id=" + promotion_id + "&withproducts=Yes";
    this.props.getPromotionReceipt(customerParam);
  }
  displayPromoDetails() {
    var promodetailsdispaly = "";
    if (Object.keys(this.state.promodetails).length > 0) {
      var promo = this.state.promodetails;
      promodetailsdispaly = (
        <div className="promo-detail-main-div">
          <div className="promo-detail-top-div">
            <div className="container">
              {/* <div className="promo-detail-header">
                <Link to="/promotions">Back</Link>
              </div> */}
              <div className="promo-image">
                {promo.promotion_image !== "" &&
                promo.promotion_image !== null ? (
                  <img
                    src={this.props.promoreciptSource + promo.promotion_image}
                    alt={promo.promotion_name}
                    title={promo.promotion_name}
                  />
                ) : (
                  <img
                    src={promotionImage}
                    alt={promo.promotion_name}
                    title={promo.promotion_name}
                  />
                )}
              </div>
              <div className="promo-title">{promo.promotion_name}</div>
              <div className="promo-period">
                From {moment(promo.promotion_start_date).format("DD MMM YYYY")}{" "}
                to {moment(promo.promotion_end_date).format("DD MMM YYYY")}
              </div>
              {promo.promo_desc !== "" && promo.promo_desc !== null && (
                <div className="promo-desc">{Parser(promo.promo_desc)}</div>
              )}
            </div>
          </div>
          {/* promo.promo_group_details.length > 0 && (
            <div className="promo-group-list">
              {promo.promo_group_details.map((item, index) => {
                var bgurl =
                  item.productpromogroup_bg_image !== "" &&
                  item.productpromogroup_bg_image !== null
                    ? this.props.promogroupSource +
                      item.productpromogroup_bg_image
                    : "";
                return (
                  <div
                    key={index}
                    className={
                      item.productpromogroup_type === "S"
                        ? "promo-group-slider"
                        : "promo-group-normal"
                    }
                    style={{
                      backgroundImage:
                        bgurl !== "" ? 'url("' + bgurl + '")' : "",
                    }}
                  >
                    <div className="container">
                      <h3>{item.productpromogroup_title}</h3>
                      <div className="product-list">
                        {item.productpromogroup_type === "S" &&
                          this.loadSliderProducts(item.products)}

                        {item.productpromogroup_type !== "S" && (
                          <ul>{this.loadNormalProducts(item.products)}</ul>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) */}
          {promo.promotion_method !== "4" && (
            <div className="promo-apply-btn">
              <div className="container">
                <button
                  className={
                    this.state.userID !== ""
                      ? "applyBtn button"
                      : "applyBtn button disabled"
                  }
                  onClick={this.applyCoupon.bind(this, 0, promo.promotion_name)}
                >
                  Apply promo to cart
                </button>
              </div>
            </div>
          )}
        </div>
      );
    }
    this.setState({ promodetailsdispaly: promodetailsdispaly });
  }
  loadSliderProducts(product) {
    if (product.length > 0) {
      return (
        <OwlCarousel options={settingsBanner}>
          {product.map((item, index) => {
            var prodivId = "proIndex-" + item.product_primary_id;
            var comboProId = "comboPro-" + item.product_slug;
            var productName =
              item.product_alias !== ""
                ? stripslashes(item.product_alias)
                : stripslashes(item.product_name);
            return (
              <div className="products-single-li" id={prodivId} key={index}>
                <Link
                  to={
                    "/" +
                    restaurantLink +
                    "/" +
                    item.outlet_slug +
                    "/" +
                    item.pro_cate_slug +
                    "/" +
                    item.pro_subcate_slug +
                    "/" +
                    item.product_slug
                  }
                >
                  <div className="products-image-div">
                    {item.product_thumbnail !== "" ? (
                      <img
                        src={
                          this.props.promoproSource +
                          "/" +
                          item.product_thumbnail
                        }
                        title={
                          item.product_thumbnail_title !== "" &&
                          item.product_thumbnail_title !== null
                            ? item.product_thumbnail_title
                            : productName
                        }
                        alt={
                          item.product_thumbnail_alt !== "" &&
                          item.product_thumbnail_alt !== null
                            ? item.product_thumbnail_alt
                            : productName
                        }
                      />
                    ) : (
                      <img
                        src={noimage}
                        title={productName}
                        alt={productName}
                      />
                    )}
                  </div>
                </Link>

                <div className="product-info-div">
                  <div className="product-outlet-name">
                    <Link to={"/" + restaurantLink + "/" + item.outlet_slug}>
                      {item.outlet_name}
                    </Link>
                  </div>
                  <div className="product-title-maindiv">
                    <div className="product-title">
                      <Link
                        to={
                          "/" +
                          restaurantLink +
                          "/" +
                          item.outlet_slug +
                          "/" +
                          item.pro_cate_slug +
                          "/" +
                          item.pro_subcate_slug +
                          "/" +
                          item.product_slug
                        }
                      >
                        <h3>{productName}</h3>
                      </Link>
                    </div>
                  </div>

                  <div className="product-price">
                    <h3>{showPriceValue(item.product_price)}</h3>
                    {parseFloat(item.product_cost) > 0 && (
                      <h4 className="product-originale-price">
                        {showPriceValue(item.product_cost)}
                      </h4>
                    )}
                  </div>

                  <div className="products-ordernow-action">
                    <div className="addcart_row addcart_done_maindiv">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            item.product_primary_id,
                            "decr"
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            item.product_primary_id,
                            "incr"
                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="btn btn_black order_done"
                        onClick={this.addToCartSimple.bind(this, item, "done")}
                      >
                        +
                      </button>
                    </div>

                    {item.product_stock > 0 || item.product_stock === null ? (
                      item.product_type === "1" ? (
                        <a
                          className="button order_nowdiv smiple_product_lk disbl_href_action"
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            item,
                            "done"
                          )}
                        >
                          +
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.viewProDetail.bind(this, item)}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv compo_product_lk"
                        >
                          +
                        </a>
                      )
                    ) : (
                      <a className="button disabled disbl_href_action" href="/">
                        Sold Out
                      </a>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </OwlCarousel>
      );
    }
  }
  loadNormalProducts(product) {
    if (product.length > 0) {
      return product.map((item, index) => {
        var prodivId = "proIndex-" + item.product_primary_id;
        var comboProId = "comboPro-" + item.product_slug;
        var productName =
          item.product_alias !== ""
            ? stripslashes(item.product_alias)
            : stripslashes(item.product_name);
        return (
          <li className="products-single-li" id={prodivId} key={index}>
            <div className="products-image-div">
              {item.product_thumbnail !== "" ? (
                <img
                  src={this.props.promoproSource + "/" + item.product_thumbnail}
                  title={
                    item.product_thumbnail_title !== "" &&
                    item.product_thumbnail_title !== null
                      ? item.product_thumbnail_title
                      : productName
                  }
                  alt={
                    item.product_thumbnail_alt !== "" &&
                    item.product_thumbnail_alt !== null
                      ? item.product_thumbnail_alt
                      : productName
                  }
                />
              ) : (
                <img src={noimage} title={productName} alt={productName} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-outlet-name">
                <Link to={"/" + restaurantLink + "/" + item.outlet_slug}>
                  {item.outlet_name}
                </Link>
              </div>
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>{productName}</h3>
                </div>
              </div>

              <div className="product-price">
                <h3>{showPriceValue(item.product_price)}</h3>
                {parseFloat(item.product_cost) > 0 && (
                  <h4 className="product-originale-price">
                    {showPriceValue(item.product_cost)}
                  </h4>
                )}
              </div>

              <div className="products-ordernow-action">
                <div className="addcart_row addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        item.product_primary_id,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        item.product_primary_id,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                  <button
                    className="btn btn_black order_done"
                    onClick={this.addToCartSimple.bind(this, item, "done")}
                  >
                    +
                  </button>
                </div>

                {item.product_stock > 0 || item.product_stock === null ? (
                  item.product_type === "1" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      href="/"
                      onClick={this.addToCartSimple.bind(this, item, "done")}
                    >
                      +
                    </a>
                  ) : (
                    <a
                      href="/"
                      onClick={this.viewProDetail.bind(this, item)}
                      title="Product Details"
                      id={comboProId}
                      className="button order_nowdiv compo_product_lk"
                    >
                      +
                    </a>
                  )
                ) : (
                  <a className="button disabled disbl_href_action" href="/">
                    Sold Out
                  </a>
                )}
              </div>
            </div>
          </li>
        );
      });
    }
  }
  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");

    if (
      avilablityId !== "" &&
      typeof avilablityId !== undefined &&
      typeof avilablityId !== "undefined"
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          outletLocationId: productDetail.outlet_location_id,
          outlet_id: productDetail.outlet_id,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          shopType: shopType,
          outletzone_id: this.state.orderZoneId,
          login_type:
            typeof cookie.load("userAccountType") === "undefined"
              ? ""
              : cookie.load("userAccountType"),
          reference_new_id: getReferenceID(),
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart.",
              "cart-success-msg"
            );
            removePromoCkValue();
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.form_error !== ""
                ? res.data.form_error
                : res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    if (
      avilablityId !== "" &&
      typeof avilablityId !== undefined &&
      typeof avilablityId !== "undefined"
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        this.props.history.push(
          "/" +
            restaurantLink +
            "/" +
            productDetail.outlet_slug +
            "/" +
            productDetail.pro_cate_slug +
            "/" +
            productDetail.pro_subcate_slug +
            "/" +
            productSlug
        );
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }
  sateValChange = (field, value) => {};
  render() {
    return (
      <div className="promo-main-div">
        <Header sateValChange={this.sateValChange} />
        <div className="innersection_wrap">
          <div className="promolisting-page">
            {/* container div - start */}
            {this.state.promodetailsdispaly === "" && (
              <div className="container">
                <ul className="myacc_order_details">
                  {this.state.promotions && this.__promotionListing()}
                </ul>
              </div>
            )}
            {this.state.promodetailsdispaly !== "" &&
              this.state.promodetailsdispaly}

            {/* container div - end */}
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
        <div
          className="white-popup mfp-hide popup_sec"
          id="promotion-popup"
          style={{ maxWidth: 500 }}
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Success</div>
                <div className="alert_body">
                  <p>Promotion is applied successfully</p>
                  <div className="alt_btns">
                    <a
                      href={void 0}
                      className="btn btn-sm btn_yellow"
                      onClick={this.promotioncheckout.bind(this)}
                    >
                      Ok
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var overAllcart = Array();
  var cartTotalItmCount = 0;
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  var promoSource = "";
  var promogroupSource = "";
  var promoproSource;
  var loadingPromo = true;
  var promoreciptSource = "";
  if (Object.keys(state.promotionlist).length > 0) {
    loadingPromo = false;
    promoSource = state.promotionlist[0].common.promo_image_source;
  }
  if (Object.keys(state.promotionreceipt).length > 0) {
    if (state.promotionreceipt[0].status === "ok") {
      promogroupSource =
        state.promotionreceipt[0].common.promo_group_image_source;
      promoproSource =
        state.promotionreceipt[0].common.promo_product_image_source;
      promoreciptSource = state.promotionreceipt[0].common.promo_image_source;
    }
  }
  return {
    activitycount: state.activitycount,
    promotionlist: state.promotionlist,
    promoSource: promoSource,
    promogroupSource: promogroupSource,
    promoproSource: promoproSource,
    promoreciptSource: promoreciptSource,
    overAllcart: overAllcart,
    cartTotalItmCount: cartTotalItmCount,
    promotionreceipt: state.promotionreceipt,
    applypromotion: state.applypromotion,
    loadingPromo: loadingPromo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
    getPromotionReceipt: (params) => {
      dispatch({ type: GET_PROMOTIONRECEIPT, params });
    },
    getApplyPromotion: (postData) => {
      dispatch({ type: GET_APPLYPROMOTION, postData });
    },
  };
};

Promotions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Promotions)
);
