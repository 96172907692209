/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { validated } from "react-custom-validation";
import validator from "validator";
import axios from "axios";

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";

import { appId, apiUrl, headerconfig } from "../Helpers/Config";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

const isEmail = (email) =>
  validator.isEmail(email) ? null : "This is not a valid email.";

const phonenumberPattern = /^[0-9]{6,8}$/;
const isMobile = (mobile) =>
  mobile.match(phonenumberPattern) ? null : "please enter valid Phone number.";

function validationConfigCheckout(props) {
  const {
    check_firstname,
    check_lastname,
    check_email,
    birthday,
    check_phone,
  } = props.fields;
  return {
    fields: ["firstname", "email", "birthday", "mobile"],

    validations: {
      firstname: [[isEmpty, check_firstname]],
      email: [[isEmail, check_email]],
      birthday: [[isEmpty, birthday]],
      mobile: [
        [isEmpty, check_phone],
        [isMobile, check_phone],
      ],
    },
  };
}

class Guestcheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "Loading",
      check_phone: "",
    };
  }

  componentDidMount() {
    var check_phone = this.props.fields.check_phone;
    this.setState({ check_phone: check_phone });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.check_phone !== nextProps.fields.check_phone) {
      this.setState({ check_phone: nextProps.fields.check_phone });
    }
  }

  isGuestPhonenumerExist() {
    var mobile = $("#check_phone").val();
    this.setState({ check_phone: mobile });
    this.props.onChange("check_phone", mobile);
    if (mobile.length > 7) {
      axios
        .get(
          apiUrl +
            "guestaccount/guest_customer_phone?app_id=" +
            appId +
            "&status=A&phone_no=" +
            mobile,
          headerconfig
        )
        .then((response) => {
          if (response.data.status === "ok") {
            this.props.onChange("check_phone", mobile);
            /*this.props.onChange(
              "check_firstname",
              response.data.result_set.customer_first_name
            );
            this.props.onChange(
              "check_email",
              response.data.result_set.customer_email
            );

            var birthdate = response.data.result_set.customer_birthdate;
            if (
              birthdate !== "" &&
              birthdate !== "0000-00-00" &&
              birthdate !== "00/00/0000"
            ) {
              birthdate = new Date(birthdate);
              this.props.onChange("birthday", birthdate);
            }*/
          } else {
            this.props.onChange("check_phone", mobile);
            /*this.props.onChange('check_firstname', '');
                    this.props.onChange('check_lastname', '');
                    this.props.onChange('check_email', ''); */
          }
        });
    } else {
      this.setState({ check_phone: mobile });
    }
  }

  guestChgDate(datevalue) {
    var dateval = new Date(datevalue);
    dateval = format(dateval, "dd/MM/yyyy");
    this.props.onChange("birthday", datevalue);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;

    let errMsgFirstName,
      errMsgLastName,
      errMsgEmail,
      errMsgBirthday,
      errMsgMobile;

    if ($validation.firstname.error.reason !== undefined) {
      errMsgFirstName = $validation.firstname.show && (
        <span className="error">{$validation.firstname.error.reason}</span>
      );
    }
    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.mobile.error.reason !== undefined) {
      errMsgMobile = $validation.mobile.show && (
        <span className="error">{$validation.mobile.error.reason}</span>
      );
    }
    if ($validation.birthday.error.reason !== undefined) {
      errMsgBirthday = $validation.birthday.show && (
        <span className="error">{$validation.birthday.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="guest-chk-error"></span>

        <div className="form-group">
          <div className="focus-out">
            <input
              type="tel"
              maxLength="8"
              id="check_phone"
              className="form-control input-focus"
              placeholder="Phone number"
              pattern="\d*"
              value={this.state.check_phone}
              onChange={() => this.isGuestPhonenumerExist()}
            />
            <div id="spn-mobile-error">{errMsgMobile}</div>
          </div>
        </div>
        <div className="form-group">
          <div
            className={
              fields.check_firstname != "" ? "focus-out focused" : "focus-out"
            }
          >
            <input
              type="text"
              className="form-control input-focus"
              placeholder="Name"
              value={fields.check_firstname}
              {...$field("check_firstname", (e) =>
                onChange("check_firstname", e.target.value)
              )}
            />
            {errMsgFirstName}
          </div>
        </div>

        <div className="form-group">
          <div
            className={
              fields.check_email != "" ? "focus-out focused" : "focus-out"
            }
          >
            {" "}
            <input
              type="text"
              className="form-control input-focus"
              placeholder="Email"
              value={fields.check_email}
              {...$field("check_email", (e) =>
                onChange("check_email", e.target.value)
              )}
            />
            <div id="spn-email-error">{errMsgEmail}</div>
          </div>
        </div>

        <div className="form-group" style={{ display: "none" }}>
          <div
            className={
              fields.birthday != "" ? "focus-out focused" : "focus-out"
            }
          >
            <label>Birthday</label>
            <div className="react_datepicker">
              <DatePicker
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                className="form-control input-focus"
                dateFormat="dd/MM/yyyy"
                selected={fields.birthday}
                onChange={this.guestChgDate.bind(this)}
              />

              {errMsgBirthday}
            </div>
            <span className="birthday_info_sp">
              [ * <b>Used to send vouchers and promotions.</b> ]
            </span>
          </div>
        </div>

        <span className="pdpa_trmcont_link">
          By continuing, you agree to the platform{" "}
          <Link
            className="sign_reg_uline"
            target="_blank"
            to="/terms-conditions"
          >
            Terms and Conditions
          </Link>
          .
        </span>

        <div className="form-group">
          <div className="login_pop_sub login_in_guest">
            <button
              type="button"
              className="btn btn_black btn_minwid guestcheckout_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              Submit
            </button>
          </div>
          <div className="form-group">
            <div className="login-links">
              <a href="#login-main-popup" className="open-popup-link">
                Go Back
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Guestcheckout = validated(validationConfigCheckout)(Guestcheckout);

export default Guestcheckout;
