/* eslint-disable */

// if (location.protocol !== "https:") {
//   var locationULR = location.href.replace("www.", "");
//   location.href = locationULR.replace("http://", "https://");
// } /*  else if (location.protocol === "https:") {
//   var locationULR = location.href;
//   if (locationULR.indexOf("www.") < 0) {
//     location.href = "https://www." + locationULR.replace("https://", "");
//   }
// } */
import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";
import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";
import Home from "./components/Home/Home";
import Products from "./components/Products/Index";
import Search from "./components/Products/Search";
import Promotions from "./components/Products/Promotions";
import Promocode from "./components/Products/Promocode";
import Checkout from "./components/Checkout/Checkout";
import Placeorder from "./components/Checkout/Placeorder";
import Thankyou from "./components/Checkout/Thankyou";
import Findingdriver from "./components/Checkout/Findingdriver";
import Pages from "./components/Pages/Pages";
import ContactUs from "./components/Pages/ContactUs";
import Faq from "./components/Pages/Faq";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Ocidlogin from "./components/Layout/Ocidlogin";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Scanqrcode from "./components/Dineqrcode/Scanqrcode";
import Booktable from "./components/Dineqrcode/Booktable";
import Page404 from "./Page404";

const store = getStore();
render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/restaurants" component={Home} />
        <Route
          path={"/food/:slugRestaurant/:slugType/:slugValue/:proValue"}
          component={Products}
        />
        <Route
          path={"/food/:slugRestaurant/:slugType/:slugValue"}
          component={Products}
        />
        <Route path={"/food/:slugRestaurant/"} component={Products} />
        <Route path={"/promotions/:promoID/"} component={Promotions} />
        <Route path={"/promotions"} component={Promotions} />
        <Route path={"/promo-codes/:promoID/"} component={Promocode} />
        <Route path={"/promo-codes"} component={Promocode} />

        <Route path={"/search/:searchKeyword"} component={Search} />
        <Route path={"/search"} component={Search} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/placeorder" component={Placeorder} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/findingdriver/:orderId" component={Findingdriver} />
        <Route path="/faq" component={Faq} />
        <Route path="/terms-conditions" component={Pages} />
        <Route path="/delivery-locations" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/about-us" component={Pages} />
        <Route path="/our-story" component={Pages} />
        <Route path="/visit-us" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/myorders/:orderID" component={Orders} />
        <Route path="/myorders" component={Orders} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/logout" component={Logout} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route
          path={"/refpage/:slugtext/:slugtextadditional"}
          component={Refpage}
        />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route path={"/refpage"} component={Refpage} />
        <Route path={"/ocidlogin/:slugtext/:slugid"} component={Ocidlogin} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/booktable/:tablenumber" component={Booktable} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
