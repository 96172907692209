/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
const isEmpty = (value) => (value === "" ? "This field is required." : null);

function validationConfig(props) {
  const { memberid, password } = props.fields;
  /* , password */
  return {
    fields: ["memberid"],
    /* , "password" ,, "first_name" "last_name" */
    validations: {
      memberid: [[isEmpty, memberid]],
      /* first_name: [[isEmpty, first_name]], */
      /* last_name: [[isEmpty, last_name]], */
      /* password: [[isEmpty, password]], */
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgFirstName, errMsgLastName, errMsgPassword;
    if ($validation.memberid.error.reason !== undefined) {
      errMsgEmail = $validation.memberid.show && (
        <span className="error">{$validation.memberid.error.reason}</span>
      );
    }

    /* if ($validation.first_name.error.reason !== undefined) {
      errMsgFirstName = $validation.first_name.show && (
        <span className="error">{$validation.first_name.error.reason}</span>
      );
    } */
    /*  if ($validation.password.error.reason !== undefined) {
      errMsgPassword = $validation.password.show && (
        <span className="error">{$validation.password.error.reason}</span>
      );
    } */
    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          <div className="focus-out">
            <input
              type="text"
              placeholder="Enter Your Membership ID"
              className="form-control input-focus"
              value={fields.memberid}
              {...$field("memberid", (e) =>
                onChange("memberid", e.target.value)
              )}
            />
            {errMsgEmail}
          </div>
        </div>
        {/* <div className="form-group">
          <div className="focus-out">
            <input
              type="text"
              placeholder="First Name"
              className="form-control input-focus"
              value={fields.first_name}
              {...$field("first_name", (e) =>
                onChange("first_name", e.target.value)
              )}
              readOnly={true}
            />
            {errMsgFirstName}
          </div>
        </div> */}
        {/* <div className="form-group">
          <div className="focus-out">
            <input
              type="text"
              placeholder="Last Name"
              className="form-control input-focus"
              value={fields.last_name}
              {...$field("last_name", (e) =>
                onChange("last_name", e.target.value)
              )}
              readOnly={true}
            />
          </div>
        </div> */}
        {/* <div className="form-group">
          <div className="focus-out">
            <input
              type="password"
              placeholder="Enter Your Password"
              className="form-control input-focus"
              value={fields.password}
              {...$field("password", (e) =>
                onChange("password", e.target.value)
              )}
            />
            {errMsgPassword}
          </div>
        </div> */}
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="btn btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              Continue{" "}
            </button>
          </div>
          {/* <div className="form-group">
            <div className="login-links">
              <a href="#login-main-popup" className="open-popup-link">
                Go Back
              </a>
            </div>
          </div> */}
        </div>

        <div className="form-group no-margin-btm">
          <div className="controls two-links">
            {/* <a
              href="#signup-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link"
            >
              {" "}
              Create an account
            </a> */}
            <a href="#forgot-password-popup" className="open-popup-link">
              Forgot Password
            </a>{" "}
          </div>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
