/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { GET_BANNER_LIST } from "../../../actions";
import { stripslashes } from "../../Helpers/SettingHelper";
var settingsBanner = {
  items: 1,
  autoplay: true,
  nav: false,
  dots: true,
  loop: true,
};
var Parser = require("html-react-parser");
class HomeBanner extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.getBannerList();
  }
  render() {
    let bannerArr = this.props.banner;
    let bannerlist = [];
    let bannerimagesource = "";

    if (Object.keys(bannerArr).length > 0) {
      if (bannerArr[0].status === "ok") {
        bannerlist = bannerArr[0].result_set;
        bannerimagesource = bannerArr[0].common.image_source;
      }
    }

    return (
      <div className="home-banner">
        {Object.keys(bannerlist).length > 0 ? (
          <>
            <OwlCarousel options={settingsBanner}>
              {bannerlist.map((banner, index) => {
                return (
                  <div key={index}>
                    <img
                      src={bannerimagesource + banner.banner_image}
                      alt="Banner"
                    />
                    {banner.banner_description != ""
                      ? Parser(stripslashes(banner.banner_description))
                      : ""}
                  </div>
                );
              })}
            </OwlCarousel>
          </>
        ) : (
          <div className="loader-main-cls">
            <div className="loader-sub-div"></div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    banner: state.banner,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBannerList: () => {
      dispatch({ type: GET_BANNER_LIST });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(HomeBanner);
