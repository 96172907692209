/* eslint-disable */
import React, { Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
var Parser = require("html-react-parser");
import { connect } from "react-redux";
import axios from "axios";
import { appId, apiUrl, headerconfig } from "../Helpers/Config";
import { stripslashes } from "../Helpers/SettingHelper";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Accordion, AccordionItem } from "react-light-accordion";
import Collapsible from "react-collapsible";
import "react-light-accordion/demo/css/index.css";
import innerbanner from "../../common/images/faq-banners.png";
class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = { FaqList: [], FaqDisplay: "" };
  }

  componentDidMount() {
    var urlShringTxt = apiUrl + "cms/faq?status=A&app_id=" + appId;

    axios.get(urlShringTxt, headerconfig).then((res) => {
      var FaqList = "";
      $("#dvLoading").hide();
      if (res.data.status === "ok") {
        var FaqList = res.data.result_set;
      }
      this.setState(
        {
          FaqList: FaqList,
        },
        function () {
          this.loadFaq();
        }
      );
    });
  }
  loadFaq() {
    var Faq_List = "";
    if (this.state.FaqList.length > 0) {
      Faq_List = this.state.FaqList.map((item, index) => {
        if (Object.keys(item.faqs).length > 0) {
          var faqListNew = item.faqs;
          return (
            <>
              <h4>{item.faqcategory_title}</h4>
              <div className="faq-item-list">
                {Object.keys(item.faqs).map((item1, index1) => {
                  var faqListCurrent = faqListNew[item1];
                  if (
                    faqListCurrent.faq_title !== "" &&
                    faqListCurrent.faq_title !== null
                  ) {
                    return (
                      <Collapsible
                        trigger={
                          faqListCurrent.faq_title !== null
                            ? stripslashes(faqListCurrent.faq_title)
                            : ""
                        }
                        key={index + index1}
                      >
                        {faqListCurrent.faq_description !== "" &&
                        faqListCurrent.faq_description !== null
                          ? Parser(faqListCurrent.faq_description)
                          : ""}
                      </Collapsible>
                    );
                  }
                })}
              </div>
            </>
          );
        }
      });
    }
    this.setState({ FaqDisplay: Faq_List });
  }
  sateValChange() {}
  render() {
    return (
      <div className="pagesList-main-div page-faq">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />
        {/* Header End */}

        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <img
              src={innerbanner}
              alt="Singapore Swimming Club FAQ"
              title="Singapore Swimming Club FAQ"
            />
            <p>Singapore Swimming Club FAQ</p>
          </div>
        </div>

        <div className="cms-page">
          <div className="container-one cms-content">
            <div className="container cms-content">{this.state.FaqDisplay}</div>
          </div>
        </div>

        <Footer />
        <div id="dvLoading" className="dvLoadrCls"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Faq.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Faq));
