import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ZONE_DETAIL, SET_ZONE_DETAIL } from "../actions";
import {
  appId,
  apiUrlV2,
  deliveryId,
  headerconfig,
} from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetZonedetail = function* () {
  yield takeEvery(GET_ZONE_DETAIL, workerGetZonedetail);
};

function* workerGetZonedetail({ outletId, zoneId, shop_type }) {
  var outlet_Id = "";
  if (
    outletId !== "" &&
    typeof outletId !== undefined &&
    typeof outletId !== "undefined"
  ) {
    outlet_Id = "&outletId=" + outletId;
  }
  var shoptype = "";
  if (
    shop_type !== "" &&
    typeof shop_type !== undefined &&
    typeof shop_type !== "undefined"
  ) {
    shoptype = "&shop_type=" + shop_type;
  }
  try {
    const uri =
      apiUrlV2 +
      "outlets/getZoneDetails?app_id=" +
      appId +
      "&availability=" +
      deliveryId +
      outlet_Id +
      shoptype +
      "&ZoneId=" +
      zoneId;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ZONE_DETAIL, value: resultArr });
  } catch {
    console.log("Get All Outlets Failed");
  }
}
