/* eslint-disable */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
/* import modules */
import { format } from "date-fns";
import cookie from "react-cookies";
import Moment from "moment";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "./Sidebar";
import { deliveryId, dineinId, cookieConfig } from "../Helpers/Config";
import { addressFormat, stripslashes } from "../Helpers/SettingHelper";
import {
  GET_CORDERDETAIL,
  GET_PORDERDETAIL,
  GET_ORDERHISTORY,
} from "../../actions";
import mapImg from "../../common/images/map-pindark.svg";
import clock from "../../common/images/clock-purple.svg";
var Parser = require("html-react-parser");
class Orders extends Component {
  constructor(props) {
    super(props);
    var orderID =
      this.props.match.params.orderID !== "" &&
      typeof this.props.match.params.orderID !== undefined &&
      typeof this.props.match.params.orderID !== "undefined"
        ? this.props.match.params.orderID
        : "";
    this.state = {
      corderdetail: [],
      cartItems: [],
      cartData: [],
      cart_brktime_list: [],
      showitems: 100,
      showpitems: 100,
      showresvitems: 10,
      resviewmore: "none",
      order_all: 0,
      overall_orders: 0,
      deliveryTakeaway_orders: 0,
      ReceiptDetails: [],
      orderID: orderID,
    };
    if (cookie.load("loginTableNo") === "Yes") {
      props.history.push("/");
    }
    if (cookie.load("UserId") === undefined) {
      cookie.save("loginpopupTrigger", "Yes", cookieConfig);
      props.history.push("/");
    } else if (orderID !== "") {
      $("#dvLoading").fadeIn();
      var params =
        "&customer_id=" +
        cookie.load("UserId") +
        "&local_order_no=" +
        this.state.orderID;
      this.props.getOrderHistory(params);
    }
  }

  componentDidMount() {
    /* delivery current - past orders */
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);

    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
    $("#dvLoading").fadeOut(2000);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.match.params.orderID !== "" &&
      typeof nextProps.match.params.orderID !== undefined &&
      typeof nextProps.match.params.orderID !== "undefined"
    ) {
      if (nextProps.match.params.orderID !== this.state.orderID) {
        this.setState({ orderID: nextProps.match.params.orderID }, function () {
          $("#dvLoading").fadeIn();
          var params =
            "&customer_id=" +
            cookie.load("UserId") +
            "&local_order_no=" +
            this.state.orderID;
          this.props.getOrderHistory(params);
        });
      }
    } else {
      this.setState({ ReceiptDetails: [], orderID: "" });
    }

    /*current orders */
    if (nextProps.corderdetail !== this.props.corderdetail) {
      if (nextProps.corderdetail && nextProps.corderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ corderdetail: nextProps.corderdetail[0].result_set });
        if (nextProps.corderdetail[0].common.total_records) {
          this.setState({
            totalcount: nextProps.corderdetail[0].common.total_records,
          });
        }
        if (
          this.state.showitems > nextProps.corderdetail[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }

    if (nextProps.porderdetail !== this.props.porderdetail) {
      if (nextProps.porderdetail && nextProps.porderdetail[0].status == "ok") {
        $("b").removeClass("gloading_img");
        $("#dvLoading").fadeOut(2000);
        this.setState({ porderdetail: nextProps.porderdetail[0].result_set });
        if (
          nextProps.porderdetail[0].common.total_records &&
          this.state.showpitems > nextProps.porderdetail[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }

    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          CartItems: nextProps.orderhistory[0].result_set[0]["items"],
          ReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });

        $("#dvLoading").fadeOut(5000);
      } else {
        this.setState({ ReceiptDetails: [] });
      }
    }
  }

  /* for order load more  button */
  loadcurrentItems() {
    var pageNext = this.state.showitems + 10;
    this.setState({ showitems: pageNext }, function () {
      this.loadcitems();
    });
  }
  loadpastItems() {
    var pagepNext = this.state.showpitems + 10;
    this.setState({ showpitems: pagepNext }, function () {
      this.loadpitems();
    });
  }

  loadcitems() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    var deliveryparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getCorderDetail(deliveryparams);
  }
  loadpitems() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    var deliverypastparams =
      "&customer_id=" +
      cookie.load("UserId") +
      "&availabilityIDs=" +
      deliveryId +
      "&limit=" +
      this.state.showitems +
      "&except_availability=yes";
    this.props.getPorderDetail(deliverypastparams);
  }

  getReceipt(orderId) {
    this.props.history.push("/myorders/" + orderId);
  }

  getOrderItemData = (dataProp, orderType) => {
    var dataProp = dataProp !== undefined ? dataProp : Array();
    if (Object.keys(dataProp).length > 0) {
      return dataProp.map((item, index) => {
        const orderDate = Moment(item.order_date).format("ddd DD MMM YYYY");
        const orderTime = this.showOrdTimeSlot(item);
        return (
          <li
            key={item.order_id}
            onClick={this.getReceipt.bind(this, item.order_local_no)}
          >
            <div className="order-lhs">
              <p>
                {orderDate}, {orderTime}
              </p>
              <span>
                ${item.order_total_amount} · Ordered{" "}
                {Moment(item.order_created_on).format("DD MMM YYYY")}
              </span>
            </div>
            {orderType === "c" && (
              <div className="order-rhs">
                {this.loadStatus(item.status_name)}
              </div>
            )}
          </li>
        );
      });
    } else {
      return (
        <div className="no-recrds-found">
          {orderType === "c"
            ? "You have no current orders."
            : "You have no past orders."}{" "}
        </div>
      );
    }
  };

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }
    return ordTmSlt;
  }
  loadStatus(status_name) {
    var statusBg = "";
    if (status_name === "Received") {
      statusBg = "received";
    } else if (status_name === "Processing") {
      statusBg = "processing";
    } else if (status_name === "Delivering") {
      statusBg = "delivering";
    } else if (status_name === "Completed") {
      statusBg = "completed";
    } else if (status_name === "Cancelled") {
      statusBg = "cancelled";
    } else if (status_name === "Ready To Pickup") {
      statusBg = "delivering";
    }
    return <span className={"order-" + statusBg}>{status_name}</span>;
  }
  sateValChange = (field, value) => {};

  render() {
    return (
      <div className="myacc-main-div">
        <Header sateValChange={this.sateValChange} />

        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <Sidebar pageName="myorders" />
              {Object.keys(this.state.ReceiptDetails).length === 0 ? (
                this.state.orderID === "" && (
                  <div className="mainacc_menucontent">
                    <div className="current_order">
                      <h4>Current orders</h4>

                      <div className="myacc_order_details">
                        <ul>
                          {this.getOrderItemData(this.state.corderdetail, "c")}
                        </ul>
                      </div>

                      {/* <div className="load_more_div">
                    <button
                      className="load_more_data"
                      onClick={this.loadcurrentItems.bind(this)}
                      style={{ display: "none" }}
                    >
                      Load More
                    </button>
                  </div> */}
                    </div>
                    <div className="past-order">
                      <h4>Past Orders</h4>

                      <div className="myacc_order_details">
                        <ul>
                          {this.getOrderItemData(this.state.porderdetail, "p")}
                        </ul>
                      </div>

                      {/* <div className="load_more_div">
                    <button
                      className="load_more_data1"
                      style={{ display: "none" }}
                      onClick={this.loadpastItems.bind(this)}
                    >
                      Load More
                    </button>
                  </div> */}
                    </div>

                    {/* next tab */}
                  </div>
                )
              ) : (
                <div className="order-details myacc-thkyou-main">
                  <Viewreceipt
                    details={this.state.ReceiptDetails}
                    cartItems={this.state.CartItems}
                  />
                </div>
              )}
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div id="dvLoading"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    corderdetail: state.corderdetail,
    porderdetail: state.porderdetail,
    orderhistory: state.orderhistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCorderDetail: (deliveryparams) => {
      dispatch({ type: GET_CORDERDETAIL, deliveryparams });
    },
    getPorderDetail: (deliverypastparams) => {
      dispatch({ type: GET_PORDERDETAIL, deliverypastparams });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
  };
};

Orders.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Orders));

class Viewreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(cartItems) {
    if (Object.keys(cartItems).length > 0) {
      return Object.keys(cartItems).map((index) => {
        var outlet = cartItems[index].outlet;
        return (
          <div className="outlet-product-inner" key={index}>
            <div className="outlet-name">{outlet}</div>
            {Object.keys(cartItems[index].items).map((index1) => {
              return this.getCartItemDetList(cartItems[index].items[index1]);
            })}
          </div>
        );
      });
    }
  }

  getCartItemDetList(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row oreder_itm_row_inner" key={index}>
          <div className="row oreder-row-inv">
            <div className="col-xs-7 cart_left">
              <div className="cart_info">
                <h4>{stripslashes(item.item_name)}</h4>
                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
              </div>
              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}
              {/*} 
				 <p className ="help-block">Special instruction for individual product items ...</p>
				 {*/}
            </div>
            <div className="col-xs-5 cart_right text-right">
              <span className="order-qty">{item.item_qty}</span>
              <div className="cart_price">
                <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  loadItems(orderDetail) {
    if (Object.keys(orderDetail).length > 0) {
      var cartItems = orderDetail["items"];

      return Object.keys(cartItems).map((index) => {
        var outlet = cartItems[index].outlet;
        return (
          <div className="outlet-product-inner" key={index}>
            {/*  <div className="outlet-name">
              <a href="">
                {outlet}
                <img src={arrow} />
              </a>
            </div> */}
            {Object.keys(cartItems[index].items).map((index1) => {
              return <>{this.loadItemsList(cartItems[index].items[index1])}</>;
            })}
          </div>
        );
      });
    }
  }

  loadItemsList(orderDetail) {
    if (orderDetail.length > 0) {
      var cartItems = orderDetail;
      return cartItems.map((item, index) => (
        <div className="outlet-product-parent" key={index}>
          <div className="cart_row">
            <div className="row">
              <div className="col-xs-9 cart_left">
                <div className="cart_info">
                  <h4>{stripslashes(item.item_name)}</h4>

                  {this.loadModifierItems(
                    "Component",
                    item.modifiers,
                    item.set_menu_component
                  )}

                  {item.item_specification !== "" && (
                    <p className="help-block">
                      {stripslashes(item.item_specification)}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-xs-3 cart_right text-right">
                <span className="order-qty">{item.item_qty}</span>
                <div className="cart_price">
                  <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';
    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p>" +
          stripslashes(modName) +
          ":</p><p> " +
          stripslashes(modval) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);
        html +=
          "<p>" +
          comboName +
          ":</p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p>";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboVal = this.showComboInCombo(combos[r]["set_menu_component"]);
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        html +=
          "<p>" +
          comboQty +
          " X " +
          comboPro +
          newPrice +
          " " +
          comboVal +
          " </p>";
      }
      return html;
    }
    return "";
  }

  showComboInCombo(combo) {
    var html = "";
    var comboLen = combo.length;
    for (var i = 0, length = comboLen; i < length; i++) {
      var comboName = combo[i]["menu_component_name"];
      var comboVal = this.showComboInComboProducts(combo[i]["product_details"]);
      html +=
        "<ul class='cart-combo-in-combo'><li>" +
        comboName +
        ": </li>" +
        comboVal +
        " " +
        "</ul> ";
    }
    return html;
  }

  showComboInComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        if (parseInt(combos[r]["menu_inner_product_qty"]) > 0) {
          var comboPro = combos[r]["menu_inner_product_name"];
          var comboQty = combos[r]["menu_inner_product_qty"];
          var comboPrice = combos[r]["menu_inner_product_price"];
          var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
          html += "<li>" + comboQty + " X " + comboPro + newPrice + " </li> ";
        }
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div > ";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p>";
      }
      html += "</div>";
    }
    return html;
  }
  componentWillReceiveProps(nextProps) {}

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }
    return ordTmSlt;
  }
  loadStatus(status_name) {
    var statusBg = "";
    if (status_name === "Received") {
      statusBg = "received";
    } else if (status_name === "Processing") {
      statusBg = "processing";
    } else if (status_name === "Delivering") {
      statusBg = "delivering";
    } else if (status_name === "Completed") {
      statusBg = "completed";
    } else if (status_name === "Cancelled") {
      statusBg = "cancelled";
    } else if (status_name === "Collected") {
      statusBg = "collected";
    } else if (status_name === "Packed") {
      statusBg = "packed";
    }
    return <span className={"order-" + statusBg}>{status_name}</span>;
  }

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-order">
                <h3>Order #{details.order_local_no}</h3>
              </div>
              <div className="order-status">
                {this.loadStatus(details.status_name)}
              </div>
            </div>

            <div className="tnk-delivery">
              <div className="order-date-parent">
                <div className="delivery_total_number">
                  <div className="delivery_total_left">
                    <img src={clock} />
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : details.order_availability_id === dineinId
                        ? "Dine In"
                        : "Pickup"}{" "}
                      date & time
                    </h2>
                    <h4 className="checkoutDate">
                      {Moment(details.order_date).format("ddd DD MMM YYYY")}
                      <br />
                      {this.showOrdTimeSlot(details)}
                    </h4>
                  </div>
                </div>

                {details.order_availability_id === deliveryId ? (
                  <div className="delivery_total_text">
                    <div className="delivery_total_left">
                      <img src={mapImg} />
                      <h2>Delivery address</h2>
                      <span>{details.order_customer_address_line1}</span>{" "}
                      <span>
                        Singapore {details.order_customer_postal_code}
                      </span>{" "}
                      <span>
                        {details.order_customer_unit_no1 != "" &&
                        details.order_customer_unit_no2 != ""
                          ? details.order_customer_unit_no1 +
                            "-" +
                            details.order_customer_unit_no2
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="delivery_total_text">
                    <div className="delivery_total_left">
                      <img src={mapImg} />
                      <h2>
                        {details.order_availability_id === dineinId
                          ? "Dine In"
                          : "Pickup"}{" "}
                        Location
                      </h2>
                      <span>
                        {details.outlet_name !== "" &&
                        details.outlet_name !== undefined
                          ? stripslashes(details.outlet_name)
                          : ""}
                      </span>{" "}
                      <span>
                        {addressFormat(
                          details.outlet_unit_number1,
                          details.outlet_unit_number2,
                          details.outlet_address_line1,
                          details.outlet_address_line2,
                          details.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {details.order_availability_id === deliveryId &&
                details.padd_api_driver_name !== null &&
                details.padd_api_driver_phone !== null && (
                  <div className="order-rider-details">
                    <div className="delivery_total_left">
                      <h2>Rider Name</h2>
                      <h4 className="checkoutDate">
                        {details.padd_api_driver_name}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <h2>Rider Mobile</h2>
                      <h4 className="checkoutTime">
                        {details.padd_api_driver_phone}
                      </h4>
                    </div>
                  </div>
                )}

              <div className="orderitem_body_div">
                <div className="hcart_tt-details">
                  <h5>Your order</h5>
                  <h5 className="mobile-order-item-title">
                    Ordered items ({details.total_item})
                  </h5>
                </div>
                {/* <div className="hcart_tt_mobile">
                  <h5>
                    <a href="/">View order breakdown</a>
                  </h5>
                </div> */}
                <div className="order_item_desktop">
                  {this.loadItems(details)}
                </div>
                {details.order_remarks !== "" &&
                  details.order_remarks !== null && (
                    <div className="remark_notesec text-left">
                      <h4>Remarks</h4>
                      <p>{details.order_remarks}</p>
                    </div>
                  )}
                {details.order_customer_send_gift === "Yes" && (
                  <>
                    {details.order_recipient_name !== "" &&
                      details.order_recipient_name !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Name</h4>
                          <p>{details.order_recipient_name}</p>
                        </div>
                      )}
                    {details.order_recipient_contact_no !== "" &&
                      details.order_recipient_contact_no !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{details.order_recipient_contact_no}</p>
                        </div>
                      )}
                    {details.order_gift_message !== "" &&
                      details.order_gift_message !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{details.order_gift_message}</p>
                        </div>
                      )}
                  </>
                )}
              </div>

              <div className="order-items-maindiv">
                {details.order_remarks !== "" &&
                  details.order_remarks !== null && (
                    <div className="remark_notesec text-left">
                      <h4>Remarks</h4>
                      <p>{details.order_remarks}</p>
                    </div>
                  )}
                {details.order_customer_send_gift === "Yes" && (
                  <>
                    {details.order_recipient_name !== "" &&
                      details.order_recipient_name !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Name</h4>
                          <p>{details.order_recipient_name}</p>
                        </div>
                      )}
                    {details.order_recipient_contact_no !== "" &&
                      details.order_recipient_contact_no !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{details.order_recipient_contact_no}</p>
                        </div>
                      )}
                    {details.order_gift_message !== "" &&
                      details.order_gift_message !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Recipient Contact Number</h4>
                          <p>{details.order_gift_message}</p>
                        </div>
                      )}
                  </>
                )}
              </div>

              <div className="tnk_cart_footer">
                <h3>Payment Summary</h3>
                <div className="order-divider">
                  <div className="grant-total-cls">
                    <div className="cart_row">
                      <div className="row">
                        <div className="col-xs-7 order-smry-lhs">
                          <p>Amount paid</p>
                          {details.payment_details !== undefined &&
                            Object.keys(details.payment_details).length > 0 && (
                              <label className="payment-via">
                                via {details.payment_details.card_type} Card
                                (**** {details.payment_details.last_digit})
                              </label>
                            )}
                        </div>
                        <div className="col-xs-5 text-right order-smry-lhs">
                          <span>${details.order_total_amount}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="order-divider">
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7 order-smry-lhs">
                        <p>Subtotal</p>
                      </div>
                      <div className="col-xs-5 text-right order-smry-rhs">
                        <span>${details.order_sub_total}</span>
                      </div>
                    </div>
                  </div>

                  {parseFloat(details.order_delivery_charge) > 0 && (
                    <div className="cart_row">
                      <div className="row">
                        <div className="col-xs-7">
                          <p>Delivery Charges</p>
                        </div>
                        <div className="col-xs-5 text-right">
                          <span>
                            $
                            {(
                              parseFloat(details.order_delivery_charge) +
                              parseFloat(details.order_additional_delivery)
                            ).toFixed(2)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {(parseFloat(details.order_discount_amount) > 0 ||
                  (details.order_promocode_name !== "" &&
                    details.order_promocode_name !== null)) && (
                  <div className="order-divider">
                    <div className="cart_row">
                      <div className="row">
                        <div className="col-xs-7">
                          <p>
                            {details.order_discount_type === "redeem"
                              ? "Discount (-)"
                              : details.order_promocode_name !== ""
                              ? "Promocode (" +
                                details.order_promocode_name +
                                ")"
                              : "Promocode (-)"}
                          </p>
                        </div>
                        <div className="col-xs-5 text-right">
                          {parseFloat(details.order_discount_amount) > 0 && (
                            <span>${details.order_discount_amount}</span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {details.order_tax_calculate_amount > 0 && (
                  <div className="cart_row">
                    <div className="row">
                      <div className="col-xs-7">
                        <p>GST({details.order_tax_charge}%)</p>
                      </div>
                      <div className="col-xs-5 text-right">
                        <span>${details.order_tax_calculate_amount}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}
