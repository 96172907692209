/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_SEARCHRECLIST, SET_SEARCHRECLIST } from "../actions";
import { apiUrl, appId, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetSearchRecproductslist = function* () {
  yield takeEvery(GET_SEARCHRECLIST, workerGetSearchRecproductslist);
};

function* workerGetSearchRecproductslist() {
  try {
    const uri =
      apiUrl +
      "products/recommendedpro?app_id=" +
      appId +
      "&customer_id=" +
      cookie.load("UserId");
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_SEARCHRECLIST, value: resultArr });
  } catch (e) {
    console.log(e, "Get Search Recoment Products List Failed");
  }
}
