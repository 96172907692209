import { takeEvery, call, put } from "redux-saga/effects";
import { GET_FIND_OUTLETS, SET_FIND_OUTLETS } from "../actions";
import { appId, apiUrl, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetFindOutlets = function* () {
  yield takeEvery(GET_FIND_OUTLETS, workerGetFindOutlets);
};

function* workerGetFindOutlets({ availability, postal_code }) {
  try {
    const uri =
      apiUrl +
      "outlets/find_outlet?app_id=" +
      appId +
      "&availability_id=" +
      availability +
      "&postal_code=" +
      postal_code;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_FIND_OUTLETS, value: resultArr });
  } catch {
    console.log("Get Find Outlets Failed");
  }
}
