/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CARTITEMCOUNT, SET_CARTITEMCOUNT } from "../actions";
import { appId, apiUrl, headerconfig } from "../components/Helpers/Config";
import { getReferenceID } from "../components/Helpers/SettingHelper";
import Axios from "axios";
import cookie from "react-cookies";
var qs = require("qs");

export const watchGetCartItemCount = function* () {
  yield takeEvery(GET_CARTITEMCOUNT, workerGetCartItemCount);
};

function* workerGetCartItemCount() {
  try {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    customerParam += "&availability_id=" + cookie.load("defaultAvilablityId");

    const uri =
      apiUrl + "cart/item_count?status=A&app_id=" + appId + customerParam;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CARTITEMCOUNT, value: resultArr });
  } catch {
    console.log("Get Cart Detail Failed");
  }
}
