/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

import cookie from "react-cookies";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Searchrecoment from "../Home/HomeSubCompo/Searchrecoment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Axios from "axios";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  restaurantLink,
  shopType,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import logo0 from "../../common/images/no-img-80.png";
import noimage from "../../common/images/no-img-product.png";
import nofound from "../../common/images/no-found-img.png";
import searchicon from "../../common/images/search-purple.svg";
import noresults from "../../common/images/no-results.svg";

var qs = require("qs");
var Parser = require("html-react-parser");

class Search extends Component {
  constructor(props) {
    super(props);
    var searchKeyword =
      this.props.match.params.searchKeyword !== "" &&
      typeof this.props.match.params.searchKeyword !== undefined &&
      typeof this.props.match.params.searchKeyword !== "undefined"
        ? this.props.match.params.searchKeyword
        : "";
    this.state = {
      searchoutlet: "",
      displayoutlet: "",
      displayproducts: "",
      search_keyword: searchKeyword,
      tagList: [],
      tagDisplay: "",
      clickTag: false,
    };
    if (searchKeyword !== "") {
      this.searcheats();
    }
  }

  componentDidMount() {
    $("#search_keywords").focus();
    this.loadTagList();
  }

  sateValChange = (field, value) => {
    if (field === "search_keyword") {
      this.setState({ search_keyword: value, clickTag: false }, function () {
        this.searcheats();
      });
    }
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  loadTagList() {
    axios
      .get(apiUrl + "products/product_tags?app_id=" + appId, headerconfig)
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState({ tagList: res.data.result_set }, function () {
            this.tagdisplay();
          });
        }
      });
  }
  tagdisplay() {
    var tagDisplay = "";
    if (this.state.tagList.length > 0) {
      tagDisplay = this.state.tagList.map((item, index) => {
        return (
          <li
            key={index}
            className={
              this.state.search_keyword !== "" &&
              item.pro_tag_name.toLowerCase() ===
                this.state.search_keyword.toLowerCase() &&
              this.state.clickTag === true
                ? "active"
                : ""
            }
          >
            <a
              href={void 0}
              onClick={this.setTagSearch.bind(this, item.pro_tag_name)}
            >
              {item.pro_tag_name}
            </a>
          </li>
        );
      });
    }
    this.setState({ tagDisplay: tagDisplay });
  }
  setTagSearch(tagName) {
    this.setState({ search_keyword: tagName, clickTag: true }, function () {
      this.searcheats();
    });
  }
  searcheats() {
    showLoader("search_result_div", "Idtext");
    axios
      .get(
        apiUrl +
          "products/appsearch?app_id=" +
          appId +
          "&search_keyword=" +
          this.state.search_keyword,
        headerconfig
      )
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState({ searchResult: res.data }, function () {
            this.searchsdisplay();
          });
        } else if (res.data.status === "error") {
          /* Error response */
          this.setState({ searchResult: [] }, function () {
            this.searchsdisplay();
          });
        }
      });
  }

  searchsdisplay() {
    var displayoutlet = "";
    var displayproducts = "";
    if (Object.keys(this.state.searchResult).length > 0) {
      if (this.state.searchResult.outletList.length > 0) {
        var mediaUrl = this.state.searchResult.common.image_source;
        displayoutlet = this.state.searchResult.outletList.map(
          (item, index) => {
            return (
              <li key={index}>
                <Link to={"/food/" + item.outlet_slug} className="outlet-inner">
                  <div className="outlet-img">
                    <img
                      src={
                        item.outlet_image !== "" && item.outlet_image !== null
                          ? mediaUrl + item.outlet_image
                          : logo0
                      }
                      alt={item.outlet_name}
                      title={item.outlet_name}
                    />
                  </div>
                  <div className="outlet-content">
                    <h5>{item.outlet_name}</h5>
                    <p>
                      {/* item.sl_name} ·{" " */}
                      {/* this.dispalyshortOutetTag(item.outlet_tag_id) */}
                    </p>
                    {item.outlet_open_time !== "" && (
                      <span>{item.outlet_open_time}</span>
                    )}
                  </div>
                </Link>
              </li>
            );
          }
        );
      } else {
        displayoutlet = (
          <li className="no_restaurants">
            There are no restaurants matching your search.
          </li>
        );
      }

      if (this.state.searchResult.productList.length > 0) {
        var mediaUrl = this.state.searchResult.common.product_image_source;
        displayproducts = this.state.searchResult.productList.map(
          (productDetail, index) => {
            var description =
              productDetail.product_short_description !== null &&
              productDetail.product_short_description !== ""
                ? Parser(stripslashes(productDetail.product_short_description))
                : "";

            var prodivId = "proIndex-" + productDetail.product_primary_id;
            var comboProId = "comboPro-" + productDetail.product_slug;
            var productName =
              productDetail.product_alias !== ""
                ? stripslashes(productDetail.product_alias)
                : stripslashes(productDetail.product_name);
            return (
              <li
                className={this.chkProStockCls(
                  productDetail.product_slug,
                  productDetail.product_stock
                )}
                id={prodivId}
                key={index}
              >
                {productDetail.product_tag_info !== "" &&
                  productDetail.product_tag_info !== null && (
                    <div className="ribbon">
                      <span>{productDetail.product_tag_info}</span>
                    </div>
                  )}
                <Link
                  to={
                    "/" +
                    restaurantLink +
                    "/" +
                    productDetail.outlet_slug +
                    "/" +
                    productDetail.cat_slug +
                    "/" +
                    productDetail.subcate_slug +
                    "/" +
                    productDetail.product_slug
                  }
                >
                  <div className="products-image-div">
                    {productDetail.product_thumbnail !== "" ? (
                      <img
                        src={mediaUrl + "/" + productDetail.product_thumbnail}
                        title={
                          productDetail.product_thumbnail_title !== "" &&
                          productDetail.product_thumbnail_title !== null
                            ? productDetail.product_thumbnail_title
                            : productName
                        }
                        alt={
                          productDetail.product_thumbnail_alt !== "" &&
                          productDetail.product_thumbnail_alt !== null
                            ? productDetail.product_thumbnail_alt
                            : productName
                        }
                      />
                    ) : (
                      <img
                        src={noimage}
                        title={productName}
                        alt={productName}
                      />
                    )}
                    {productDetail.product_stock <= 0 && (
                      <div className="not-available-stock">
                        This product is out of stock
                      </div>
                    )}
                  </div>
                </Link>

                <div className="product-info-div">
                  <div className="product-outlet-name">
                    <Link
                      to={
                        "/" + restaurantLink + "/" + productDetail.outlet_slug
                      }
                    >
                      {productDetail.outlet_name}
                    </Link>
                  </div>
                  <div className="product-title-maindiv">
                    <div className="product-title">
                      <Link
                        to={
                          "/" +
                          restaurantLink +
                          "/" +
                          productDetail.outlet_slug +
                          "/" +
                          productDetail.cat_slug +
                          "/" +
                          productDetail.subcate_slug +
                          "/" +
                          productDetail.product_slug
                        }
                      >
                        <h3>{productName}</h3>
                      </Link>
                    </div>
                  </div>

                  <div className="products-ordernow-action">
                    <div className="product-price">
                      <h3>
                        {productDetail.product_specialprice_applicable === "yes"
                          ? showPriceValue(productDetail.product_special_price)
                          : showPriceValue(productDetail.product_price)}
                      </h3>
                      {productDetail.product_specialprice_applicable === "yes"
                        ? parseFloat(productDetail.product_price) > 0 && (
                            <span className="product-originale-price">
                              {showPriceValue(productDetail.product_price)}
                            </span>
                          )
                        : parseFloat(productDetail.product_cost) > 0 && (
                            <span className="product-originale-price">
                              {showPriceValue(productDetail.product_cost)}
                            </span>
                          )}
                    </div>
                    <div className="addcart_row addcart_done_maindiv">
                      <div className="addcart-row-child">
                        <div className="qty_bx">
                          <span
                            className="qty_minus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "decr"
                            )}
                          >
                            -
                          </span>
                          <input
                            type="text"
                            className="proqty_input"
                            readOnly
                            value="1"
                          />
                          <span
                            className="qty_plus"
                            onClick={this.proQtyAction.bind(
                              this,
                              productDetail.product_primary_id,
                              "incr"
                            )}
                          >
                            +
                          </span>
                        </div>
                        <button
                          className="button btn btn_black order_done"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "done"
                          )}
                        >
                          Done
                        </button>
                      </div>
                    </div>

                    {productDetail.product_stock > 0 ||
                    productDetail.product_stock === null ? (
                      productDetail.product_type === "1" ? (
                        <a
                          href="/"
                          onClick={this.addToCartSimple.bind(
                            this,
                            productDetail,
                            "initial"
                          )}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv smiple_product_lk"
                        >
                          Add to Cart
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.viewProDetail.bind(this, productDetail)}
                          title="Product Details"
                          id={comboProId}
                          className="button order_nowdiv compo_product_lk"
                        >
                          Order Now
                        </a>
                      )
                    ) : (
                      <a className="button disabled disbl_href_action" href="/">
                        Sold Out
                      </a>
                    )}
                  </div>
                </div>
              </li>
            );
          }
        );
      } else {
        displayproducts = (
          <li className="no_restaurants">
            There are no dishes matching your search.
          </li>
        );
      }
    }

    this.setState(
      {
        displayoutlet: displayoutlet,
        displayproducts: displayproducts,
      },
      function () {
        this.tagdisplay();
        hideLoader("search_result_div", "Idtext");
      }
    );
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    if (
      avilablityId !== "" &&
      typeof avilablityId !== undefined &&
      typeof avilablityId !== "undefined"
    ) {
      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();

        var specialprice_applicable =
          productDetail.product_specialprice_applicable;

        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          outletLocationId: productDetail.outlet_location_id,
          outlet_id: productDetail.outlet_id,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          specialprice_applicable: specialprice_applicable,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
          shopType: shopType,
          outletzone_id: this.state.orderZoneId,
          login_type:
            typeof cookie.load("userAccountType") === "undefined"
              ? ""
              : cookie.load("userAccountType"),
          reference_new_id: getReferenceID(),
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject),
          headerconfig
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            var productName =
              productDetail.product_alias !== "" &&
              productDetail.product_alias !== null
                ? productDetail.product_alias
                : productDetail.product_name;
            var proPrice = productDetail.product_price;
            if (specialprice_applicable === "yes") {
              proPrice = productDetail.product_special_price;
            }

            this.setState({ cartTriggerFlg: "yes" });
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart.",
              "cart-success-msg"
            );
            removePromoCkValue();
            return false;
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.form_error !== ""
                ? res.data.form_error
                : res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
          }
        });
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
  }

  viewProDetail(productDetail, event) {
    event.preventDefault();
    var avilablityId = cookie.load("defaultAvilablityId");
    if (
      avilablityId !== "" &&
      typeof avilablityId !== undefined &&
      typeof avilablityId !== "undefined"
    ) {
      var productSlug = productDetail.product_slug;
      if (productSlug !== "") {
        this.props.history.push(
          "/" +
            restaurantLink +
            "/" +
            productDetail.outlet_slug +
            "/" +
            productDetail.cat_slug +
            "/" +
            productDetail.subcate_slug +
            "/" +
            productSlug
        );
      }
    } else {
      cookie.save("popuptriggerFrom", "FeaturedPro", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }
    return false;
  }

  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-li no-stock-product " + proSlug;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug;
    }

    return returnText;
  }

  handleChangeTxt = (item, event) => {
    var value = event.target.value;
    console.log(value, "valuevalue");
    this.setState({ [item]: value }, function () {
      this.searcheats();
    });
  };

  chkProStockCls(proSlug, Stock) {
    var returnText = "products-single-li no-stock-product";
    if (Stock > 0) {
      returnText = "products-single-li";
    }
    return returnText;
  }

  render() {
    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        {/* Header End */}
        <div className="container">
          <div className="search-mobile-inner">
            <img className="search-mobile-icon" src={searchicon} alt="Search" />
            <input
              type="text"
              placeholder="Search Singapore Swimming Club"
              id="mobile_search"
              name="mobile_search"
              value={this.state.search_keyword}
              onChange={this.handleChangeTxt.bind(this, "search_keyword")}
            />
          </div>

          <div className="search-result" id="search_result_div">
            {this.state.search_keyword !== "" && (
              <h3 className="bigweight">
                Search results for “<span>{this.state.search_keyword}</span>”
              </h3>
            )}
            {this.state.displayoutlet !== "" ||
            this.state.displayproducts !== "" ? (
              <>
                <div className="search-resturent">
                  <h4>Restaurants And F&B Outlets</h4>
                  <ul>{this.state.displayoutlet}</ul>
                </div>
                <div className="search-dishes">
                  <h4>Dishes</h4>
                  <ul>{this.state.displayproducts}</ul>
                </div>
              </>
            ) : (
              this.state.search_keyword !== "" && (
                <>
                  <div className="search-no-parent">
                    <div className="search-no-found">
                      <img src={noresults} />
                      <h3>No results found</h3>
                      <p>
                        Fret not, we have over 30 restaurants and 500 dishes
                        from you to choose from!
                      </p>
                      <Link
                        to={"/restaurants"}
                        className="button ghost-button"
                        title="Browse all restaurants & outlets"
                      >
                        Browse all restaurants & outlets
                      </Link>
                    </div>
                    {this.state.tagDisplay !== "" && (
                      <div className="search-popular-cuisines">
                        <ul>{this.state.tagDisplay}</ul>
                      </div>
                    )}
                  </div>
                </>
              )
            )}

            {this.state.search_keyword === "" && (
              <>
                <div className="search-placeholder-txt">
                  <h4>Need some inspiration?</h4>
                  <p>Try these new offerings from our restaurants!</p>
                </div>
                <Searchrecoment
                  {...this.props}
                  sateValChange={this.sateValChange}
                />
                {this.state.tagDisplay !== "" && (
                  <div className="search-popular-cuisines">
                    <h4>Popular Cuisines</h4>
                    <ul>{this.state.tagDisplay}</ul>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

Search.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
