/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import { connect } from "react-redux";
import Moment from "moment";
import { format } from "date-fns";
import cookie from "react-cookies";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import {
  appId,
  apiUrlV2,
  deliveryId,
  shopType,
  headerconfig,
  dineinId,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showPriceValue,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

import { GET_PRODUCT_LIST } from "../../actions";
var Parser = require("html-react-parser");
var qs = require("qs");
var settingsBanner = {
  items: 2,
  dots: false,
  autoplay: false,
  nav: true,
  margin: 18,
  responsive: {
    0: {
      items: 2,
      margin: 8,
    },
    600: {
      items: 2,
      margin: 10,
    },
    900: {
      items: 3,
    },
    1200: {
      items: 3,
    },
  },
};
class ProductList extends Component {
  constructor(props) {
    super(props);

    var avilablityIdTxt =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderZoneId =
      cookie.load("orderZoneId") !== "" &&
      typeof cookie.load("orderZoneId") !== undefined &&
      typeof cookie.load("orderZoneId") !== "undefined"
        ? cookie.load("orderZoneId")
        : "";

    this.state = {
      selectedNavigation: "",
      settings: [],
      orderZoneId: orderZoneId,
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      outlet_slug: this.props.match.params.slugRestaurant,
      allProductList: "",
      imagesource: "",
      cartOutletList: [],
      outletslist: [],
      totalCartCount: 0,
      orderOutletId: "",
      shopeClosed: 0,
      defaultAvilTy: avilablityIdTxt,
      displayTime: "",
      scrollProduct: "No",
    };

    this.showOrdTimeSlot();
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    let selectedNavigation = nextProps.productState.selectedNavigation;
    let slugType = nextProps.productState.catslugType;
    let outletslist = nextProps.productState.outletslist;
    let totalCartCount = nextProps.productState.totalCartCount;
    if (totalCartCount != this.state.totalCartCount) {
      this.setState({ totalCartCount: totalCartCount });
    }
    if (
      nextProps.productState.loadproduct === "Yes" &&
      nextProps.productState.orderOutletId !== "" &&
      this.state.orderOutletId !== nextProps.productState.orderOutletId /* &&
      this.props.productState.orderOutletId !==
        nextProps.productState.orderOutletId */
    ) {
      //if (selectedNavigation !== this.state.selectedNavigation) {

      var outlets_list = [];
      if (outletslist !== "") {
        outlets_list = outletslist[0];
      }
      this.setState(
        {
          selectedNavigation: selectedNavigation,
          selectedslugType: slugType,
          outletslist: outlets_list,
          orderOutletId: nextProps.productState.orderOutletId,
          allProductList: "",
        },
        function () {
          if (selectedNavigation !== "") {
            var catSlug = selectedNavigation;
            if (slugType === "subcategory") {
              catSlug = "";
              subcatSlug = selectedNavigation;
            }

            //if (nextProps.productState.navigateMenu > 0) {
            this.props.sateValChange("loadproduct", "No");
            this.checkTimeAvilablity(
              nextProps.productState.navigateMenu,
              this.state.outlet_slug,
              nextProps.productState.orderOutletId
            );
            // }

            //this.props.getProductList(catSlug, subcatSlug, this.state.outlet_slug);
          }
        }
      );
    }
    if (nextProps.productState.settings !== this.state.settings) {
      if (
        nextProps.productState.settings !== "" &&
        typeof nextProps.productState.settings !== undefined &&
        typeof nextProps.productState.settings !== "undefined"
      ) {
        this.setState({ settings: nextProps.productState.settings });
      }
    }
    if (this.state.cartOutletList !== nextProps.cartOutletList) {
      this.setState({ cartOutletList: nextProps.cartOutletList });
    }
  }

  /* Advanced Slot */
  showOrdTimeSlot() {
    var TodayDate = new Date();
    var deliveryDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined" &&
      cookie.load("deliveryDate") !== undefined &&
      cookie.load("deliveryDate") !== "undefined"
        ? cookie.load("deliveryDate")
        : "";
    if (deliveryDate !== "") {
      var order_date_exist = "";
      if (deliveryDate !== "") {
        var delivery_Date = deliveryDate.split("/");
        TodayDate = new Date(new Date(TodayDate).setDate(delivery_Date[0]));
        TodayDate = new Date(
          new Date(TodayDate).setMonth(delivery_Date[1] - 1)
        );
        TodayDate = new Date(new Date(TodayDate).setFullYear(delivery_Date[2]));

        order_date_exist = TodayDate;
      }

      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined" &&
        cookie.load("deliveryTime") !== undefined &&
        cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      var order_time_exist = "";
      if (deliveryTime !== "") {
        var delivery_Time = deliveryTime.split(":");
        TodayDate = new Date(new Date(TodayDate).setHours(delivery_Time[0]));
        TodayDate = new Date(new Date(TodayDate).setMinutes(delivery_Time[1]));
        TodayDate = new Date(new Date(TodayDate).setSeconds(delivery_Time[2]));
        order_time_exist = TodayDate;
      }
      var order_is_timeslot =
        cookie.load("slotType") !== "" &&
        typeof cookie.load("slotType") !== undefined &&
        typeof cookie.load("slotType") !== "undefined" &&
        cookie.load("slotType") !== undefined &&
        cookie.load("slotType") !== "undefined"
          ? cookie.load("slotType")
          : 1;

      var orderSlotEndTime =
        cookie.load("orderSlotEndTime") !== "" &&
        typeof cookie.load("orderSlotEndTime") !== undefined &&
        typeof cookie.load("orderSlotEndTime") !== "undefined" &&
        cookie.load("orderSlotEndTime") !== undefined &&
        cookie.load("orderSlotEndTime") !== "undefined"
          ? cookie.load("orderSlotEndTime")
          : "";
      var orderSlotStrTime =
        cookie.load("orderSlotStrTime") !== "" &&
        typeof cookie.load("orderSlotStrTime") !== undefined &&
        typeof cookie.load("orderSlotStrTime") !== "undefined" &&
        cookie.load("orderSlotStrTime") !== undefined &&
        cookie.load("orderSlotStrTime") !== "undefined"
          ? cookie.load("orderSlotStrTime")
          : "";
      var ordTmSlt = Moment(order_date_exist).format("hh:mm A");

      if (
        order_is_timeslot === "2" &&
        orderSlotStrTime !== "" &&
        orderSlotEndTime !== ""
      ) {
        var slotTime1 =
          orderSlotStrTime !== "" ? orderSlotStrTime.split(":") : Array();
        var slotTime2 =
          orderSlotEndTime !== "" ? orderSlotEndTime.split(":") : Array();

        if (
          Object.keys(slotTime1).length > 0 &&
          Object.keys(slotTime2).length > 0
        ) {
          var startTimeVal = parseInt(slotTime1[0]);
          var startMinitVal = parseInt(slotTime1[1]);
          var strdatevalobj = new Date();
          strdatevalobj.setHours(startTimeVal);
          strdatevalobj.setMinutes(startMinitVal);

          var endTimeVal = parseInt(slotTime2[0]);
          var endMinitVal = parseInt(slotTime2[1]);
          var enddatevalobj = new Date();
          enddatevalobj.setHours(endTimeVal);
          enddatevalobj.setMinutes(endMinitVal);

          ordTmSlt =
            format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
        }
      }
      this.setState({
        displayTime:
          format(order_date_exist, "iii dd LLL yyyy") + " " + ordTmSlt,
      });
    }
  }

  checkTimeAvilablity(navigateMenu, outlet_slug) {
    if (this.state.orderOutletId !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";

      if (deliveryTime !== "") {
        Axios.get(
          apiUrlV2 +
            "settings/chkOutletTimesAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            this.state.defaultAvilTy +
            "&outletID=" +
            this.state.orderOutletId +
            "&orderDateTime=" +
            orderDate +
            " " +
            deliveryTime,
          headerconfig
        ).then((response) => {
          var shopeClosed = 0;
          if (
            response.data.status === "error" &&
            cookie.load("defaultAvilablityId") !== dineinId
          ) {
            shopeClosed = 1;
          }
          this.setState({ shopeClosed: shopeClosed }, function () {
            this.getCateProductList(navigateMenu, outlet_slug);
          });
        });
      } else {
        this.getCateProductList(navigateMenu, outlet_slug);
      }
    }
  }
  getCateProductList(navigateMenu, outlet_slug) {
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      var updateproductList = [];
      var current = this;
      var addSlug = "";
      if (
        this.props.productState.currentPageUrl !== "" &&
        typeof this.props.productState.currentPageUrl !== undefined &&
        typeof this.props.productState.currentPageUrl !== "undefined"
      ) {
        addSlug += "&shop_type=" + this.props.productState.currentPageUrl;
      }
      navigateMenu.map(function (item) {
        var availabilityId =
          cookie.load("defaultAvilablityId") === undefined ||
          cookie.load("defaultAvilablityId") == ""
            ? deliveryId
            : cookie.load("defaultAvilablityId");
        var deliveryDate =
          cookie.load("deliveryDate") == undefined ||
          cookie.load("deliveryDate") == ""
            ? ""
            : cookie.load("deliveryDate").split("/");
        var delivery_Date = "";
        if (deliveryDate !== "") {
          delivery_Date =
            deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
        } else {
          delivery_Date = format(new Date(), "yyyy-MM-dd");
        }

        var spicelPride =
          "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
        Axios.get(
          apiUrlV2 +
            "products/getAllProducts?app_id=" +
            appId +
            "&availability=" +
            availabilityId +
            "&category_slug=" +
            item.pro_cate_slug +
            "&outletSlug=" +
            outlet_slug +
            addSlug +
            spicelPride,
          headerconfig
        )
          .then(function (response) {
            if (response.data.status === "ok") {
              if (current.state.imagesource === "") {
                current.setState({ imagesource: response.data.common });
              }
              var result = response.data.result_set;
              updateproductList[item.pro_cate_slug] = result;
              current.productsubcatlist(updateproductList, navigateMenu);
            } else {
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      });
    }
  }

  productsubcatlist(subcatlistArr, navigateMenu) {
    var current = this;
    if (navigateMenu !== "" && navigateMenu.length > 0) {
      hideLoader("productlist-main-div", "class");
      var inc = 0;
      this.showOrdTimeSlot();
      const productDetails = navigateMenu.map(function (item, index) {
        if (
          subcatlistArr[item.pro_cate_slug] !== "" &&
          typeof subcatlistArr[item.pro_cate_slug] != undefined &&
          typeof subcatlistArr[item.pro_cate_slug] != "undefined"
        ) {
          return (
            <div
              key={index}
              id={item.pro_cate_slug}
              className={
                index === 0 && window.innerWidth > 980
                  ? "category-product-list first-list"
                  : "category-product-list"
              }
              title={item.category_name}
            >
              {item.pro_cate_description !== "" && (
                <div className="do-or-die">
                  {stripslashes(item.pro_cate_description)}
                </div>
              )}
              {subcatlistArr[item.pro_cate_slug].map(
                (subcatlistArr1, index) => {
                  return subcatlistArr1["subcategorie"].map(
                    (categories, categoryIndex) => {
                      if (categories.products.length > 0) {
                        if (window.innerWidth > 980) {
                          inc++;
                        }
                        return (
                          <div className="innerproduct" key={categoryIndex}>
                            <h6
                              /*  className={inc !== 1 ? "hide-title" : ""} */
                              id={categories.pro_subcate_slug}
                            >
                              {stripslashes(
                                categories.pro_subcate_name
                              ).toLowerCase()}
                            </h6>
                            <ul className="products-list-ulmain">
                              {inc === 1 ? (
                                <>
                                  <OwlCarousel options={settingsBanner}>
                                    {current.productList(
                                      categories.products,
                                      "slider"
                                    )}
                                  </OwlCarousel>
                                </>
                              ) : (
                                <>
                                  {current.productList(
                                    categories.products,
                                    "normal"
                                  )}
                                </>
                              )}
                            </ul>
                          </div>
                        );
                      }
                    }
                  );
                }
              )}
            </div>
          );
        }
      });
      this.setState({ allProductList: productDetails }, function () {
        var proValue =
          typeof this.props.match.params.proValue !== "undefined" &&
          typeof this.props.match.params.proValue !== "undefined" &&
          this.props.match.params.proValue !== undefined
            ? this.props.match.params.proValue
            : "";
        var currentThis = this;
        if (this.state.scrollProduct === "No" && proValue !== "") {
          setTimeout(function () {
            if ($("." + proValue).length > 0) {
              $("." + proValue + " .order_nowdiv").trigger("click");
              $("html, body").animate(
                {
                  scrollTop: $("." + proValue).offset().top - 150,
                },
                100
              );
              currentThis.setState({ scrollProduct: "Yes" });
            }

            const cat = subcatlistArr[current.props.match.params.slugType];
            if (cat && cat.length) {
              const subCat = cat[0].subcategorie;
              if (subCat && subCat.length) {
                const productDetail = (subCat[0]?.products || []).find(
                  (p) => p.product_slug === proValue
                );
                if (productDetail) {
                  current.viewProDetail(productDetail);
                }
              }
            }
          }, 100);
        }
      });
      return "";
    } else {
      return "";
    }

    /* if (Object.keys(subcatlistArr).length > 0) {
      hideLoader("productlist-main-div", "class");
      const productDetails = subcatlistArr.map((categories, categoryIndex) => {
        if (categories.products.length > 0) {
          return (
            <div className="innerproduct" key={categoryIndex}>
              <h3 id={categories.pro_subcate_slug}>
                {stripslashes(categories.pro_subcate_name)}
              </h3>
              <ul className="products-list-ulmain">
                {this.productList(
                  categories.products,
                  categories.products.length
                )}
              </ul>
            </div>
          );
        }
      });
      return productDetails;
    } else {
      return "";
    } */
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }

    return returnText;
  }

  /* Products List */
  productList(list, Listtype) {
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;
      var productName =
        productDetail.product_alias !== ""
          ? stripslashes(productDetail.product_alias)
          : stripslashes(productDetail.product_name);
      return (
        <li
          className={this.chkProStockCls(
            productDetail.product_slug,
            productDetail.product_stock
          )}
          id={prodivId}
          key={index}
        >
          {productDetail.product_tag_info !== "" &&
            productDetail.product_tag_info !== null &&
            Listtype === "slider" && (
              <div className="ribbon">
                <span>{productDetail.product_tag_info}</span>
              </div>
            )}

          <div className="products-image-div">
            <a href="/" onClick={this.viewProDetail.bind(this, productDetail)}>
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={
                    this.state.imagesource.product_image_source +
                    "/" +
                    productDetail.product_thumbnail
                  }
                  title={
                    productDetail.product_thumbnail_title !== "" &&
                    productDetail.product_thumbnail_title !== null
                      ? productDetail.product_thumbnail_title
                      : productName
                  }
                  alt={
                    productDetail.product_thumbnail_alt !== "" &&
                    productDetail.product_thumbnail_alt !== null
                      ? productDetail.product_thumbnail_alt
                      : productName
                  }
                />
              ) : (
                <img src={noimage} title={productName} alt={productName} />
              )}
            </a>
            {Listtype === "slider" && (
              <a
                href="/"
                onClick={this.viewProDetail.bind(this, productDetail)}
              >
                {this.state.shopeClosed === 1 ? (
                  <div className="not-available-stock">
                    Not available for {this.state.displayTime}
                  </div>
                ) : (
                  productDetail.product_stock <= 0 && (
                    <div className="not-available-stock">
                      This product is out of stock
                    </div>
                  )
                )}
              </a>
            )}
          </div>

          <div className="product-info-div">
            {productDetail.product_tag_info !== "" &&
              productDetail.product_tag_info !== null &&
              Listtype !== "slider" && (
                <div className="ribbon">
                  <span>{productDetail.product_tag_info}</span>
                </div>
              )}
            <div className="product-title-maindiv">
              <div className="product-title">
                <a
                  href="/"
                  onClick={this.viewProDetail.bind(this, productDetail)}
                >
                  <h3>{productName.toLowerCase()}</h3>
                </a>
              </div>
              <div className="product-tag-list">
                {Object.keys(productDetail.product_tag).length > 0 ? (
                  <ul>
                    {productDetail.product_tag.map((producttag, index1) => {
                      return (
                        <li key={index1}>
                          {producttag.pro_tag_name !== ""
                            ? producttag.pro_tag_name
                            : ""}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
            {description !== "" && (
              <div className="product-short-description">
                <a
                  href="/"
                  onClick={this.viewProDetail.bind(this, productDetail)}
                >
                  <p>{description !== "" ? description : Parser("&nbsp;")}</p>
                </a>
              </div>
            )}
            <div className="products-ordernow-action">
              <div className="product-price">
                <h3>
                  {productDetail.product_specialprice_applicable === "yes"
                    ? showPriceValue(productDetail.product_special_price)
                    : showPriceValue(productDetail.product_price)}
                </h3>
                {productDetail.product_specialprice_applicable === "yes"
                  ? parseFloat(productDetail.product_price) > 0 && (
                      <span className="product-originale-price">
                        {showPriceValue(productDetail.product_price)}
                      </span>
                    )
                  : parseFloat(productDetail.product_cost) > 0 && (
                      <span className="product-originale-price">
                        {showPriceValue(productDetail.product_cost)}
                      </span>
                    )}
              </div>

              {this.state.settings !== "" &&
                this.state.settings.client_web_maintenance_mode !== "Yes" && (
                  <>
                    {productDetail.product_stock > 0 ||
                    productDetail.product_stock === null
                      ? productDetail.product_type === "1"
                        ? this.state.shopeClosed === 0 && (
                            <a
                              className="button order_nowdiv smiple_product_lk disbl_href_action "
                              href="/"
                              /* onClick={this.addToCartSimple.bind(
                                this,
                                productDetail,
                                "initial"
                              )} */
                              id={comboProId}
                              onClick={this.viewProDetail.bind(
                                this,
                                productDetail
                              )}
                            >
                              Order Now
                            </a>
                          )
                        : this.state.shopeClosed === 0 && (
                            <a
                              href="/"
                              onClick={this.viewProDetail.bind(
                                this,
                                productDetail
                              )}
                              title="Product Details"
                              id={comboProId}
                              className="button order_nowdiv compo_product_lk"
                            >
                              Add to Cart
                            </a>
                          )
                      : this.state.shopeClosed === 0 && (
                          <a
                            className="button order_nowdiv disabled disbl_href_action"
                            href="/"
                          >
                            Sold Out
                          </a>
                        )}

                    <div className="addcart_row addcart_done_maindiv">
                      <div className="qty_bx">
                        <span
                          className="qty_minus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "decr"
                          )}
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="proqty_input"
                          readOnly
                          value="1"
                        />
                        <span
                          className="qty_plus"
                          onClick={this.proQtyAction.bind(
                            this,
                            productDetail.product_primary_id,
                            "incr"
                          )}
                        >
                          +
                        </span>
                      </div>
                      <button
                        className="btn btn_black"
                        onClick={this.addToCartSimple.bind(
                          this,
                          productDetail,
                          "done"
                        )}
                      >
                        Done
                      </button>
                    </div>
                  </>
                )}
            </div>
          </div>
        </li>
      );
    });

    return listProduct;
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();
    var outlet_id = this.props.productState.orderOutletId;
    var outletLocationId = this.props.productState.outletLocationId;
    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      $.magnificPopup.open({
        items: {
          src: "#dinein-popup",
        },
        type: "inline",
      });

      return false;
    } else if (cookie.load("defaultAvilablityId") === dineinId) {
      if (
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined &&
        typeof cookie.load("orderOutletId") !== "undefined"
      ) {
        if (cookie.load("orderOutletId") !== outlet_id) {
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
    }

    var cartOutletList = this.state.cartOutletList;

    if (
      cartOutletList.length >= parseInt(this.state.settings.max_order_handle)
    ) {
      if (cartOutletList.indexOf(outlet_id) < 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-max-orderpopup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proIndex-" + IndexFlg).addClass("active");
      $("#proIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();
      var specialprice_applicable =
        productDetail.product_specialprice_applicable;
      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: productId,
        product_qty: proqtyQty,
        outletLocationId: outletLocationId,
        outlet_id: outlet_id,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        specialprice_applicable: specialprice_applicable,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        shopType: shopType,
        outletzone_id: this.state.orderZoneId,
        login_type:
          typeof cookie.load("userAccountType") === "undefined"
            ? ""
            : cookie.load("userAccountType"),
        reference_new_id: getReferenceID(),
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject),
        headerconfig
      ).then((res) => {
        $("#proIndex-" + IndexFlg).removeClass("active");
        hideLoader("proIndex-" + IndexFlg, "Idtext");
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          var productName =
            productDetail.product_alias !== "" &&
            productDetail.product_alias !== null
              ? productDetail.product_alias
              : productDetail.product_name;
          var proPrice = productDetail.product_price;
          if (specialprice_applicable === "yes") {
            proPrice = productDetail.product_special_price;
          }

          this.props.sateValChange("cartflg", "yes");
          removePromoCkValue();
          showCustomAlert(
            "success",
            "Great! Your item has been added to the cart",
            "cart-success-msg"
          );
          /* this.handleShowAlertFun(
            "success",
            "Great choice! Item added to your cart."
          ); */
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("error", errMsgtxt);
          //this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  viewProDetail(productDetail, event) {
    if (event) {
      event.preventDefault();
    }
    var productSlug = productDetail.product_slug;
    var outlet_id = this.props.productState.orderOutletId;
    var outletLocationId = this.props.productState.outletLocationId;
    if (
      cookie.load("defaultAvilablityId") === "" ||
      typeof cookie.load("defaultAvilablityId") === undefined ||
      typeof cookie.load("defaultAvilablityId") === "undefined"
    ) {
      $.magnificPopup.open({
        items: {
          src: "#dinein-popup",
        },
        type: "inline",
      });

      return false;
    } else if (cookie.load("defaultAvilablityId") === dineinId) {
      if (
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined &&
        typeof cookie.load("orderOutletId") !== "undefined"
      ) {
        if (cookie.load("orderOutletId") !== outlet_id) {
          $.magnificPopup.open({
            items: {
              src: "#warning-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
    }

    if (productSlug !== "") {
      showLoader("comboPro-" + productDetail.product_slug);

      // $("#comboPro-" + productDetail.product_primary_id).addClass("active");
      this.props.sateValChange("view_pro_data", productSlug);
      this.props.sateValChange("showProDetails", "yes");
    }
    return false;
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  componentDidMount() {}

  render() {
    return (
      <div className="productlist-main-div">
        <div className="innerproduct_row">
          <a href={void 0} className="testtest">
            Tetsttest
          </a>
          {this.state.allProductList}
          {/* this.productsubcatlist() */}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var productSubcat = Array();
  var productCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("productlist" in state.product[0])
      ? Array()
      : state.product[0].productlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        productSubcat = tempArr[0].result_set[0].subcategorie;
        productCmn = tempArr[0].common;
      }
    }
  }

  return {
    productSubcatlist: productSubcat,
    productCommon: productCmn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductList: (catslug, subcatslug, outletid) => {
      dispatch({ type: GET_PRODUCT_LIST, catslug, subcatslug, outletid });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
