/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { format } from "date-fns";
import axios from "axios";
import { connect } from "react-redux";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import Promodeals from "./HomeSubCompo/Promodeals";
import NewProducts from "./HomeSubCompo/NewProducts";
import Newlyopened from "./HomeSubCompo/Newlyopened";
import Specialcollaborations from "./HomeSubCompo/Specialcollaborations";
import SwimlaneProducts from "./HomeSubCompo/SwimlaneProducts";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import {
  appId,
  apiUrl,
  siteURL,
  deliveryId,
  pickupId,
  dineinId,
  projectTitle,
  mediaUrl,
  headerconfig,
  cookieConfig,
} from "../Helpers/Config";
import { GET_NORMAL_POPUP } from "../../actions";
import {
  showLoader,
  showAlert,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  getReferenceID,
  showToastrAlert,
  stripslashes,
} from "../Helpers/SettingHelper";
import newletterImg from "../../common/images/newletter-bg.jpg";
import logo0 from "../../common/images/no-img-80.png";

import dbw from "../../common/images/delivery-bike-white.svg";
import db from "../../common/images/delivery-bike.svg";
import tyw from "../../common/images/takeaway-white.svg";
import ty from "../../common/images/takeaway.svg";
import dinw from "../../common/images/dinein-white.svg";
import din from "../../common/images/dinein.svg";

import pplace from "../../common/images/noimg-400x400.jpg";
import sample from "../../common/images/samplelogo.png";
import { pick } from "underscore";

var base64 = require("base-64");
var Parser = require("html-react-parser");

class Home extends Component {
  constructor(props) {
    super(props);
    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }
    var availability =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";

    this.state = {
      defaultAvilablityId: availability,
      seletedAvilablityId: availability,
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      mixmatchleft: "",
      mixmatchright: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      cartDetails: "",
      guestname: "",
      guestname_error: "",
      outletList: [],
      displayoutlet: "",
      outletTagList: [],

      setallTag: false,
      selectedTag: [],
      search_keyword: "",
      swimlaneheader1: "",
      swimlaneheader2: "",
      swimlaneheader3: "",
      swimlaneheader4: "",
      swimlaneheader5: "",
      swimlaneheader6: "",
      multiswimlane: [],
      multiswimlaneimage: [],
      displaymultiswimlane: "",
    };

    this.props.getNormalPopup();
    this.handleChangeTag = this.handleChangeTag.bind(this);
    this.handleChangeTagMobile = this.handleChangeTagMobile.bind(this);
    this.loadSwimlaneProducts();

    this.loadAllOutlet();
  }

  componentWillReceiveProps(PropsData) {
    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }

    if (PropsData.staticblack !== this.state.staticblacks) {
      var mixmatchleft = "";
      var mixmatchright = "";
      var swimlaneheader1 = "";
      var swimlaneheader2 = "";
      var swimlaneheader3 = "";
      var swimlaneheader4 = "";
      var swimlaneheader5 = "";
      var swimlaneheader6 = "";

      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "mix-match-left") {
            mixmatchleft =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "mix-match-rigth") {
            mixmatchright =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "swimlane-header1") {
            swimlaneheader1 =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "swimlane-header2") {
            swimlaneheader2 =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "swimlane-header3") {
            swimlaneheader3 =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "swimlane-header4") {
            swimlaneheader4 =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "swimlane-header5") {
            swimlaneheader5 =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
          if (data.staticblocks_slug === "swimlane-header6") {
            swimlaneheader6 =
              data.staticblocks_description !== ""
                ? Parser(data.staticblocks_description)
                : "";
            return "";
          }
        });
      }

      this.setState({
        mixmatchleft: mixmatchleft,
        mixmatchright: mixmatchright,
        staticblacks: PropsData.staticblack,
        swimlaneheader1: swimlaneheader1,
        swimlaneheader2: swimlaneheader2,
        swimlaneheader3: swimlaneheader3,
        swimlaneheader4: swimlaneheader4,
        swimlaneheader5: swimlaneheader5,
        swimlaneheader6: swimlaneheader6,
      });
    }
  }

  loadSwimlaneProducts() {
    var availabilityId =
      this.state.defaultAvilablityId !== ""
        ? this.state.defaultAvilablityId
        : deliveryId;

    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
    axios
      .get(
        apiUrl +
          "products/swimlane_products_list?app_id=" +
          appId +
          "&availability=" +
          availabilityId +
          spicelPride,
        headerconfig
      )
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState(
            {
              multiswimlane: res.data.result_set,
              multiswimlaneimage: res.data.common,
            },
            function () {
              this.swimlaneProducts();
            }
          );
        } else if (res.data.status === "error") {
        }
      });
  }

  handleChangeTag = (event) => {
    var selectedTag = this.state.selectedTag;
    if (event.target.checked === true) {
      selectedTag.push(event.target.value);
    } else {
      const index = selectedTag.indexOf(event.target.value);
      selectedTag.splice(index, 1);
    }
    this.setState({ selectedTag: selectedTag }, function () {
      this.displayoutlet();
    });
  };
  handleChangeTagMobile = (event) => {
    var selectedTag = this.state.selectedTag;
    if (event.target.checked === true) {
      selectedTag.push(event.target.value);
    } else {
      const index = selectedTag.indexOf(event.target.value);
      selectedTag.splice(index, 1);
    }
    this.setState({ selectedTag: selectedTag }, function () {});
  };

  loadAllOutlet() {
    axios
      .get(
        apiUrl + "outlets/getAllOutles?app_id=" + appId + "&withtag=Yes",
        headerconfig
      )
      .then((res) => {
        /* Success response */
        if (res.data.status === "ok") {
          this.setState(
            {
              outletList: res.data.result_set,
              outletTagList: res.data.tagList,
            },
            function () {
              this.displayoutlet();
            }
          );
        } else if (res.data.status === "error") {
        }
      });
  }
  displayoutlet() {
    var totalRes = 0;
    var displayoutlet = this.state.outletList.map((item, index) => {
      if (item.outlet_id != "138") {
        totalRes++;
        return (
          <li key={index}>
            <Link to={"/food/" + item.outlet_slug} className="outlet-inner">
              <div className="hol-img">
                {" "}
                <img
                  src={
                    item.outlet_extra_image !== "" &&
                    item.outlet_extra_image !== null
                      ? mediaUrl + "outlet/" + item.outlet_extra_image
                      : logo0
                  }
                  alt={item.outlet_name}
                  title={item.outlet_name}
                />{" "}
              </div>
              <div
                className="hol-desc"
                style={{ backgroundColor: item.outlet_color_code }}
              >
                <img
                  src={
                    item.outlet_image !== "" && item.outlet_image !== null
                      ? mediaUrl + "outlet/" + item.outlet_image
                      : logo0
                  }
                />
                <h3>{stripslashes(item.outlet_name)}</h3>

                {item.outlet_tag_id !== "" && item.outlet_tag_id != null && (
                  <div className="outlet-tag">
                    {this.dispalyshortOutetTag(item.outlet_tag_id)}
                  </div>
                )}
                {item.outlet_open_time !== "" && (
                  <p>Available from {item.outlet_open_time}</p>
                )}
              </div>
            </Link>
          </li>
        );
      }
    });
    if (totalRes === 0) {
      displayoutlet = (
        <li className="no_restaurants">
          <h3>No restaurants found</h3>
          <p>
            There are no restaurants matching your selected. Try widening your
            search to see what’s available!
          </p>
        </li>
      );
    }

    this.setState({ displayoutlet: displayoutlet }, function () {
      if (this.props.match.path === "/restaurants") {
        setTimeout(function () {
          if ($("#outlet-listing").length > 0) {
            $("html, body").animate(
              {
                scrollTop: $("#outlet-listing").offset().top - 100,
              },
              500
            );
          }
        }, 1000);
      }
    });
  }

  dispalyshortOutetTag(outletTag) {
    var result = "";
    if (
      this.state.outletTagList.length > 0 &&
      outletTag !== "" &&
      outletTag !== null
    ) {
      var outlet_Tag = outletTag.split(",");
      result = this.state.outletTagList.map((item) => {
        if (outlet_Tag.indexOf(item.ol_tag_primary_id) >= 0) {
          return <span>{item.ol_tag_name}</span>;
        }
      });
    }
    return result;
  }

  chooseAvailability(availability) {
    var defaultAvilTy = this.state.defaultAvilablityId;
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    $("body").on("click", ".gotosection", function (e) {
      e.preventDefault();
      var secID = $(this).data("id");
      if ($("#" + secID).length > 0) {
        $("html, body").animate(
          {
            scrollTop: $("#" + secID).offset().top - 150,
          },
          500
        );
      }
    });

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", cookieConfig);
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("openqrcode") === "Yes") {
      $.magnificPopup.open({
        items: {
          src: "#dinein-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#order-popup",
            },
            type: "inline",
          });
        }
      });
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, cookieConfig);
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", cookieConfig);
      cookie.remove("promoPopupTrigger", cookieConfig);
      $.magnificPopup.open({
        items: {
          src: "#promo-check-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
      });
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(
          apiUrl + "promotion/user_promotion",
          qs.stringify(postObject),
          headerconfig
        )
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of " +
                projectTitle +
                ". Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy =
      this.state.defaultAvilablityId !== ""
        ? this.state.defaultAvilablityId
        : "";
    var orderPostalCode = cookie.load("orderPostalCode");
    var deliveryPostalCode = cookie.load("deliveryPostalCode");

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined"
    ) {
      if (availability === deliveryId) {
        if (
          deliveryPostalCode !== "" &&
          typeof deliveryPostalCode !== undefined &&
          typeof deliveryPostalCode !== "undefined"
        ) {
        } else {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
      cookie.remove("defaultAvilablityId", cookieConfig);
      cookie.save("defaultAvilablityId", availability, cookieConfig);
      this.setState({ defaultAvilTy: availability }, function () {
        showLoader("innerproduct", "class");
        axios
          .get(
            apiUrl +
              "cart/update_availability?app_id=" +
              appId +
              "&reference_id=" +
              getReferenceID() +
              "&customer_id=" +
              cookie.load("UserId") +
              "&availability_id=" +
              availability,
            headerconfig
          )
          .then((res) => {});
      });
      this.props.history.push("/food");
      return false;
    }

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined" &&
      defaultAvilTy === availability
    ) {
      if (availability === deliveryId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          $.magnificPopup.open({
            items: {
              src: "#delevery-postcode-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (availability === pickupId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          this.setState({ seletedAvilablityId: pickupId });
          return false;
        }
      }
      this.props.history.push("/food");
      return false;
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      this.setState({ seletedAvilablityId: pickupId });
      //popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = this.state.defaultAvilablityId;
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    if (event.target.value !== "") {
      this.setState({ [item + "_error"]: "" });
    }
  };

  searchPro() {
    this.props.history.push("/search/" + this.state.search_keyword);
  }
  swimlaneProducts() {
    var displaymultiswimlane = "";
    if (this.state.multiswimlane.length > 0) {
      displaymultiswimlane = this.state.multiswimlane.map((item, index) => {
        return (
          <section
            className="featuredpro-section"
            id={"swimlane_sec_list" + item.swimlane_id}
            key={index}
          >
            <div className="featured-products-section">
              <div className="container">
                {item.swimlane_description !== "" &&
                  Parser(item.swimlane_description)}
                <SwimlaneProducts
                  {...this.props}
                  sateValChange={this.sateValChange}
                  swimlaneid={item.swimlane_id}
                  highlightproductlist={item.product_list}
                  multiswimlaneimage={this.state.multiswimlaneimage}
                />
              </div>
            </div>
          </section>
        );
      });
    }
    this.setState({ displaymultiswimlane: displaymultiswimlane });
  }
  openOrderType(orderType) {
    if (orderType !== dineinId) {
      return false;
    }
    var availability =
      this.state.defaultAvilablityId !== ""
        ? this.state.defaultAvilablityId
        : "";
    var orderOutletId =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
        ? cookie.load("orderOutletId")
        : "";
    var cartTotalItems = cookie.load("cartTotalItems");
    cartTotalItems =
      cartTotalItems != "" && cartTotalItems != undefined
        ? parseInt(cartTotalItems)
        : 0;

    if (
      orderOutletId !== "" &&
      (availability === dineinId || orderType === dineinId) &&
      this.props.cartTotalItmCount > 0
    ) {
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
      this.setState({ seletedAvilablityId: orderType, nextavail: orderType });
      return false;
    } else if (
      this.props.cartTotalItmCount > 0 &&
      availability !== orderType &&
      orderType === dineinId
    ) {
      $.magnificPopup.open({
        items: {
          src: "#warning-popup",
        },
        type: "inline",
      });
      this.setState({ seletedAvilablityId: orderType, nextavail: orderType });
      return false;
    }

    var popupval = "";
    this.setState(
      { seletedAvilablityId: orderType, nextavail: orderType },
      function () {
        if (orderType === deliveryId) {
          popupval = "#delevery-postcode-popup";
        } else if (orderType === dineinId) {
          popupval = "#dinein-popup";
        }
        if (popupval !== "") {
          $.magnificPopup.open({
            items: {
              src: popupval,
            },
            type: "inline",
          });
        }
      }
    );
  }

  render() {
    let settingsArr = this.props.globalsettings;

    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
          selectedAvilHome={this.state.seletedAvilablityId}
        />
        <section className="white-home">
          <HomeBanner />
        </section>

        <section className="home-option">
          <div className="container">
            <ul>
              {/*  <li
                className={
                  this.state.defaultAvilablityId === deliveryId ? "active" : ""
                }
              >
                <a
                  href={void 0}
                  onClick={this.openOrderType.bind(this, deliveryId)}
                >
                  <img className="normalstate" src={db} />
                  <img className="hoverstate" src={dbw} />
                  <span>Delivery</span>
                  <label>Coming Soon</label>
                </a>
              </li>
              <li
                className={
                  this.state.defaultAvilablityId === pickupId ? "active" : ""
                }
              >
                <a
                  href={void 0}
                  onClick={this.openOrderType.bind(this, pickupId)}
                >
                  <img className="normalstate" src={ty} />
                  <img className="hoverstate" src={tyw} />
                  <span>takeaway</span>
                  <label>Coming Soon</label>
                </a>
              </li> */}
              <li
                className={
                  this.state.defaultAvilablityId === dineinId ? "active" : ""
                }
              >
                <a
                  href={void 0}
                  onClick={this.openOrderType.bind(this, dineinId)}
                >
                  <img className="normalstate" src={din} />
                  <img className="hoverstate" src={dinw} />
                  <span>Dine In</span>
                </a>
              </li>
            </ul>
          </div>
        </section>

        <Promodeals swimlaneheader={this.state.swimlaneheader2} />
        <FeaturedProducts
          {...this.props}
          sateValChange={this.sateValChange}
          swimlaneheader={this.state.swimlaneheader3}
        />

        <section className="home-outlet-listing" id="outlet-listing">
          <div className="container">
            <div className="hol-header">
              <h2>Our Outlets</h2>
              <p>
                The Premier Family Club where the People make the Difference
              </p>
            </div>
            <ul>{this.state.displayoutlet}</ul>
          </div>
        </section>

        {/* Footer section */}
        <Footer />
        {/*Normal popup Start*/}
        <div
          id="normal-popup"
          className="white-popup mfp-hide popup_sec normal-popup"
        >
          <div className="normal_popup_alert">
            {this.state.normalpopup_status === "yes" &&
              Object.keys(this.state.normalpopupdata).length > 0 && (
                <div className="normal_popup_cont">
                  {Parser(this.state.normalpopupdata.normalpopup_description)}
                </div>
              )}
          </div>
        </div>
        {/*Normal popup - End*/}
        {/*Promo Check Email popup Start*/}
        <div
          id="promo-check-popup"
          className="white-popup mfp-hide popup_sec promo-check-popup"
        >
          <div className="promopopup-maindiv">
            <div className="promopopup-lhs">
              <div className="frm-action-div">
                <div className="frm-top-title">
                  <h3>Join our email list</h3>
                  <p>Enter your Email address to receive your promocode</p>
                </div>

                <div className="frm-inputbtn-div">
                  <div className="form-group">
                    <div className="focus-out">
                      <label>Email Address</label>
                      <input
                        type="email"
                        className="form-control input-focus"
                        value={this.state.promo_mail_id}
                        onChange={this.handleEmailChange.bind(this)}
                      />
                      {this.state.promomail_error !== "" && (
                        <div id="promomail-error">
                          {this.state.promomail_error}
                        </div>
                      )}
                    </div>
                  </div>

                  <button
                    type="button"
                    onClick={this.sendPromoMailFun.bind(this)}
                    className="button promomailpopup-cls"
                  >
                    Subscribe
                  </button>
                </div>

                <div className="frm-bottom-text">
                  <p>
                    SIGN UP FOR EXCLUSIVE UPDATES, NEW PRODUCTS, AND
                    INSIDER-ONLY DISCOUNTS
                  </p>
                </div>
              </div>
            </div>

            <div className="promopopup-rhs">
              <img src={newletterImg} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var cartTotalItmCount = 0;

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  return {
    globalsettings: state.settings,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
    staticblack: blacksArr,
    cartTotalItmCount: cartTotalItmCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
