/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_REWARDREDEEM, SET_REWARDREDEEM } from "../actions";
import { appId, apiUrl, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetRewardRedeem = function* () {
  yield takeEvery(GET_REWARDREDEEM, workerGetRewardRedeem);
};

function* workerGetRewardRedeem({ customer_id }) {
  try {
    const uri =
      apiUrl +
      "loyalty/customer_redeemed_rewardpoint_histroy?status=A&app_id=" +
      appId +
      "&customer_id=" +
      customer_id;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_REWARDREDEEM, value: resultArr });
  } catch {
    console.log("Get reward redeem Failed");
  }
}
