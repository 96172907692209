import { takeEvery, call, put } from "redux-saga/effects";
import { GET_ALL_OUTLETS, SET_ALL_OUTLETS } from "../actions";
import { appId, apiUrlV2, headerconfig } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetAllOutlets = function* () {
  yield takeEvery(GET_ALL_OUTLETS, workerGetAllOutlets);
};

function* workerGetAllOutlets({
  availability,
  outlet_slug,
  shop_type,
  zip_code,
}) {
  var outletslug = "";
  if (outlet_slug !== "" && outlet_slug !== undefined) {
    outletslug = "&outlet_slug=" + outlet_slug;
  }
  var event_slug = "";

  var shoptype = "";
  if (shop_type !== "" && shop_type !== undefined) {
    shoptype = "&shop_type=" + shop_type;
  }
  var zipcode = "";
  if (zip_code !== "" && zip_code !== undefined) {
    zipcode = "&postal_code=" + zip_code;
  }
  var availability_id = "";
  if (availability !== "" && availability !== undefined) {
    availability_id = "&availability_id=" + availability;
  }
  try {
    const uri =
      apiUrlV2 +
      "outlets/getAllOutles?app_id=" +
      appId +
      availability_id +
      outletslug +
      event_slug +
      shoptype +
      zipcode;
    const result = yield call(Axios.get, uri, headerconfig);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_ALL_OUTLETS, value: resultArr });
  } catch (e) {
    console.log(e, "Get All Outlets Failed");
  }
}
